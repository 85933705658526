import Vue from 'vue';
import App from './App';

let TumsComponents = window['tums-components'].default;
TumsComponents.install(Vue);

const { TumsMessage, TumsDialog, Utils } = TumsComponents;

window.TumsMessage = TumsMessage;
window.TumsDialog = TumsDialog;

let tumsAxios = Utils.data.tumsAxios;
window.tumsAxios = tumsAxios;
Vue.prototype.$tumsAxios = tumsAxios;

let { $t } = Utils.language;
Vue.prototype.$t = $t;

Vue.use(Utils.date.default.install);

new Vue({
    el: '#app',
    components: {
        App,
    },
    template: '<app></app>',
});
