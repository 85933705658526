import { appendByteArray } from '@/utils/binary';
import PayloadType from '@/utils/payloadType';

const startSequence = [0, 0, 0, 1];

export class NALU {
    static get NDR() {
        return 1;
    }
    static get IDR() {
        return 5;
    }
    static get SEI() {
        return 6;
    }
    static get SPS() {
        return 7;
    }
    static get PPS() {
        return 8;
    }
    static get STAP_A() {
        return 24;
    }
    static get STAP_B() {
        return 25;
    }
    static get FU_A() {
        return 28;
    }
    static get FU_B() {
        return 29;
    }
    static get VPS() {
        return 32;
    }
    static get HEVC_SPS() {
        return 33;
    }
    static get HEVC_PPS() {
        return 34;
    }

    static get TYPES() {
        return {
            [NALU.IDR]: 'IDR',
            [NALU.SEI]: 'SEI',
            // 7：序列参数集/SPS
            [NALU.SPS]: 'SPS',
            // 8：图像参数集/PPS B
            [NALU.PPS]: 'PPS',
            [NALU.NDR]: 'NDR',
            [NALU.VPS]: 'VPS',
            // 33: H265 序列参数集/SPS
            [NALU.HEVC_SPS]: 'SPS',
            // 34: H265 图像参数集/PPS B
            [NALU.HEVC_PPS]: 'PPS'
        };
    }

    static type(nalu) {
        if (nalu.ntype in NALU.TYPES) {
            return NALU.TYPES[nalu.ntype];
        } else {
            return 'UNKNOWN';
        }
    }

    constructor(ntype, data, dts, pts, nri, payloadType) {
        this.data = new Uint8Array(startSequence.length + data.length);
        this.data.set(startSequence, 0);
        this.data.set(data, startSequence.length);
        // this.data = this.data.concat(data);
        this.dts = dts;
        this.pts = pts ? pts : this.dts;
        this.ntype = ntype;
        this.nri = nri;
        this.payloadType = payloadType;
    }

    appendData(idata) {
        this.data = appendByteArray(this.data, idata);
    }

    toString() {
        return `${NALU.type(this)}(${this.data.byteLength}): NRI: ${this.getNri()}, PTS: ${this.pts}, DTS: ${this.dts}`;
    }

    getNri() {
        return this.nri >> 5;
    }

    type() {
        return this.ntype;
    }

    isKeyframe() {
        return this.payloadType === PayloadType.H264
            ? this.ntype == NALU.IDR
            : (this.ntype >= 16 && this.ntype <= 20);
    }

    isParameterSet() {
        return this.payloadType === PayloadType.H264
            ? (this.ntype === NALU.SPS || this.ntype === NALU.PPS)
            : (this.ntype === NALU.VPS || this.ntype === NALU.HEVC_SPS || this.ntype === NALU.HEVC_PPS);
    }

    getSize() {
        return 4 + 1 + this.data.byteLength;
    }

    getData() {
        let header = new Uint8Array(5 + this.data.byteLength);
        let view = new DataView(header.buffer);
        view.setUint32(0, this.data.byteLength + 1);
        view.setUint8(4, (0x0 & 0x80) | (this.nri & 0x60) | (this.ntype & 0x1f));
        header.set(this.data, 5);
        return header;
    }
}