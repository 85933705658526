<template>
    <div class="a-h-full">
        <div class="mobile-player a-pos-r a-w-full tums-h-264 a-bg-black" ref="player">
            <i v-if="loading" class="tums-icon-loading a-gold-center a-c-primary"></i>
        </div>
        <div class="mobile-player-tool tums-h-56 a-d-f a-ai-c a-p-h-md">
            <i v-if="isPlaying" class="tums-icon-pause" @touchstart="suspend(0)"></i>
            <i v-else class="tums-icon-play" @touchstart="suspend(1)"></i>
            <i class="tums-icon-stop a-m-l-lg" @touchstart="destroy"></i>
            <i class="a-m-l-lg" :class="isAudioPlaying ? 'tums-icon-volume' : 'tums-icon-mute'" @touchstart="playAudio"></i>
            <p class="a-m-l-lg" @touchstart="changeResolution(resolution > 0 ? -1 : 1)">
                <span>{{ resolution > 0 ? '高清' : '流畅' }}</span>
            </p>
        </div>
        <div style="height: calc(100% - 376px)" class="a-bg-grey-bg">
            <tums-scrollbar>
                <div
                    v-for="device in deviceList"
                    :key="device.deviceIndex"
                    class="mobile-device-item a-m-t-md a-m-h-md a-bg-white a-p-md a-d-f a-ai-c"
                    :class="{ 'a-bg-light-blue1': currentDevice.deviceIndex === device.deviceIndex }"
                >
                    <i class="tums-icon-ipc" @touchstart="setCurrentDev(device)"></i>
                    <div class="a-m-l-md">
                        <p class="mobile-device-name">{{ device.deviceName }}</p>
                        <p class="mobile-device-description">{{ device.projectName + '/' + device.regionName }}</p>
                    </div>
                </div>
            </tums-scrollbar>
        </div>
        <div class="tums-h-56 a-d-f a-ai-c a-jc-c">
            <tums-popover>
                <div slot="reference" class="mobile-player-enterprise">
                    <span>{{ currentEnterprise.enterpriseName }}</span>
                </div>
                <div class="mobile-player-enterprise-pop a-p-v-md">
                    <div
                        v-for="item in enterpriseList"
                        :key="item.enterpriseId"
                        class="mobile-player-enterprise-item tums-h-40"
                        :class="{ 'a-bg-light-blue1': currentEnterprise.enterpriseId === item.enterpriseId }"
                        @touchstart="changeEnterprise(item)"
                    >
                        <span>{{ item.enterpriseName }}</span>
                    </div>
                </div>
            </tums-popover>
        </div>
    </div>
</template>

<script>
import TumsPlayer from '@/index';
import { getAllDevice, getIpcCapability } from '../api/device';
import { getPreviewUrl } from '../api/getPreviewUrl';
import Vconsole from 'vconsole';

export default {
    name: 'MobilePreview',
    props: {
        enterpriseList: Array,
        config: Object
    },
    data() {
        return {
            currentEnterprise: {},
            deviceList: [],
            currentDevice: {},
            isPlaying: false,
            isAudioPlaying: false,
            resolution: 1,
            loading: false
        };
    },
    mounted() {
        new Vconsole();
    },
    beforeDestroy() {
        this.destroy();
    },
    computed: {
        isCloud() {
            return this.config.platform === 'cloud';
        }
    },
    watch: {
        enterpriseList: {
            immediate: true,
            handler(val) {
                if (!val || !val.length) return;
                this.currentEnterprise = this.enterpriseList[0] || {};
            }
        },
        currentEnterprise(val) {
            this.getDeviceList(val);
        }
    },
    methods: {
        changeEnterprise(enterprise) {
            if (this.currentEnterprise.enterpriseId === enterprise.enterpriseId) return;
            this.currentEnterprise = enterprise;
        },

        async getDeviceList(enterprise) {
            let deviceList = await getAllDevice({
                start: 0,
                limit: 100,
                filterAnd: {
                    enterpriseId: enterprise.enterpriseId,
                    deviceStatus: 1
                }
            });
            let ipcList = deviceList.filter((item) => item.deviceType === 'SURVEILLANCECAMERA');
            let nvrList = deviceList.filter((item) => item.deviceType === 'NETWORKVIDEORECORDER');
            this.deviceList = [...ipcList];
            // nvrList.forEach((item) => {
            //     item.extend.channelList.forEach((channel) => {
            //         this.deviceList.push(channel);
            //     });
            // });
        },

        async setCurrentDev(device) {
            if (this.loading || this.currentDevice.deviceIndex === device.deviceIndex) return;

            if (this.isPlaying) await this.destroy();

            this.currentDevice = device;
            this.loading = true;

            // requestRelayAccess(this.config.proxyServer, 'ASWMLB=xxx', '/sd/');
            let { proxyServer, cloudDomain } = this.config;
            let domain = cloudDomain || proxyServer.split('/')[2];
            let res;
            try {
                res = await getPreviewUrl(device.deviceIndex, this.config.platform, this.resolution, domain, 'video');
            } catch (error) {
                this.loading = false;
                return;
            }

            if (res === '网络异常' || (res.error_code && res.error_code !== 0)) {
                this.loading = false;
                if (res === '网络异常') {
                    TumsMessage.error(res);
                }
                return;
            }

            let playerOptions = {
                autoplay: true,
                resolution: this.resolution === 1 ? 'HD' : 'VGA',
                defaultStyle: true,
                cover: false,
                streamType: 'video',
                talkEnable: true,
                ptzEnable: true,
                volume: 50
            };

            if (this.isCloud) {
                playerOptions = Object.assign(playerOptions, {
                    url: res.url || res.sdkStreamUrl,
                    type: 'relay',
                    token: res.token,
                    cookie: res.cookie
                });
                // document.cookie = `${res.cookie};path=/;domain=.tp-link.com.cn`;
            } else {
                let capability = await getIpcCapability(device.deviceIndex);
                this.isSupportMultitrans = Boolean(capability.multitrans);
                playerOptions = Object.assign(playerOptions, {
                    url: res.url,
                    socket: res.wssUrl,
                    type: 'rtsp',
                    useMultitrans: this.isSupportMultitrans
                });
            }

            let { appKey, appSecret, authType } = this.config;
            if (authType === 'aksk') {
                playerOptions = Object.assign(playerOptions, {
                    appKey: appKey,
                    appSecret: appSecret,
                    proxyServer: proxyServer
                });
            }
            if (this.player) {
                this.player.init(playerOptions).then(() => {
                    this.addEventHandler();
                });
            } else {
                this.player = new TumsPlayer(this.$refs.player, playerOptions);
                this.addEventHandler();
            }
        },

        addEventHandler() {
            let ref = 'player';
            this.player.on('play', () => {
                this.$emit('event-log', `${ref} trigger event: play`);
                this.isPlaying = true;
            });
            this.player.on('error', ({ detail = {} }) => {
                // if (this.loading) {
                //     this.destroy();
                // }
                this.loading = false;
                this.$emit('event-log', `${ref} trigger event: error\ncode: ${detail.code}: ${detail.message}`);
            });
            this.player.on('playing', () => {
                this.loading = false;
                this.$emit('event-log', `${ref} trigger event: playing`);
            });
            this.player.on('zoom', ({ detail = 1 }) => {
                this.$emit('event-log', `${ref} trigger event: zoom, level = ${detail.scale}`);
            });

            ['ready', 'pause', 'stream', 'disconnected', 'ended'].forEach((event) => {
                this.player.on(event, () => {
                    this.$emit('event-log', `${ref} trigger event: ${event}`);
                });
            });
        },

        suspend(status) {
            if (!this.player) return;
            let player = this.player;
            if (!player) return;
            if (status) {
                if (player.isInit) {
                    this.loading = true;
                    player.start();
                } else {
                    player.play();
                }
            } else {
                player.pause();
            }
            this.isPlaying = !!status;
        },

        async changeResolution(resolution) {
            if (!this.player) return;
            this.resolution = resolution;

            let { proxyServer, cloudDomain } = this.config;
            let domain = cloudDomain || proxyServer.split('/')[2];
            let res = await getPreviewUrl(this.currentDevice.deviceIndex, this.config.platform, this.resolution, domain, 'video');
            if (res.error_code && res.error_code !== 0) {
                return;
            }
            this.player.setResolution(res.sdkStreamUrl || res.url, this.resolution === 1 ? 'HD' : 'VGA');
        },

        playAudio() {
            if (!this.player) return;

            this.isAudioPlaying = !this.isAudioPlaying;
            if (this.isAudioPlaying) {
                this.player.playAudio();
                this.player.setVolume(50);
            } else {
                this.player.setVolume(0);
            }
        },

        async destroy() {
            let player = this.player;
            if (player) {
                await player.destroy();
                this.loading = false;
                this.player = null;
                this.isPlaying = false;
                this.currentDevice = {};
            }
        }
    }
};
</script>

<style lang="scss">
html,
body {
    min-width: unset !important;
}

.mobile-player {
    .tums-icon-loading {
        font-size: 40px;
    }
}

.mobile-player-tool {
    i {
        font-size: 24px;
    }
}

.mobile-device-item {
    height: 72px;

    .tums-icon-ipc {
        font-size: 40px;
    }

    .mobile-device-name {
        font-size: 16px;
        color: #000;
        line-height: 24px;
        font-weight: 600;
    }

    .mobile-device-description {
        margin-top: 2px;
        font-size: 12px;
    }
}

.mobile-player-enterprise {
    font-size: 24px;
    text-align: center;
}

.mobile-player-enterprise-pop {
    width: 375px;
    background: #f5f5f5;

    .mobile-player-enterprise-item {
        line-height: 40px;
        font-size: 18px;
        text-align: center;
    }
}
</style>
