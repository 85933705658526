<template>
    <li
        class="tums-h-48 a-p-t-xs a-p-b-xs a-d-f a-jc-fs a-ai-c a-p-l-md"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
        @click="selectPreset"
    >
        <img :src="imgSrc" class="tums-w-56 tums-h-32">
        <tums-input
            class="a-p-l-xs a-p-r-xs"
            style="width: 96px"
            v-if="editNameStatus"
            v-model="presetName"
            maxlength="20"
            ref="input"
        >
        </tums-input>
        <div v-if="editNameStatus">
            <tums-button type="graytext" icon="tums-icon-close" @click="closeName"></tums-button>
            <tums-button type="graytext" icon="tums-icon-check" @click="saveName"></tums-button>
        </div>
        <span
            v-if="!editNameStatus"
            :title="name"
            @dblclick="editName"
            class="a-p-l-xs f-toe a-p-r-xs"
            style="width: 69px"
        >
            {{name}}
        </span>
        <div v-show="mouseOver && !editNameStatus && !status">
            <tums-button
                type="graytext"
                v-for="type in operation"
                :key="type.name"
                :title="type.title"
                @click.stop="operatePreset(type.name)">
                <i :class="buttonIcon(type)" class="tums-w-16 tums-h-16"></i>
            </tums-button>
        </div>
        <span class="f-fcc" v-if="status">守望中</span>
    </li>
</template>

<script>

const OPERATION_TYPE = {
    APPLY: 'apply',
    RENAME: 'rename',
    CLEAR: 'clear'
};
const MAX_CHAR_LENGTH = 20;

export default {
    name: 'presetItem',

    data() {
        return {
            // 预置点输入框的值
            presetName: this.name,
            // 预置点编辑状态
            editNameStatus: false,
            // 鼠标在该预置点悬浮
            mouseOver: false,
            // 操作按钮
            operation: [{
                name: OPERATION_TYPE.APPLY,
                icon: 'tums-icon-play',
                title: '调用'
            }, {
                name: OPERATION_TYPE.RENAME,
                icon: 'tums-icon-edit-outline',
                title: '重命名'
            }, {
                name: OPERATION_TYPE.CLEAR,
                icon: 'tums-icon-delete',
                title: '清除'
            }]
        };
    },

    props: {
        index: {
            type: Number
        },
        name: {
            type: String
        },
        selected: {
            type: Boolean
        },
        status: {
            type: Boolean
        },
        imgSrc: {
            type: String
        }
    },

    watch: {
        name(val) {
            this.presetName = val;
        },
        editNameStatus(status) {
            if (status) {
                this.presetName = this.name;
            }
        },
        presetName(str) {
            let charLen = str.replace(/[\u4e00-\u9fa5]/g, 'aa').length;
            if (charLen > MAX_CHAR_LENGTH) {
                this.$nextTick(() => {
                    this.presetName = str.slice(0, str.length - 1);
                });
            }
        }
    },

    computed: {
        buttonIcon() {
            return type => {
                if (type.name === OPERATION_TYPE.APPLY) {
                    return this.status ? 'tums-icon-stop' : 'tums-icon-play';
                } else {
                    return type.icon;
                }
            };
        }
    },

    methods: {
        onMouseEnter() {
            this.mouseOver = true;
        },
        onMouseLeave() {
            this.mouseOver = false;
        },
        selectPreset() {
            this.$emit('select');
        },
        editName() {
            this.editNameStatus = true;
            this.$nextTick(() => {
                let input = this.$refs.input;
                input.focus();
                input.select();
            });
        },
        saveName() {
            this.editNameStatus = false;
            console.log('this.presetName', this.presetName);
            this.$emit('change', this.presetName);
        },
        closeName() {
            this.editNameStatus = false;
        },
        operatePreset(type) {
            switch (type) {
                case OPERATION_TYPE.APPLY:
                    this.$emit('apply');
                    break;
                case OPERATION_TYPE.RENAME:
                    this.editName();
                    break;
                case OPERATION_TYPE.CLEAR:
                    this.$emit('clear');
                    break;
            }
        }
    }
};
</script>
<style scoped>
.tums-button--text+.tums-button--text {
    margin-left: 8px;
}
.active {
    background: #E5F3FF;
}
.mark {
    color: #1785e6;
}
.fade {
    color: #999;
}

</style>
