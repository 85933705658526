<template>
    <tums-scrollbar class="scrollbar" v-show="show">
        <p class="a-p-h-md a-p-b-md">
            <tums-button icon="tums-icon-plus" width="208" @click="addCruiseRoute">添加巡航路径</tums-button>
        </p>
        <ul>
            <template v-if="routes.length">
                <cruise-item
                    v-for="(route, index) in routes"
                    :key="route.tourId"
                    :index="index"
                    :tourName="route.tourName"
                    :paths="route.paths"
                    :class="{mark: activeIndex === index}"
                    :selected="route.selected"
                    :status="route.status"
                    @select="selectedRoute(index)"
                    @apply="applyRoute(index)"
                    @configure="configureRoute(index)"
                    @clear="clearRoute(index)">
                </cruise-item>
            </template>
            <div v-else class="u-content-center">
                <p class="a-font7">暂未添加巡航路径</p>
            </div>
        </ul>
         <!-- 添加/ 编辑巡航扫描路径 -->
        <cruise-path
            :showCruiseRoute="showCruiseRoute"
            :presets="presets"
            :cruiseRouteData="cruiseRouteData"
            :cruiseRouteType="cruiseRouteType"
            :id="id"
            @change-show="changeShow"
            @refresh-cruise-path="updateCruiseData"
            :maxTourNumber="maxTourNumber"
            :addTourId="addTourId"
            :tourStayTimeRange="tourStayTimeRange"
        ></cruise-path>
    </tums-scrollbar>
</template>

<script>
import CruiseItem from './cruiseItem';
import CruisePath from './cruisePath.vue';
const {
    Utils
} = window['tums-components'].default;
const { decode, deepCopy } = Utils.util;
export default {
    props: {
        id: {
            type: String
        },
        show: {
            type: Boolean
        },
        presets: {
            type: Array
        },
        tourStayTimeRange: {
            type: Array
        }
    },
    components: {
        CruiseItem,
        CruisePath
    },
    data() {
        return {
            routes: [],
            maxTourNumber: '',
            activeIndex: '',
            seed: Math.random(),
            showCruiseRoute: false,
            cruiseRouteData: {},
            cruiseRouteType: '',
            addTourId: -1
        };
    },
    watch: {
        show(val) {
            if (val) {
                this.updateCruiseData();
            }
        }
    },
    methods: {
        updateCruiseData() {
            let tourIds;
            let tourStates;
            let tourNames;
            this.routes = [];
            tumsAxios.chainPost('/tums/ptz/v1/getAllTours', {
                id: this.id
            }).then(res => {
                let data = res.result;
                tourIds = data.tourId || [];
                tourStates = data.tourStates || [];
                tourNames = data.tourNames || [];
                this.maxTourNumber = data.maxTourNumber;
                let getCurrentTourId = deepCopy(tourIds).sort((a, b) => {
                    return a - b;
                });
                this.addTourId = -1;
                getCurrentTourId.forEach((item, index) => {
                    if (this.addTourId === -1) {
                        if (Number(item) !== index + 1) {
                            this.addTourId = index + 1;
                        }
                    }
                });
                this.addTourId = this.addTourId === -1 ? getCurrentTourId.length + 1 : this.addTourId;
                // 未设置任何预置点
                if (tourIds.length === 0) {
                    return Promise.resolve({
                        result: [],
                        error_code: 0
                    });
                }

                return tumsAxios.chainPost('/tums/ptz/v1/getToursInfo', {
                    id: this.id,
                    tourId: tourIds
                });
            }).then(res => {
                let routePaths = res.result.map(route => {
                    let presetIds = route.presetIds || [];
                    let times = route.times || [];
                    let speedPans = route.speedPans || [];

                    return presetIds.map((presetId, index) => {
                        return {
                            presetId: presetId,
                            speed: parseInt(speedPans[index] * 40, 10),
                            time: parseInt(times[index] / 1000, 10),
                            uniqueId: this._genUniqueId()
                        };
                    });
                });
                tourIds.forEach((tourId, i) => {
                    this.routes.push({
                        paths: routePaths[i],
                        status: tourStates[i],
                        tourId: tourId,
                        id: this.id,
                        tourName: decode(tourNames[i]) || `巡航路径${i}`,
                        // 巡航路径选中状态
                        selected: false,
                        maxTourNumber: this.maxTourNumber
                    });
                });
            });
        },
        _genUniqueId() {
            return this.seed++;
        },
        selectedRoute() {
            //
        },
        // 播放
        applyRoute(index) {
            let route = this.routes[index];
            let action = route.status === 'idle'
                ? 'start'
                : 'stop';
            if (route.status === 'idle') {
                this.routes[index].status = 'touring';
            } else if (route.status === 'touring') {
                this.routes[index].status = 'idle';
            }
            tumsAxios.post('/tums/ptz/v1/executeTour', {
                id: this.id,
                tourId: this.routes[index].tourId,
                action: action
            }, () => {
                if (route.status === 'idle') {
                    this.routes[index].status = 'touring';
                } else if (route.status === 'touring') {
                    this.routes[index].status = 'idle';
                }
            });
        },
        // 编辑
        configureRoute(index) {
            this.showCruiseRoute = true;
            this.cruiseRouteType = 'edit';
            this.cruiseRouteData = this.routes[index];
        },
        // 删除
        clearRoute(index) {
            tumsAxios.post('/tums/ptz/v1/deleteTour', {
                id: this.id,
                tourId: this.routes[index].tourId
            }, () => {
                this.updateCruiseData();
            });
        },
        changeShow(val) {
            this.showCruiseRoute = val;
        },
        // 添加巡航扫描
        addCruiseRoute() {
            this.showCruiseRoute = true;
            this.cruiseRouteType = 'add';
        }
    }
};
</script>
