export function getDeviceList(regionId) {
    let api = '/tums/regionDevice/v1/getDeviceInRegionWithPermission';
    let param = {
        regionId,
        permissionList: [0, 1, 2]
    };

    return tumsAxios.chainPostProxy(api, param).then((res) => {
        let deviceList = res.result || [];
        return deviceList;
    });
}

export function getAllDevice(param) {
    let api = '/tums/deviceManager/v2/getDeviceList';

    return tumsAxios.chainPostProxy(api, param).then(({ result = {} }) => {
        let deviceList = result.list || [];
        return deviceList;
    });
}

export function passthrough(param) {
    let api = '/tums/devConfig/v1/passthrough';
    return new Promise((resolve) => {
        return tumsAxios
            .chainPostProxy(api, param, (errData) => {
                resolve(errData);
            })
            .then((res) => resolve(res));
    });
}

export function nvrChnPassthrough(devId, channel, content) {
    let param = {
        devId,
        param: {
            method: 'forward',
            destination: 'ipc',
            channel: channel,
            content
        }
    };
    return passthrough(param).then((res) => {
        if (res.error_code !== 0) return res;
        return res.content;
    });
}

export function nvrDeviceMicrophone(devId, content) {
    let param = {
        devId,
        param: content
    };
    return passthrough(param).then((res) => {
        return res;
    });
}

export function getIpcCapability(devId) {
    return new Promise(resolve => {
        tumsAxios.postProxy('/tums/deviceManager/v1/getIpcCapability', {
            devId: devId
        }, ({ result = {} }) => {
            let capability = result.capability || {};
            // let supportMultitrans = Boolean(result.multitrans);
            resolve(capability);
        }, (_, errMsg) => {
            TumsMessage.error(errMsg);
            resolve({});
        });
    });
}
