
import { NALUAsm } from '@/elementary/NALUAsm';
import { NALUAsmH265 } from '@/elementary/NALUAsmH265';
import { AACAsm } from '@/elementary/AACAsm';
import PayloadType from '@/utils/payloadType';
import { getTagged } from '@/utils/logger';
import { getUint64 } from '@/utils/binary.js';

const Log$7 = getTagged('client:rtsp');

export class RTP {
    constructor(pkt /*uint8array*/, sdp) {
        let bytes = new DataView(pkt.buffer, pkt.byteOffset, pkt.byteLength);
        this.version = bytes.getUint8(0) >>> 6;
        this.padding = (bytes.getUint8(0) & 0x20) >>> 5;
        this.has_extension = (bytes.getUint8(0) & 0x10) >>> 4;
        this.csrc = bytes.getUint8(0) & 0x0f;
        this.marker = bytes.getUint8(1) >>> 7;
        this.pt = bytes.getUint8(1) & 0x7f;
        this.sequence = bytes.getUint16(2);
        this.timestamp = bytes.getUint32(4);
        this.ssrc = bytes.getUint32(8);
        this.csrcs = [];

        let pktIndex = 12; // 字节索引号
        if (this.csrc > 0) {
            this.csrcs.push(bytes.getUint32(pktIndex));
            pktIndex += 4;
        }
        if (this.has_extension == 1) {
            this.extension = bytes.getUint16(pktIndex); // 扩展头版本号
            this.ehl = bytes.getUint16(pktIndex + 2); // 扩展头长度(4字节为一个长度)
            pktIndex += 4;

            this.header_data = pkt.slice(pktIndex, pktIndex + this.ehl * 4);

            // slp设备
            if (this.extension === 0x0001) {
                this.utc_timestamp = Math.floor(getUint64(bytes, pktIndex) / 1000 / 1000); // 统一时间单位，微秒转为10位时间戳
            }

            // nvmp设备
            if (this.extension === 0x0100) {
                let headerIdx = 0;
                while(headerIdx < this.ehl * 4) {
                    let start = pktIndex + headerIdx;
                    // RTP头部扩展类型
                    let type = bytes.getUint8(start);
                    start += 1;

                    switch(type) {
                        case 1:
                            // 通道号
                            this.channel = bytes.getUint8(start + 2);
                            start += 3;
                            break;
                        case 2:
                            // 音频参数
                            this.frequency = bytes.getUint8(start + 3);
                            this.bitwidth = bytes.getUint16(start + 4);
                            this.audio_channels = bytes.getUint8(start + 6);
                            start += 7;
                            break;
                        case 3:
                            // 帧标记
                            this.frame_flg = bytes.getUint8(start + 2);
                            start += 3;
                            break;
                        case 4:
                            // UTC时间
                            this.utc_timestamp = bytes.getUint32(start + 3);
                            start += 7;
                        default:
                            break;
                    }

                    headerIdx = start - pktIndex;
                }
            }

            pktIndex += this.ehl * 4;
        }

        this.headerLength = pktIndex;
        // let padLength = 0;
        // if (this.padding) {
        //     padLength = bytes.getUint8(pkt.byteLength - 1);
        // }
        // this.bodyLength	 = pkt.byteLength-this.headerLength-padLength;

        this.media = sdp.getMediaBlockByPayloadType(this.pt);
        if (null === this.media) {
            Log$7.log(`Media description for payload type: ${this.pt} not provided.`);
        } else {
            this.type = this.media.ptype; //PayloadType.string_map[this.media.rtpmap[this.media.fmt[0]].name];
        }

        this.data = pkt.subarray(pktIndex);
        // this.timestamp = 1000 * (this.timestamp / this.media.rtpmap[this.pt].clock);
        // console.log(this);
    }
    getPayload() {
        return this.data;
    }

    getTimestampMS() {
        return this.timestamp; //1000 * (this.timestamp / this.media.rtpmap[this.pt].clock);
    }

    toString() {
        return (
            'RTP(' +
            'version:' +
            this.version +
            ', ' +
            'padding:' +
            this.padding +
            ', ' +
            'has_extension:' +
            this.has_extension +
            ', ' +
            'csrc:' +
            this.csrc +
            ', ' +
            'marker:' +
            this.marker +
            ', ' +
            'pt:' +
            this.pt +
            ', ' +
            'sequence:' +
            this.sequence +
            ', ' +
            'timestamp:' +
            this.timestamp +
            ', ' +
            'ssrc:' +
            this.ssrc +
            ')'
        );
    }

    isVideo() {
        return this.media.type == 'video';
    }
    isAudio() {
        return this.media.type == 'audio';
    }
}

export class RTPFactory {
    constructor(sdp) {
        this.tsOffsets = {};
        for (let pay in sdp.media) {
            // pay -> audio/video
            for (let pt of sdp.media[pay].fmt) {
                Log$7.log(pt);
                this.tsOffsets[pt] = { last: 0, overflow: 0 };
            }
        }
    }

    build(pkt /*uint8array*/, sdp) {
        let rtp = new RTP(pkt, sdp);

        let tsOffset = this.tsOffsets[rtp.pt];
        if (tsOffset) {
            rtp.timestamp += tsOffset.overflow;
            if (tsOffset.last && Math.abs(rtp.timestamp - tsOffset.last) > 0x7fffffff) {
                Log$7.log(`\nlast ts: ${tsOffset.last}\n
                            new ts: ${rtp.timestamp}\n
                            new ts adjusted: ${rtp.timestamp + 0xffffffff}\n
                            last overflow: ${tsOffset.overflow}\n
                            new overflow: ${tsOffset.overflow + 0xffffffff}\n
                            `);
                tsOffset.overflow += 0xffffffff;
                rtp.timestamp += 0xffffffff;
            }
            /*if (rtp.timestamp>0xffffffff) {
                console.log(`ts: ${rtp.timestamp}, seq: ${rtp.sequence}`);
            }*/
            tsOffset.last = rtp.timestamp;
        }

        return rtp;
    }
}

export class G711AFrame {
    constructor(data, dts, pts) {
        this.dts = dts;
        this.pts = pts ? pts : this.dts;
        this.data = data; //.subarray(offset);
    }

    getData() {
        return this.data;
    }

    getSize() {
        return this.data.byteLength;
    }
}

export class RTPH264Parser {
    constructor() {
        this.naluasm = new NALUAsm();
    }

    parse(rtp) {
        return this.naluasm.onNALUFragment(rtp.getPayload(), rtp.timestamp);
    }
}

export class RTPH265Parser {
    constructor() {
        this.naluasm = new NALUAsmH265();
    }

    parse(rtp) {
        return this.naluasm.onNALUFragment(rtp.getPayload(), rtp.timestamp);
    }
}

export class RTPg711Parser {
    constructor() {
        this.scale = 1;
    }

    parse(rtp) {
        return [new G711AFrame(rtp.data, rtp.timestamp, rtp.timestamp)];
    }
}

export class RTPAACParser {
    constructor() {
        this.scale = 1;
        this.asm = new AACAsm();
    }

    setConfig(conf) {
        this.asm.config = conf;
    }

    parse(rtp) {
        return this.asm.onAACFragment(rtp);
    }
}

export class RTPPayloadParser {
    constructor() {
        this.h264parser = new RTPH264Parser();
        this.h265parser = new RTPH265Parser();
        this.aacparser = new RTPAACParser();
        this.g711parser = new RTPg711Parser();
    }

    parse(rtp) {
        if (rtp.media.ptype == PayloadType.H265) {
            return this.h265parser.parse(rtp);
        } else if (rtp.media.ptype == PayloadType.H264) {
            return this.h264parser.parse(rtp);
        } else if (rtp.media.type == 'audio') {
            return this.g711parser.parse(rtp);
        }
        return null;
    }
}
