<template>
    <div class="tums-player-preview tums-w-full">
        <div class="tums-player-content a-bs-b a-bb-base">
            <!-- <div class="a-h-full a-w-full" ref="tumsPlayer"></div> -->
            <div
                v-for="i of layoutArr[0]"
                class="a-bb-darker a-d-f a-ai-c a-bs-b"
                :key="'row_' + i"
                :style="{
                    width: '100%',
                    height: `${100 / layoutArr[0]}%`,
                }"
            >
                <div
                    v-for="j of layoutArr[1]"
                    class="a-pos-r a-br-darker a-bs-b"
                    :key="'col_' + j"
                    :style="{
                        width: `${100 / layoutArr[1]}%`,
                        height: '100%',
                    }"
                    @click="setCurrentWindow(j + layoutArr[1] * (i - 1))"
                    @dragover.prevent
                    @drop="drop(j + layoutArr[1] * (i - 1), $event)"
                >
                    <div class="tums-player-border" v-show="currentWindow === j + layoutArr[1] * (i - 1)"></div>
                    <tums-button
                        type="graytext"
                        class="tums-player-close tums-icon-close"
                        v-show="currentWindow === j + layoutArr[1] * (i - 1) && players['tumsPlayer' + (j + layoutArr[1] * (i - 1))]"
                        @click="destroy(j + layoutArr[1] * (i - 1), $event)"
                    ></tums-button>
                    <div class="tums-player a-h-full a-w-full" :ref="'tumsPlayer' + (j + layoutArr[1] * (i - 1))">
                        <div v-if="loading['tumsPlayer' + (j + layoutArr[1] * (i - 1))]" class="tums-icon-loading"></div>
                        <div v-if="recording[j + layoutArr[1] * (i - 1)]" class="tums-player-recording a-pos-a a-font5 a-font-b">
                            <p class="a-c-primary">
                                <span>视频录制 大小：{{recording[j + layoutArr[1] * (i - 1)].size + ' '}}</span>
                                <span>时长：{{recording[j + layoutArr[1] * (i - 1)].time}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="a-p-h-lg a-p-v-sm">
            <tums-button v-if="isPlaying" type="graytext" icon="tums-icon-pause" @click="suspend(0)"></tums-button>
            <tums-button v-if="!isPlaying" type="graytext" icon="tums-icon-play" @click="suspend(1)"></tums-button>
            <tums-select class="a-m-l-xs" v-model="resolution" size="mini" @change="changeResolution">
                <tums-option label="主码流" :value="1"></tums-option>
                <tums-option label="子码流" :value="-1"></tums-option>
            </tums-select>
            <tums-button class="a-m-l-lg" size="mini" @click="fullscreen">全屏</tums-button>
            <tums-button class="a-m-l-lg" size="mini" @click="screenshot">截图</tums-button>
            <tums-button v-if="!recording[currentWindow]" class="a-m-l-lg" size="mini" @click="startRecording">录制</tums-button>
            <tums-button v-else class="a-m-l-lg" size="mini" @click="stopRecording">停止录制</tums-button>
            <tums-button v-if="lensMaskEnable" class="a-m-l-lg" size="mini" @click="changeLensMaskValue">
                {{ lensMaskValueMap[devMap['tumsPlayer' + currentWindow]] === 'off' ? '镜头遮蔽' : '取消遮蔽'}}
            </tums-button>
            <tums-button
                type="graytext"
                icon="tums-icon-cloudterrace"
                @click="handleShowPtzPanel"
                v-if="ptzEnable"
            >
            </tums-button>
            <tums-button
                class="a-m-l-xs"
                :type="isZoom ? 'text' : 'graytext'"
                icon="tums-icon-zoom-in"
                @click="zoom"
            ></tums-button>
            <tums-popover popper-class="u-operation-content-popper" width="260">
                <tums-button
                    :disabled="!currentPlayer"
                    class="a-m-l-xs"
                    icon="tums-icon-volume"
                    type="graytext"
                    slot="reference"
                    @click="getVolume"
                ></tums-button>
                <div>
                    <tums-progress
                        style="width: 200px;display:inline-block;"
                        :percent="volume"
                        show-thunk
                        is-drag
                        clickable
                        hide-info
                        @on-percent-change="setVolume"
                    ></tums-progress>
                    <span class="a-m-l-sm a-font4">{{volume}}</span>
                </div>
            </tums-popover>
            <tums-button
                size="mini"
                class="a-m-l-lg"
                @click="voiceIntercom(false)"
                :type="isVoiceIntering? 'primary': ''"
                v-if="talkEnable && audioVoiceEnable"
            >{{`${isVoiceIntering? '挂断': '喊话'}`}}
            </tums-button>
            <tums-button
                size="mini"
                class="a-m-l-lg"
                @click="voiceIntercom(true)"
                :type="isAecVoiceIntering? 'primary': ''"
                v-if="talkEnable && audioVoiceEnable"
            >{{`${isAecVoiceIntering? '挂断': '通话'}`}}
            </tums-button>
            <tums-select v-model="fishEyeMode" v-if="fishEyeEnable" class="a-m-l-xs" size="mini" @change="setFishEyeMode">
                <tums-option
                    v-for="item in fishEyeOptions"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                ></tums-option>
            </tums-select>
            <tums-select v-model="flipType" class="a-m-l-xs" size="mini" style="width: 80px" @change="setFlipType">
                <tums-option
                    v-for="item in flipTypes"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label">
                </tums-option>
            </tums-select>
            <tums-select v-if="rotateEnable" v-model="rotateMode" class="a-m-l-xs" size="mini" style="width: 140px" @change="setRotateMode">
                <tums-option
                    v-for="item in rotateOptions"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label">
                </tums-option>
            </tums-select>
        </div>
    </div>
</template>

<script>
import TumsPlayer from '@/index';
import { getPreviewUrl } from '../api/getPreviewUrl';
import { passthrough, nvrChnPassthrough, nvrDeviceMicrophone } from '../api/device';
import { rotateOptions, flipTypes } from '../utils/constant';

export default {
    name: 'Preview',
    props: {
        config: {
            type: Object,
            default() {
                return {
                    platform: '',
                };
            },
        },
        currentDevice: Object,
        autoplay: {
            type: Boolean,
            default: true,
        },
        layout: {
            type: String,
            default: '1 x 1',
        },
        audioVoiceEnable: {
            type: Boolean,
            default: true
        },
        decoderType: String
    },
    data() {
        const fishEyeOptions = [{
            value: 'ORIGIN',
            label: '原始图像'
        }, {
            value: 'FISHEYE_360D',
            label: '顶装360度全景'
        }, {
            value: 'FISHEYE_180D',
            label: '顶装180度全景'
        }, {
            value: 'FISHEYE_WIN_PLANE_TOP_QUAD',
            label: '顶装四分屏'
        }, {
            value: 'FISHEYE_LONGITUDE',
            label: '壁装全景'
        }];

        return {
            player: null,
            isPlaying: false,
            resolution: 1,
            currentWindow: -1,
            currentPlayer: null,
            players: {},
            loading: {},
            devMap: {},
            zoomState: {},
            isZoom: false,
            // 语音对讲
            isVoiceIntering: false,
            isAecVoiceIntering: false,
            talkEnable: false,
            recording: {},
            isSupportMultitrans: false,
            recordInterval: [],
            talkMap: {},
            volume: 50,
            // 设备能力集
            moduleSpec: {},
            // 鱼眼配置
            fishEye: {},
            fishEyeEnable: false,
            fishEyeOptions,
            fishEyeMode: 'ORIGIN',
            getTalkAudioStream: false,
            // 云台
            ptzMap: {},
            ptzEnable: false,
            // 通话
            isAec: false,
            // 镜头遮蔽
            lensMaskCapabilityMap: {},
            lensMaskValueMap: {},
            lensMaskEnable: false,
            // 画面镜像
            flipTypeValueMap: {},
            flipTypes,
            flipType: 'off',
            // 画面旋转
            rotateCapabilityMap: {},
            rotateValueMap: {},
            rotateEnable: false,
            rotateOptions,
            rotateMode: 'off'
        };
    },
    computed: {
        isCloud() {
            return this.config.platform === 'cloud';
        },
        layoutArr() {
            return this.layout.split(' x ').map((item) => parseInt(item));
        },
    },
    watch: {
        layoutArr() {
            let { layoutArr, players } = this;
            let windowNum = layoutArr[0] * layoutArr[1];
            for (let i = 1; i < 6; i++) {
                if (i > windowNum) {
                    let ref = 'tumsPlayer' + i;
                    if (!players[ref]) return;
                    players[ref].destroy();
                    players[ref] = undefined;
                }
            }
        }
    },
    beforeDestroy() {
        Object.keys(this.players).forEach((ref) => {
            this.players[ref] && this.players[ref].destroy();
            this.loading[ref] = false;
        });
    },
    methods: {
        getIpcCapability(devId) {
            return new Promise(resolve => {
                tumsAxios.post('/tums/deviceManager/v1/getIpcCapability', {
                    devId: devId
                }, ({ result = {} }) => {
                    let capability = result.capability || {};
                    // let supportMultitrans = Boolean(result.multitrans);
                    resolve(capability);
                }, (_, errMsg) => {
                    TumsMessage.error(errMsg);
                    resolve({});
                });
            });
        },
        getMultitransUrl(devId) {
            return new Promise((resolve, reject) => {
                tumsAxios.post('/tums/preview/v1/getMultitransUrl', {
                    devId
                }, ({ result = {} }) => {
                    resolve(result);
                }, (errData, errMsg) => {
                    TumsMessage.error(errMsg);
                    reject(errData);
                });
            });
        },
        // 语音对讲
        async voiceIntercom(isAec) {
            this.isAec = isAec;
            if (!this.isPlaying) return;
            // 建立relay通道进行传输
            if (!this.currentPlayer) return;

            if (this.isCloud) {
                let { proxyServer, cloudDomain } = this.config;
                let domain = cloudDomain || proxyServer.split('/')[2];
                let ref = 'tumsPlayer' + this.currentWindow;
                let res = await getPreviewUrl(this.devMap[ref], this.config.platform, this.resolution, domain, 'dtspk');
                if (!this.currentPlayer) return;
                if (res.error_code && res.error_code !== 0) {
                    if (this.isAec) {
                        this.isAecVoiceIntering = false;
                    } else {
                        this.isVoiceIntering = false;
                    }
                    return;
                }
                let player = this.players[ref];
                if (this.isAec) {
                    this.isAecVoiceIntering = !this.isAecVoiceIntering;
                } else {
                    this.isVoiceIntering = !this.isVoiceIntering;
                }
                if (this.isVoiceIntering || this.isAecVoiceIntering) {
                    // 正在对讲  传输数据
                    player.startVoiceIntercom({
                        url: res.sdkStreamUrl,
                        mode: this.isAec ? 'aec' : 'half_duplex'
                    });
                } else {
                    player.stopVoiceIntercom();
                }
            } else {
                let ref = 'tumsPlayer' + this.currentWindow;
                if (this.isSupportMultitrans) {
                    this.rtspMultitrans(ref);
                } else {
                    this.slpMultipart(ref);
                }
            }
        },
        // rtsp multitrans 对讲
        async rtspMultitrans(ref) {
            let player = this.players[ref];
            if (this.isAec) {
                this.isAecVoiceIntering = !this.isAecVoiceIntering;
            } else {
                this.isVoiceIntering = !this.isVoiceIntering;
            }
            if (this.isVoiceIntering || this.isAecVoiceIntering) {
                let urlData = await this.getMultitransUrl(this.currentDevice.devId);
                if (!urlData.httpsPort) urlData.httpsPort = 10181;
                if (!this.currentPlayer) return;
                if (urlData.error_code && urlData.error_code !== 0) {
                    if (this.isAec) {
                        this.isAecVoiceIntering = false;
                    } else {
                        this.isVoiceIntering = false;
                    }
                    return;
                }
                // 正在对讲  传输数据
                player.startVoiceIntercom({
                    url: urlData.rtspUrl,
                    wssUrl: urlData.wssUrl,
                    mode: this.isAec ? 'aec' : 'half_duplex'
                });
            } else {
                player.stopVoiceIntercom();
                this.getTalkAudioStream = false;
            }
        },
        // slp multipart 对讲
        async slpMultipart(ref) {
            if (!this.currentPlayer) return;

            if (this.isAec) {
                this.isAecVoiceIntering = !this.isAecVoiceIntering;
            } else {
                this.isVoiceIntering = !this.isVoiceIntering;
            }

            let urlData = await this.requestBidirectionStream(this.currentDevice.devId);

            if (urlData.error_code) {
                if (this.isAec) {
                    this.isAecVoiceIntering = false;
                } else {
                    this.isVoiceIntering = false;
                }
                return;
            }

            if (!urlData.httpsPort) urlData.httpsPort = 10181;
            urlData.deviceModel = this.currentDevice.deviceModel;
            let wssUrl = `ws://${urlData.serverUrl}:${urlData.port}/ws/`;
            let player = this.players[ref];
            if (this.isVoiceIntering || this.isAecVoiceIntering) {
                // 正在对讲  传输数据
                player.startVoiceIntercom({
                    url: `${urlData.protocol}://${urlData.serverUrl}:${urlData.port}/`,
                    wssUrl: wssUrl,
                    slpData: urlData,
                    mode: this.isAec ? 'aec' : 'half_duplex'
                });
            } else {
                player.stopVoiceIntercom();
            }
        },
        requestBidirectionStream(devId) {
            return new Promise((resolve, reject) => {
                tumsAxios.post('/tums/preview/v1/requestBidirectionStream', {
                    devId
                }, ({ result = {} }) => {
                    resolve(result);
                }, (errData, errMsg) => {
                    TumsMessage.error(errMsg);
                    resolve(errData);
                });
            });
        },
        suspend(status) {
            if (!this.currentPlayer) return;
            let ref = 'tumsPlayer' + this.currentWindow;
            let player = this.players[ref];
            if (!player) return;
            if (status) {
                if (player.isInit) {
                    this.loading[ref] = true;
                    this.loading = Object.assign({}, this.loading);
                    player.start();
                } else {
                    player.play();
                }
            } else {
                player.pause();
                if (this.isVoiceIntering) {
                    this.isVoiceIntering = false;
                    player.stopVoiceIntercom();
                }
                if (this.isAecVoiceIntering) {
                    this.isAecVoiceIntering = false;
                    player.stopVoiceIntercom();
                }
            }
            this.isPlaying = !!status;
        },

        setCurrentWindow(wIndex) {
            this.currentWindow = wIndex;
            let ref = 'tumsPlayer' + wIndex;
            let devId = this.devMap[ref];
            this.currentPlayer = this.players[ref];
            this.isZoom = this.zoomState[ref];
            this.isPlaying = false;
            this.talkEnable = !!this.talkMap[devId];
            this.fishEyeEnable = !!this.fishEye[devId];
            this.ptzEnable = !!this.ptzMap[devId];
            this.lensMaskEnable = !!this.lensMaskCapabilityMap[devId];
            this.rotateEnable = !!this.rotateCapabilityMap[devId];

            if (!this.currentPlayer) return;
            this.isPlaying = this.currentPlayer.isPlaying();
            this.resolution = this.currentPlayer.resolution === 'HD' ? 1 : -1;
            this.fishEyeMode = this.currentPlayer.fishEyeDisplayMode;
            this.flipType = this.flipTypeValueMap[devId];
            this.rotateMode = this.rotateValueMap[devId];
        },

        async destroy(wIndex) {
            let ref = 'tumsPlayer' + wIndex;
            let player = this.players[ref];
            if (player) {
                await player.destroy();
                this.loading[ref] = false;
                this.players[ref] = undefined;
                this.devMap[ref] = undefined;
                this.currentPlayer = null;
                this.isPlaying = false;
                this.zoomState[ref] = false;
                this.isZoom = false;
                this.isVoiceIntering = false;
                this.isAecVoiceIntering = false;
                this.recording[wIndex] = null;
                this.currentWindow = -1;
                this.recordInterval.forEach(item => {clearInterval(item);});
                this.recordInterval = [];
                this.fishEyeEnable = false;
                this.ptzEnable = false;
                this.talkEnable = false;
                this.lensMaskEnable = false;
                this.rotateEnable = false,
                this.$emit('hanle-show-ptzPanel', this.ptzEnable);
            }
            event && event.stopPropagation && event.stopPropagation();
        },

        async drop(wIndex) {
            let ref = 'tumsPlayer' + wIndex;

            if (this.loading[ref]) return;

            if (this.autoplay) {
                this.loading[ref] = true;
                this.loading = Object.assign({}, this.loading);
            }

            // requestRelayAccess(this.config.proxyServer, 'ASWMLB=xxx', '/sd/');
            let { proxyServer, cloudDomain } = this.config;
            let domain = cloudDomain || proxyServer.split('/')[2];
            let res;
            try {
                res = await getPreviewUrl(this.currentDevice.devId, this.config.platform, this.resolution, domain, 'video');
            } catch (error) {
                this.loading[ref] = false;
                return;
            }

            if (res === '网络异常' || (res.error_code && (res.error_code !== 0))) {
                this.loading[ref] = false;
                if (res === '网络异常') {
                    TumsMessage.error(res);
                }
                return;
            }

            let playerOptions = {
                autoplay: this.autoplay,
                resolution: this.resolution === 1 ? 'HD' : 'VGA',
                defaultStyle: true,
                cover: false,
                streamType: this.streamType,
                talkEnable: true,
                audioVoiceEnable: this.audioVoiceEnable,
                ptzEnable: true,
                volume: this.volume,
                decoderType: this.decoderType,
                parentId: this.currentDevice.parentId,
                channel: this.currentDevice.channel,
                devId: this.currentDevice.devId
            };

            if (this.isCloud) {
                playerOptions = Object.assign(playerOptions, {
                    url: res.url || res.sdkStreamUrl,
                    type: 'relay',
                    token: res.token,
                    cookie: res.cookie,
                });
                // document.cookie = `${res.cookie};path=/;domain=.tp-link.com.cn`;
            } else {
                let capability = await this.getIpcCapability(this.currentDevice.devId);
                this.isSupportMultitrans = Boolean(capability.multitrans);
                playerOptions = Object.assign(playerOptions, {
                    url: res.url,
                    socket: res.wssUrl,
                    type: 'rtsp',
                    useMultitrans: this.isSupportMultitrans,
                });
            }
            this.devMap[ref] = this.currentDevice.devId;

            let { appKey, appSecret, authType } = this.config;
            if (authType === 'aksk') {
                playerOptions = Object.assign(playerOptions, {
                    appKey: appKey,
                    appSecret: appSecret,
                    proxyServer: proxyServer
                });
            }
            if (this.players[ref]) {
                this.players[ref].init(playerOptions).then(() => {
                    this.addEventHandler(wIndex);
                });
            } else {
                this.players[ref] = new TumsPlayer(this.$refs[ref][0], playerOptions);
                this.addEventHandler(wIndex);
            }

            this.devMap[ref] = this.currentDevice.devId;

            this.getFishEyeCapability();
            this.getSpeakerCapability();
            this.getPtzCapability();

            let devId = this.currentDevice.devId;
            this.getPlayerModuleSpec(this.players[ref]).then(() => {
                if (this.lensMaskCapabilityMap[devId]) this.getLensMaskValue(wIndex);
                this.getImageSwitch(wIndex).then(() => {
                    this.flipType = this.flipTypeValueMap[devId];
                    if (this.rotateCapabilityMap[devId]) this.rotateMode = this.rotateValueMap[devId];
                }).catch(err => {
                    this.flipType = 'off';
                    this.rotateMode = 'off';
                    console.error('getImageSwitch error: ', err);
                });
            });
        },

        async getSpeakerCapability() {
            let { devId, parentId, channel } = this.currentDevice || {};
            if (this.talkMap[devId] !== undefined) {
                this.talkEnable = !!this.talkMap[devId];
                return this.talkMap[devId];
            }

            let talkEnable = false;
            let param = {
                method: 'get',
                audio_capability: {
                    name: ['device_speaker', 'device_microphone']
                },
                audio_config: {
                    name: ['speaker', 'microphone']
                }
            };
            if (channel) {
                let moduleSpec = await this.getModuleSpec({ devId: parentId });
                if (moduleSpec.channel_talk === '1') {
                    let res = await nvrChnPassthrough(parentId, channel, param);
                    if (res.error_code === 0) {
                        // let param2222 =  {
                        //     method: "get",
                        //     audio_capability: {
                        //         name: "chn1_device_microphone"
                        //     }
                        // };
                        // let res1 = await nvrDeviceMicrophone(parentId, param2222);
                        // if (res1.error_code === 0 && res1.audio_capability.chn1_device_microphone.echo_cancelling === '1') {
                        //     // 用aec
                        //     this.echoType = 'aec';
                        // } else {
                        //     // half_duplex
                        //     this.echoType = 'half_duplex';
                        // }
                        talkEnable = true;
                    } else {
                        talkEnable = false;
                    }

                } else {
                    talkEnable = false;
                }
            } else {
                let res = await passthrough({ devId, param });
                talkEnable = !res.error_code;
            }
            this.talkMap[devId] = talkEnable;
            this.talkEnable = talkEnable;
            return talkEnable;
        },
        async getPtzCapability() {
            let { devId, parentId, channel } = this.currentDevice || {};
            if (this.ptzMap[devId] !== undefined) {
                this.ptzEnable = !!this.ptzMap[devId];
                return this.ptzMap[devId];
            }

            if (channel > 0) {
                let content = {
                    method: "get",
                    ptz: {
                        name: "capability"
                    }
                };
                let res = await nvrChnPassthrough(parentId, channel, content);
                this.ptzMap[devId] = res.error_code === 0;
            } else {
                let param = {
                    method: 'get',
                    function: {
                        name: "module_spec"
                    }
                };
                let ptzEnableData = await passthrough({
                    devId: devId,
                    param
                });
                this.ptzMap[devId] = ptzEnableData.error_code === 0 && ptzEnableData.function.module_spec.ptz === '1';
            }
            this.ptzEnable = this.ptzMap[devId];
            return this.ptzMap[devId];
        },
        manualPlay(playerOption) {
            if (!playerOption.url) return;

            let ref = 'tumsPlayer1';

            if (playerOption.autoplay) {
                this.loading[ref] = true;
            }

            if (!this.players[ref]) {
                this.players[ref] = new TumsPlayer(this.$refs[ref][0], playerOption);
                this.addEventHandler(1);
            } else {
                this.players[ref].init(playerOption);
            }
            this.setCurrentWindow(1);
        },

        addEventHandler(wIndex) {
            let ref = 'tumsPlayer' + wIndex;
            this.players[ref].on('play', () => {
                if (this.currentWindow < 0) {
                    this.setCurrentWindow(wIndex);
                }
                this.$emit('event-log', `${ref} trigger event: play`);
            });
            this.players[ref].on('error', ({ detail = {} }) => {
                if (detail.type && detail.type === 'audio') {
                    if (this.isVoiceIntering) {
                        this.isVoiceIntering = false;
                    }
                    if (this.isAecVoiceIntering) {
                        this.isAecVoiceIntering = false;
                    }
                }
                if (detail.code === 1111) {
                    if (this.isVoiceIntering) {
                        this.isVoiceIntering = false;
                    }
                    if (this.isAecVoiceIntering) {
                        this.isAecVoiceIntering = false;
                    }
                }
                if (this.loading[ref] === true) {
                    this.destroy(wIndex);
                }
                this.loading[ref] = false;
                this.loading = Object.assign({}, this.loading);
                this.$emit('event-log', `${ref} trigger event: error\ncode: ${detail.code}: ${detail.message}`);
            });
            this.players[ref].on('playing', () => {
                this.loading[ref] = false;
                this.loading = Object.assign({}, this.loading);
                // this.logs.unshift(`${ref} trigger event: playing`);
                this.$emit('event-log', `${ref} trigger event: playing`);
            });
            this.players[ref].on('zoom', ({ detail = 1 }) => {
                this.$emit('event-log', `${ref} trigger event: zoom, level = ${detail}`);
            });
            this.players[ref].on('warning', ({ detail = 1 }) => {
                this.$emit('event-log', `${ref} trigger event: warning\ncode: ${detail.code}: ${detail.message}`);
            });

            ['ready', 'pause', 'stream', 'disconnected', 'ended'].forEach((event) => {
                this.players[ref].on(event, () => {
                    this.$emit('event-log', `${ref} trigger event: ${event}`);
                });
            });
            this.players[ref].on('sendAudioStream', () => {
                this.getTalkAudioStream = true;
            });
            this.players[ref].on('videoRecordFinished', () => {
                this.recording[wIndex] = false;
                clearInterval(this.recordInterval[wIndex]);
            });
        },

        async changeResolution() {
            if (!this.currentPlayer) return;
            let { proxyServer, cloudDomain } = this.config;
            let domain = cloudDomain || proxyServer.split('/')[2];
            let ref = 'tumsPlayer' + this.currentWindow;
            let res = await getPreviewUrl(this.devMap[ref], this.config.platform, this.resolution, domain, 'video');
            if (res.error_code && res.error_code !== 0) {
                return;
            }
            this.players[ref].setResolution(res.sdkStreamUrl || res.url, this.resolution === 1 ? 'HD' : 'VGA');
            // this.currentDevice = {
            //     devId: this.devMap[ref],
            // };
            // await this.destroy(this.currentWindow);
            // this.drop(this.currentWindow);
        },

        fullscreen() {
            if (!this.currentPlayer) return;
            this.currentPlayer.fullscreen();
        },

        screenshot() {
            if (!this.currentPlayer) return;
            this.currentPlayer.screenshot();
        },

        startRecording() {
            if (!this.currentPlayer) return;
            let { currentPlayer, currentWindow } = this;

            currentPlayer.startRecording && currentPlayer.startRecording();
            this.recordInterval[currentWindow] = setInterval(() => {
                let info = currentPlayer.getRecordInfo();
                this.recording = Object.assign({}, this.recording, {
                    [currentWindow]: {
                        size: (info.size / 1024).toFixed(2) + 'MB',
                        time: new Date(info.time - 28800000).format('hh:mm:ss')
                    }
                });
            }, 500);
        },

        stopRecording() {
            if (!this.currentPlayer) return;
            this.currentPlayer.stopRecording && this.currentPlayer.stopRecording();
            this.recording[this.currentWindow] = false;
            clearInterval(this.recordInterval[this.currentWindow]);
        },

        zoom() {
            if (!this.currentPlayer) return;
            let ref = 'tumsPlayer' + this.currentWindow;

            this.isZoom = !this.isZoom;
            this.zoomState[ref] = this.isZoom;
            this.players[ref].toggleZoomState(+this.isZoom);
        },

        setVolume(val) {
            if (!this.currentPlayer) return;
            let ref = 'tumsPlayer' + this.currentWindow;
            this.volume = val;
            this.players[ref].setVolume(val);
        },

        getVolume() {
            let ref = 'tumsPlayer' + this.currentWindow;
            this.volume = this.players[ref].getVolume();
        },

        // devId作为能力集moduleSpec的key，必传
        getModuleSpec(devInfo = {
            parentId: '',
            channel: 0,
            devId: ''
        }) {
            const {
                parentId,
                channel,
                devId
            } = devInfo;
            if (this.moduleSpec[devId]) {
                return Promise.resolve(this.moduleSpec[devId]);
            }

            let param = {
                method: 'get',
                function: {
                    name: 'module_spec'
                }
            };

            const fetch = () => {
                if (channel > 0) {
                    return nvrChnPassthrough(parentId, channel, param);
                } else {
                    return passthrough({ devId, param });
                }
            };

            return fetch().then(res => {
                if (res.error_code) return res;
                let moduleSpec = res.function['module_spec'];
                this.moduleSpec[devId] = moduleSpec;
                return moduleSpec || {};
            });
        },

        getFishEyeCapability() {
            let { devId, parentId, channel } = this.currentDevice || {};
            if (this.fishEye[devId] !== undefined) {
                this.fishEyeEnable = !!this.fishEye[devId];
                return;
            }

            let param = {
                method: 'get',
                fish_eye: {
                    name: 'capability'
                }
            };
            const fetch = () => {
                if (channel > 0) {
                    return nvrChnPassthrough(parentId, channel, param);
                } else {
                    return passthrough({ devId, param });
                }
            };

            fetch().then(res => {
                if (res.error_code) {
                    this.fishEyeEnable = false;
                    this.fishEye[devId] = false;
                    return;
                }
                this.fishEyeEnable = true;
                let fishEye = res['fish_eye'] || {};
                this.fishEye[devId] = fishEye.capability;
                return fishEye.capability || {};
            });
        },

        setFishEyeMode(val) {
            this.currentPlayer && this.currentPlayer.setFishEyeDisplayMode(val);
        },
        // 云台控制
        handleShowPtzPanel() {
            this.$emit('hanle-show-ptzPanel', {
                show: true,
                currentPlayer: this.currentPlayer
            });
        },
        // 获取镜头遮蔽状态
        getLensMaskValue(wIndex) {
            let ref = 'tumsPlayer' + wIndex;
            if (!this.players[ref]) return;

            let devId = this.players[ref].devId;
            this.players[ref].getLensMaskValue()
                .then(res => {
                    this.$set(this.lensMaskValueMap, devId, res.lens_mask.lens_mask_info.enabled || 'off');
                })
                .catch(err => {
                    this.$set(this.lensMaskValueMap, devId, 'off');
                    console.error('getLensMaskValue error: ', err);
                });
        },
        // 设置镜头遮蔽状态
        changeLensMaskValue() {
            if (!this.currentPlayer) return;
            let closeFunc = TumsMessage.loading({
                duration: 0,
                content: '设置镜头遮蔽状态中'
            });

            let ref = 'tumsPlayer' + this.currentWindow;
            let devId = this.devMap[ref];

            let value = this.lensMaskValueMap[devId];
            let afterValue = value === 'off' ? 'on' : 'off';

            this.currentPlayer.setLensMaskValue(afterValue)
                .then(() => {
                    this.$set(this.lensMaskValueMap, devId, afterValue);
                    TumsMessage.success('镜头遮蔽状态设置成功');
                    closeFunc();
                })
                .catch(errData => {
                    TumsMessage.error('镜头遮蔽状态设置失败');
                    console.error('changeLensMaskValue error: ', errData);
                    closeFunc();
                });
        },
        // 获取imgaeSwitch数据(包括镜像类型和旋转类型)
        getImageSwitch(wIndex) {
            let ref = 'tumsPlayer' + wIndex;
            if (!this.players[ref]) return;
            return this.players[ref].getImageSwitch().then(res => {
                let devId = this.players[ref].devId;
                this.flipTypeValueMap[devId] = res.flip_type;
                if (this.rotateCapabilityMap[devId]) this.rotateValueMap[devId] = res.rotate_type;
            });
        },
        // 设置镜像类型
        setFlipType(val) {
            if (!this.currentPlayer) return;

            let closeFunc = TumsMessage.loading({
                duration: 0,
                content: '设置镜像中'
            });

            let devId = this.currentPlayer.devId;
            this.currentPlayer.saveImageSwitch({ flip_type: val }).then(() => {
                TumsMessage.success('镜像设置成功');
                this.flipTypeValueMap[devId] = val;
                this.flipType = val;
                closeFunc();
            }).catch(err => {
                if (err.error_code) {
                    TumsMessage.error(err.msg || err.error_code);
                } else {
                    throw err;
                }
                closeFunc();
            });
        },
        async getPlayerModuleSpec(player) {
            if (!player) return Promise.reject({});
            let devId = player.devId;
            await player.getModuleSpec().then(playerModuleSpec => {
                this.rotateCapabilityMap[devId] = playerModuleSpec.rotateEnable;
                this.lensMaskCapabilityMap[devId] = playerModuleSpec.lensMaskEnable;
            }).catch(errData => {
                console.error('getPlayerModuleSpec error: ', errData);
                this.rotateCapabilityMap[devId] = false;
                this.lensMaskCapabilityMap[devId] = false;
            });
        },
        // 设置画面旋转模式
        setRotateMode(val) {
            if (!this.currentPlayer) return;

            let closeFunc = TumsMessage.loading({
                duration: 0,
                content: '设置画面旋转中'
            });

            let devId = this.currentPlayer.devId;
            this.currentPlayer.saveImageSwitch({ rotate_type: val }).then(() => {
                TumsMessage.success('画面旋转设置成功');
                this.rotateValueMap[devId] = val;
                this.rotateMode = val;
                closeFunc();
            }).catch(err => {
                if (err.error_code) {
                    TumsMessage.error(err.msg || err.error_code);
                } else {
                    throw err;
                }
                closeFunc();
            });
        }
    },
};
</script>

<style>
.tums-player-content {
    height: calc(100% - 49px);
}

.tums-player-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #47b6ff;
}

.tums-player-close {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 10;
    font-size: 24px;
}

.tums-player {
    background: #d9d9d9;
}

.u-operation-content-popper {
    text-align: center;
}

.tums-player-recording {
    bottom: 24px;
    right: 24px;
    z-index: 10;
}
</style>
