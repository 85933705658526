export function getProjectList(platform, enterpriseId) {
    let data = sessionStorage.getItem('projectList') || '{}';
    data = JSON.parse(data);
    if (data && data[enterpriseId]) {
        return Promise.resolve(data[enterpriseId]);
    }

    let param = {};
    let api = '/tums/statistics/v1/getAllProjectInfo';

    if (platform === 'cloud') {
        api = '/tums/resource/v2/getAllProjectInfo';
        param = {
            start: 0,
            limit: 100,
            filterAnd: {
                enterpriseId
            }
        };
    }

    return tumsAxios.chainPostProxy(api, param).then(res => {
        let projectList;
        if (platform === 'cloud') {
            let { result = {} } = res;
            projectList = result.list || [];
        } else {
            projectList =  res.result || [];
        }

        if (!projectList.length) TumsMessage.text('无项目');
        data[enterpriseId] = projectList;
        sessionStorage.setItem('projectList', JSON.stringify(data));
        return projectList;
    });
}