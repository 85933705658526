class AudioPlayer {
    static get isSupported() {
        return !!(window.AudioContext || window.webkitAudioContext);
    }

    constructor(opts) {
        this.audioType = opts.audioType;
        this.samples = new Float32Array();
        // flushingTime pcm数据刷新间隔
        this.flushingTime = opts.flushingTime || 50;
        this.channels = opts.channels || 1;
        this.sampleRate = opts.sampleRate || 8000;
        this.flush = this.flush.bind(this);
        this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        this.gainNode = this.audioCtx.createGain();
        this.gainNode.gain.value = opts.volume ? (opts.volume / 100).toFixed(2) : 0;
        this.gainNode.connect(this.audioCtx.destination);
        // 表示用于调度的不断增加的硬件时间
        this.startTime = this.audioCtx.currentTime;
        this.interval = setInterval(this.flush, this.flushingTime);
    }

    static shortToFloatData(input) {
        let inputSamples = input.length;
        let output = new Float32Array(inputSamples);
        for (let i = 0; i != inputSamples; ++i) {
            // 16bitInt 32768 编码格式
            output[i] = input[i] / 32768;
        }
        return output;
    }

    play(pcmData) {
        let pcmFloat32data = AudioPlayer.shortToFloatData(pcmData);
        this.feed(pcmFloat32data);
    }

    setVolume(volume) {
        this.gainNode.gain.value = (volume / 100).toFixed(2);
    }

    getVolume() {
        return parseInt(this.gainNode.gain.value * 100);
    }

    pause() {
        if (this.audioCtx.state === 'running') {
            this.audioCtx.suspend();
        }
    }

    resume() {
        if (this.audioCtx.state === 'suspended') {
            this.audioCtx.resume();
        }
    }

    close() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.audioCtx.close();
        this.samples = null;
        this.audioCtx = null;
    }

    getTimestamp() {
        if (this.audioCtx) {
            return this.audioCtx.currentTime;
        } else {
            return 0;
        }
    }

    feed(data) {
        let tmp = new Float32Array(this.samples.length + data.length);
        tmp.set(this.samples, 0);
        tmp.set(data, this.samples.length);
        this.samples = tmp;
    }

    flush() {
        if (!this.channels || !this.sampleRate || !this.samples.length) {
            return;
        }

        let bufferSource = this.audioCtx.createBufferSource();
        let length = this.samples.length / this.channels;
        let audioBuffer = this.audioCtx.createBuffer(this.channels, length, this.sampleRate);
        for (let channel = 0; channel != this.channels; ++channel) {
            let audioData = audioBuffer.getChannelData(channel);
            let offset = channel;
            let decrement = 50;
            for (let i = 0; i != length; ++i) {
                audioData[i] = this.samples[offset];
                if (i < 50) {
                    audioData[i] = (audioData[i] * i) / 50;
                }
                if (i >= length - 51) {
                    audioData[i] = (audioData[i] * decrement--) / 50;
                }
                offset += this.channels;
            }
        }

        if (this.startTime < this.audioCtx.currentTime) {
            this.startTime = this.audioCtx.currentTime;
        }
        bufferSource.buffer = audioBuffer;
        bufferSource.connect(this.gainNode);
        bufferSource.start(this.startTime);
        // duration属性返回一个双精度数，表示缓冲区中存储的PCM数据的持续时间（以秒为单位）
        this.startTime += audioBuffer.duration;
        this.samples = new Float32Array();
    }

    createMediaStream() {
        let dest = this.audioCtx.createMediaStreamDestination();
        this.gainNode.connect(dest);
        return dest;
    }
}

export default AudioPlayer;
