/**
 * Parser for exponential Golomb codes, a variable-bitwidth number encoding scheme used by h264.
*/
export class ExpGolomb {
    constructor(data) {
        this.data = data;
        // the number of bytes left to examine in this.data
        this.bytesAvailable = this.data.byteLength;
        // the current word being examined
        this.word = 0; // :uint
        // the number of bits left to examine in the current word
        this.bitsAvailable = 0; // :uint
    }

    // ():void
    loadWord() {
        let position = this.data.byteLength - this.bytesAvailable;
        let workingBytes = new Uint8Array(4);
        let availableBytes = Math.min(4, this.bytesAvailable);
        if (availableBytes === 0) {
            throw new Error('no bytes available');
        }
        workingBytes.set(this.data.subarray(position, position + availableBytes));
        this.word = new DataView(workingBytes.buffer, workingBytes.byteOffset, workingBytes.byteLength).getUint32(0);
        // track the amount of this.data that has been processed
        this.bitsAvailable = availableBytes * 8;
        this.bytesAvailable -= availableBytes;
    }

    // (count:int):void
    skipBits(count) {
        let skipBytes; // :int
        if (this.bitsAvailable > count) {
            this.word <<= count;
            this.bitsAvailable -= count;
        } else {
            count -= this.bitsAvailable;
            skipBytes = count >> 3;
            count -= skipBytes << 3;
            this.bytesAvailable -= skipBytes;
            this.loadWord();
            this.word <<= count;
            this.bitsAvailable -= count;
        }
    }

    // (size:int):uint
    readBits(size) {
        let bits = Math.min(this.bitsAvailable, size); // :uint
        let valu = this.word >>> (32 - bits); // :uint
        if (size > 32) {
            Log$3.error('Cannot read more than 32 bits at a time');
        }
        this.bitsAvailable -= bits;
        if (this.bitsAvailable > 0) {
            this.word <<= bits;
        } else if (this.bytesAvailable > 0) {
            this.loadWord();
        }
        bits = size - bits;
        if (bits > 0) {
            return (valu << bits) | this.readBits(bits);
        } else {
            return valu;
        }
    }

    readByte() {
        return this.readBits(8);
    }

    // ():uint
    skipLZ() {
        let leadingZeroCount; // :uint
        for (leadingZeroCount = 0; leadingZeroCount < this.bitsAvailable; ++leadingZeroCount) {
            if (0 !== (this.word & (0x80000000 >>> leadingZeroCount))) {
                // the first bit of working word is 1
                this.word <<= leadingZeroCount;
                this.bitsAvailable -= leadingZeroCount;
                return leadingZeroCount;
            }
        }
        // we exhausted word and still have not found a 1
        this.loadWord();
        return leadingZeroCount + this.skipLZ();
    }

    // ():void
    skipUEG() {
        this.skipBits(1 + this.skipLZ());
    }

    // ():void
    skipEG() {
        this.skipBits(1 + this.skipLZ());
    }

    // ():uint
    readUEG() {
        let clz = this.skipLZ(); // :uint
        return this.readBits(clz + 1) - 1;
    }

    // ():int
    readEG() {
        let valu = this.readUEG(); // :int
        if (0x01 & valu) {
            // the number is odd if the low order bit is set
            return (1 + valu) >>> 1; // add 1 to make it even, and divide by 2
        } else {
            return -1 * (valu >>> 1); // divide by two then make it negative
        }
    }

    // Some convenience functions
    // :Boolean
    readBoolean() {
        return 1 === this.readBits(1);
    }

    // ():int
    readUByte() {
        return this.readBits(8);
    }

    // ():int
    readUShort() {
        return this.readBits(16);
    }
    // ():int
    readUInt() {
        return this.readBits(32);
    }

    destroy() {
        // none
    }
}