var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tums-scrollbar',{ref:"scrollbar",staticClass:"tume-player-time__panel"},[_c('div',{staticClass:"tume-player-time__wrap",style:({ width: _vm.width + '%'}),on:{"mouseenter":function($event){_vm.inPanel = true},"mouseleave":function($event){_vm.inPanel = false}}},[_c('div',{staticClass:"tume-player-time__tab tums-h-40 a-d-f a-ai-c a-pos-r"},_vm._l((_vm.timeTabs),function(item){return _c('div',{key:item.label,staticClass:"a-pos-a",staticStyle:{"width":"58px","text-align":"center"},style:({
                    left: ("calc(" + (item.offset) + "% - 29px)")
                })},[_c('div',{staticClass:"a-font4"},[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"divide-line"})])}),0),_c('div',{ref:"timeLine",staticClass:"tume-player-time__line tums-h-32 a-pos-r",on:{"mouseover":function($event){_vm.showHoverTime = true},"mouseout":function($event){_vm.showHoverTime = false},"mousemove":_vm.handleHoverTimeline,"click":_vm.seek}},[_vm._l((_vm.timePeriods),function(item,i){return _c('div',{key:i,staticClass:"a-pos-a",class:item.videoType,style:({
                    width: item.width + '%',
                    height: '8px',
                    top: '8px',
                    left: item.left + '%'
                })})}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoList.length),expression:"videoList.length"}],staticClass:"tume-player-time__current a-pos-a",style:({ left: _vm.currentTimeInfo.left + '%'})},[_c('div',{staticClass:"tume-player-time__label"},[_vm._v(_vm._s(_vm.currentTimeInfo.label))]),_c('div',{staticClass:"tume-player-time__pointer"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHoverTime),expression:"showHoverTime"}],staticClass:"tume-player-time__hover a-pos-a",style:({ left: _vm.hoverTimeInfo.left + '%'})},[_c('div',{staticClass:"tume-player-time__label"},[_vm._v(_vm._s(_vm.hoverTimeInfo.label))]),_c('div',{staticClass:"tume-player-time__pointer"})])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }