<template>
    <div class="a-h-full">
        <mobile-preview v-if="isMobile" :enterprise-list="enterpriseList" :config="config"></mobile-preview>
        <div v-else class="a-w-full a-h-full a-d-f">
            <div class="a-h-full tums-w-240 a-bg-grey-bg">
                <tums-scrollbar>
                    <div class="tums-player-aside a-p-lg">
                        <tums-form label-width="56px" class="a-m-b-sm">
                            <tums-form-item label="模式">
                                <tums-select v-model="mode">
                                    <tums-option label="自动发现" value="auto"></tums-option>
                                    <tums-option label="手动输入" value="manual"></tums-option>
                                </tums-select>
                            </tums-form-item>
                        </tums-form>

                        <tums-form v-show="mode === 'auto'" label-width="56px">
                            <tums-form-item label="布局" v-if="streamType === 'video'">
                                <tums-select class="a-w-full" v-model="layout">
                                    <tums-option v-for="item in layouts" :key="item" :label="item" :value="item"></tums-option>
                                </tums-select>
                            </tums-form-item>
                            <tums-form-item label="播放">
                                <tums-select class="a-w-full" v-model="autoplay">
                                    <tums-option label="自动" :value="true"></tums-option>
                                    <tums-option label="手动" :value="false"></tums-option>
                                </tums-select>
                            </tums-form-item>
                            <tums-form-item label="解码">
                                <tums-select class="a-w-full" v-model="decoderType">
                                    <tums-option label="软解" value="wasm"></tums-option>
                                    <tums-option label="硬解" value="webcodecs"></tums-option>
                                </tums-select>
                            </tums-form-item>
                            <tums-form-item label="企业" v-if="isCloud">
                                <tums-select class="a-w-full" v-if="isCloud" v-model="enterpriseId">
                                    <tums-option v-for="item in enterpriseList" :key="item.enterpriseId" :label="item.enterpriseName" :value="item.enterpriseId"></tums-option>
                                </tums-select>
                            </tums-form-item>
                            <tums-form-item label="项目">
                                <tums-select class="a-w-full" v-model="projectId">
                                    <tums-option v-for="item in projectList" :key="item.projectId" :label="item.projectName" :value="item.projectId"></tums-option>
                                </tums-select>
                            </tums-form-item>
                            <tums-form-item label="分组">
                                <tums-select class="a-w-full" v-model="regionId">
                                    <tums-option v-for="item in regionList" :key="item.regionId" :label="item.regionName" :value="item.regionId"></tums-option>
                                </tums-select>
                            </tums-form-item>
                        </tums-form>

                        <div class="device-list a-font4 a-c-p a-m-t-sm" v-show="mode === 'auto' && streamType === 'video'">
                            <div
                                class="device-item a-ellipsis a-d-f a-jc-sb"
                                v-for="item in deviceList"
                                :key="item.devId"
                                draggable
                                @dragstart="dragDevice(item)"
                                @click="clickDevice(item)"
                            >
                                <p><i class="tums-icon-ipc a-m-r-xxs"></i>{{ item.deviceName || item.devName }}</p>
                                <span v-if="!item.deviceStatus" class="a-m-l-xxs a-c-error">离线</span>
                            </div>
                        </div>

                        <tums-radio-group v-model="currentDevice" class="device-list a-m-t-sm" v-show="mode === 'auto' && streamType === 'sdvod'">
                            <tums-radio class="a-p-l-ml" v-for="item in deviceList" :key="item.devId" :label="item">
                                <span>{{ item.deviceName || item.devName }}</span>
                                <span v-if="!item.deviceStatus" class="a-m-l-xxs a-c-error">离线</span>
                            </tums-radio>
                        </tums-radio-group>

                        <tums-form v-show="mode === 'manual'" label-width="56px">
                            <tums-form-item label="type">
                                <tums-select v-model="playerOption.type">
                                    <tums-option label="relay" value="relay"></tums-option>
                                    <tums-option label="rtsp" value="rtsp"></tums-option>
                                </tums-select>
                            </tums-form-item>
                            <tums-form-item label="url">
                                <tums-input v-model="playerOption.url"></tums-input>
                            </tums-form-item>
                            <tums-form-item label="socket">
                                <tums-input v-model="playerOption.socket"></tums-input>
                            </tums-form-item>
                            <tums-form-item label="pluginPath">
                                <tums-input v-model="playerOption.pluginPath"></tums-input>
                            </tums-form-item>
                            <tums-form-item label="resolution">
                                <tums-select v-model="playerOption.resolution">
                                    <tums-option label="VGA" value="VGA"></tums-option>
                                    <tums-option label="HD" value="HD"></tums-option>
                                </tums-select>
                            </tums-form-item>
                            <tums-form-item label="autoplay">
                                <tums-switch v-model="playerOption.autoplay"></tums-switch>
                            </tums-form-item>
                            <tums-form-item label="cover">
                                <tums-switch v-model="playerOption.cover"></tums-switch>
                            </tums-form-item>
                            <tums-form-item>
                                <tums-button type="primary" width="140" @click="manualPlay">确定</tums-button>
                            </tums-form-item>
                        </tums-form>
                    </div>
                </tums-scrollbar>
            </div>
            <div class="a-h-full tums-w-minus-480">
                <div class="tums-h-64 a-p-h-lg a-p-v-ml a-d-f a-ai-c">
                    <tums-button-group>
                        <tums-button width="120" :type="streamType === 'video' ? 'primary' : ''" @click="streamType = 'video'">预览</tums-button>
                        <tums-button width="120" :type="streamType === 'sdvod' ? 'primary' : ''" @click="streamType = 'sdvod'">回放</tums-button>
                    </tums-button-group>
                    <div v-if="streamType === 'sdvod'">
                        <tums-select class="a-m-l-ml" v-if="isCloud" v-model="storageType">
                            <tums-option label="设备存储" value="relay"></tums-option>
                            <!-- <tums-option label="云存储" value="cloudStorage"></tums-option> -->
                        </tums-select>
                        <tums-date-picker
                            ref="datePicker"
                            class="a-m-l-ml a-d-ib tums-w-240"
                            v-model="playbackTime"
                            :events="events"
                            @visible-change="handleDateVisible"
                            @change="handleChangeDate"
                        ></tums-date-picker>
                        <tums-button class="a-m-l-ml" type="primary" width="88" :disabled="!currentDevice.devId" @click="searchVideo">查询</tums-button>
                    </div>
                </div>
                <preview
                    v-if="streamType === 'video'"
                    ref="preview"
                    :config="config"
                    :current-device="currentDevice"
                    :autoplay="autoplay"
                    :layout="layout"
                    :decoder-type="decoderType"
                    @event-log="handleEventLog"
                    :audioVoiceEnable="audioVoiceEnable"
                    @hanle-show-ptzPanel="handleShowPtzPanel"
                ></preview>
                <playback
                    v-if="streamType === 'sdvod'"
                    ref="playback"
                    :config="config"
                    :current-device="currentDevice"
                    :autoplay="autoplay"
                    :playback-time="playbackTime"
                    :storage-type="storageType"
                    :decoder-type="decoderType"
                    @event-log="handleEventLog"
                    :audioVoiceEnable="audioVoiceEnable"
                ></playback>
            </div>
            <div class="a-h-full tums-w-240 a-bg-grey-bg">
                <tums-scrollbar v-if="!showPtz">
                    <div class="a-p-xs a-font4 a-d-f a-jc-sb" style="font-weight: 700">
                        <p>事件日志</p>
                        <tums-button type="text" @click="logs = []"> 清空 </tums-button>
                    </div>
                    <div v-for="(item, i) in logs" :key="i" class="a-p-xs a-font4">
                        <p>{{ new Date(item.time).format('hh:mm:ss') + ':' }}</p>
                        <p>{{ item.log }}</p>
                    </div>
                </tums-scrollbar>
                <ptz-panel
                    :showPanel="showPtz"
                    :currentPlayer="currentPlayer"
                    :authType="authType"
                    :id="currentDevice.devId"
                    @close-ptz-panel="closePtzPanel"
                    :config="config"
                ></ptz-panel>
            </div>
        </div>
    </div>
</template>

<script>
import { login } from './api/login';
import { getEnterpriseList } from './api/enterprise';
import { getProjectList } from './api/project';
import { getRegionList } from './api/region';
import { getDeviceList } from './api/device';
import { queryUserMenuList } from './api/userMenuList';
import { searchYear, getEventCalendar } from './api/playback';
import Preview from './components/Preview';
import Playback from './components/Playback';
import { getAuthorization } from './utils/auth';
import { randomStr } from './utils/util';
import PtzPanel from './components/ptz/ptzPanel.vue';
import MobilePreview from './components/MobilePreview';

const isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);
// const isMobile = true;

export default {
    name: 'App',
    components: {
        Preview,
        Playback,
        PtzPanel,
        MobilePreview
    },
    data() {
        return {
            isMobile,
            config: {
                platform: ''
            },
            enterpriseId: '',
            enterpriseList: [],
            projectId: '',
            projectList: [],
            regionId: '',
            regionList: [],
            currentDevice: {},
            deviceList: [],
            layout: '1 x 1',
            layouts: ['1 x 1', '1 x 2', '2 x 1', '2 x 2', '3 x 2', '4 x 2', '4 x 3', '4 x 4'],
            players: {},
            autoplay: true,
            decoderType: 'wasm',
            loading: {},
            logs: [],
            mode: 'auto',
            streamType: 'video',
            playerOption: {
                type: 'relay',
                url: '',
                socket: '',
                pluginPath: '',
                autoplay: true,
                resolution: 'HD',
                cover: true
            },
            playbackTime: new Date(),
            storageType: 'relay',
            events: {
                year: new Date().getFullYear(),
                days: []
            },
            getRegionDatabyProjectId: new Map(),
            deviceListDataByRegionId: new Map(),
            audioVoiceEnable: true,
            // ptz
            showPtz: false,
            currentPlayer: null,
            authType: ''
        };
    },
    computed: {
        isCloud() {
            return this.config.platform === 'cloud';
        }
    },
    watch: {
        async enterpriseId() {
            if (isMobile) return;

            await this.getProjectList();
            if (!this.projectId) return;

            await this.getRegionList();
            if (!this.regionId) return;

            this.getDeviceList();
        },
        async projectId(val) {
            this.deviceList = [];
            if (this.getRegionDatabyProjectId.get(val) === undefined) {
                await this.getRegionList();
                this.getRegionDatabyProjectId.set(val, this.regionList);
                if (!this.regionId) return;
                this.getDeviceList();
            } else {
                this.regionList = this.getRegionDatabyProjectId.get(val);
                if (this.regionList.length) {
                    this.regionId = this.regionList[0].regionId;
                } else {
                    this.regionId = '';
                }
            }
        },

        async regionId(val) {
            this.deviceList = [];
            if (this.regionId) {
                this.getDeviceList();
            }
        },

        mode(val) {
            this.layout = '1 x 1';
            if (this.streamType === 'video') {
                this.$refs.preview.destroy();
            }
            if (val === 'manual') {
                let options = localStorage.getItem('playerOptions');
                if (options) {
                    this.playerOption = JSON.parse(options);
                }
            }
        },

        streamType() {
            this.getRegionList();
        },

        decoderType(val) {
            localStorage.setItem('decoderType', val);
        }
    },
    async mounted() {
        let config = await fetch('./config.json').then((response) => response.json());
        this.config = config;
        let { authType, appKey, appSecret } = config;
        this.authType = authType;
        let terminalId = randomStr(16);

        this.decoderType = localStorage.getItem('decoderType') || 'wasm';

        tumsAxios.postProxy = (uri, payload, successCb, errorCb, config = {}) => {
            if (authType === 'aksk') {
                config = Object.assign(
                    {
                        headers: {
                            'X-Authorization': getAuthorization({
                                appKey,
                                appSecret,
                                terminalId,
                                uri,
                                payload: JSON.stringify(payload || {})
                            })
                        }
                    },
                    config
                );
            }
            return tumsAxios.post(uri, payload, successCb, errorCb, config);
        };
        tumsAxios.chainPostProxy = (uri, payload, errorCb, config = {}) => {
            if (authType === 'aksk') {
                config = Object.assign(
                    {
                        headers: {
                            'X-Authorization': getAuthorization({
                                appKey,
                                appSecret,
                                terminalId,
                                uri,
                                payload: JSON.stringify(payload || {})
                            })
                        }
                    },
                    config
                );
            }
            return tumsAxios.chainPost(uri, payload, errorCb, config);
        };

        if (authType !== 'aksk') {
            await login(config);
        }
        if (this.isCloud) {
            this.getEnterpriseList();
        } else {
            this.getProjectList();
        }
    },
    methods: {
        async getEnterpriseList() {
            this.enterpriseList = await getEnterpriseList();
            if (this.enterpriseList.length) {
                this.enterpriseId = this.enterpriseList[0].enterpriseId;
            }
        },

        async getProjectList() {
            this.projectList = await getProjectList(this.config.platform, this.enterpriseId);
            if (this.projectList.length) {
                this.projectId = this.projectList[0].projectId;
            }
            queryUserMenuList(this.enterpriseId, this.config.platform).then((menuList) => {
                this.audioVoiceEnable = !!menuList.find((item) => item.menuUrl === 'audioVoice');
            });
        },

        async getRegionList() {
            this.regionList = [];
            await getRegionList(this.regionList, this.projectId, this.streamType === 'video' ? 0 : 1, this.config.platform);
            if (this.regionList.length) {
                this.regionId = this.regionList[0].regionId;
            } else {
                this.regionId = '';
            }
        },

        async getDeviceList() {
            this.currentDevice = {};
            if (this.deviceListDataByRegionId.get(this.regionId) === undefined) {
                this.deviceList = await getDeviceList(this.regionId);
                this.deviceListDataByRegionId.set(this.regionId, this.deviceList);
            } else {
                this.currentDevice = {};
                this.deviceList = this.deviceListDataByRegionId.get(this.regionId);
            }
        },

        dragDevice(device) {
            this.currentDevice = device;
        },

        clickDevice(device) {
            if (this.streamType === 'video') {
                this.currentDevice = device;
                this.$nextTick(() => {
                    this.$refs.preview.drop(1);
                });
            }
        },

        manualPlay() {
            if (this.streamType === 'video') {
                this.$refs.preview.manualPlay(this.playerOption);
                localStorage.setItem('playerOptions', JSON.stringify(this.playerOption));
            }
        },

        handleEventLog(log) {
            this.logs.unshift({
                time: new Date().getTime(),
                log
            });
        },

        handleDateVisible(val) {
            if (val) {
                if (this.storageType === 'relay') this.searchYear();
                else this.getEventCalendar();
            }
        },

        handleChangeDate(data) {
            if (this.storageType === 'cloudStorage') {
                this.getEventCalendar(data);
            }
        },

        searchYear() {
            let { devId } = this.currentDevice;
            if (!devId) return;

            searchYear(devId, new Date().getFullYear()).then((res) => {
                this.events = res;
            });
        },

        getEventCalendar(data) {
            let { devId } = this.currentDevice;
            if (!devId) return;

            let date = new Date();
            if (data && data.date) {
                date = new Date(Date.parse(`${data.date.year}/${data.date.month + 1}/${data.date.date || 1}`));
            }
            let startDate = new Date(Date.parse(date.format('yyyy/MM/') + '01'));
            let endDate = new Date(Date.parse(`${startDate.getFullYear()}/${startDate.getMonth() + 2}/1`));

            getEventCalendar(devId, startDate.format('yyyyMMdd'), endDate.format('yyyyMMdd')).then((res) => {
                this.events = {
                    year: date.getFullYear(),
                    days: res.map((item) => item.slice(4))
                };
            });
        },

        searchVideo() {
            let date = new Date(this.playbackTime.toLocaleDateString());
            this.$refs.playback.searchVideo(date);
        },
        // 云台功能
        handleShowPtzPanel(val) {
            this.showPtz = val.show;
            this.currentPlayer = val.currentPlayer;
        },
        closePtzPanel() {
            this.showPtz = false;
        }
    }
};
</script>

<style>
.tums-player-aside .tums-select,
.tums-player-aside .tums-input {
    width: 140px;
}

.device-item {
    padding: 0 12px;
    line-height: 32px;
}

.device-item:hover {
    background: #ccc;
}

.device-item.is-active {
    color: #47b6ff;
}

.device-item .tums-button {
    opacity: 0;
}

.device-item:hover .tums-button {
    opacity: 1;
}

.device-list .tums-radio {
    line-height: 32px;
}

.tums-radio + .tums-radio {
    margin-left: 0;
}

.tums-player-preview,
.tums-player-playback {
    height: calc(100% - 64px);
}

.tums-player-content .tums-icon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    color: #47b6ff;
}
</style>
