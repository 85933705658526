const URI = '/tums';
const VERSION1 = 'v1';
const VERSION2 = 'v2';
const MODEL_NAME = 'ptz';

const makeUrl = (method, model = MODEL_NAME, v = VERSION) => {
    return `${URI}/${model}/${v}/${method}`;
};

const METHOD = {
    setParkConfig: 'setParkConfig',
    addPresetPoint: 'addPresetPoint',
    motionCtrl: 'motionCtrl',
    getAllTours: 'getAllTours',
    getToursInfo: 'getToursInfo',
    getAllPresets: 'getAllPresets',
    operatePreset: 'operatePreset',
    addCruisePath: 'addCruisePath',
    modifyTour: 'modifyTour',
    deleteTour: 'deleteTour'
};

const PTZ_CONFIG = {
    baseUrl: URI,
    model: MODEL_NAME,
    version: VERSION1,
    method: METHOD,
    makeUrl: makeUrl,
    url: {
        modifyTour: makeUrl(METHOD.modifyTour, MODEL_NAME, VERSION1),
        setParkConfig: makeUrl(METHOD.setParkConfig, MODEL_NAME, VERSION1),
        addPresetPoint: makeUrl(METHOD.addPresetPoint, MODEL_NAME, VERSION1),
        motionCtrl: makeUrl(METHOD.motionCtrl, MODEL_NAME, VERSION1),
        getAllTours: makeUrl(METHOD.getAllTours, MODEL_NAME, VERSION1),
        getToursInfo: makeUrl(METHOD.getToursInfo, MODEL_NAME, VERSION1),
        addCruisePath: makeUrl(METHOD.addCruisePath, MODEL_NAME, VERSION1),
        operatePreset: makeUrl(METHOD.operatePreset, MODEL_NAME, VERSION1),
        deleteTour: makeUrl(METHOD.deleteTour, MODEL_NAME, VERSION1),
        getAllPresets: makeUrl(METHOD.getToursInfo, MODEL_NAME, VERSION2)
    },
    key: {
      
    },
    value: {
        cruiseStatus: {
            idle: 'idle',
            touring: 'touring',
            paused: 'paused'
        },
        locusStatus: {
            idle: 'idle',
            scanning: 'scanning',
            recording: 'recording'
        },
        directions: {
            topLeft: 0,
            top: 1,
            topRight: 2,
            left: 3,
            horizontal: 4,
            right: 5,
            bottomLeft: 6,
            bottom: 7,
            bottomRight: 8,
            zoomOut: 9,
            zoomIn: 10,
            focusNear: 11,
            focusFar: 12
        },
        motion: {
            start: 1,
            stop: 0
        },
        operatePreset: {
            execute: 0,
            delete: 1,
            add: 2,
            modify: 3
        },
        operateCruise: {
            start: 'start',
            stop: 'stop',
            pause: 'pause'
        },
        operateLocus: {
            start: 'start',
            stop: 'stop',
            startRecord: 'start_record',
            stopRecord: 'stop_record'
        }
    }
};
export default PTZ_CONFIG;
