import localForage from 'localforage';
import { windowStorage } from '@/utils/util';
import { getTagged } from '@/utils/logger';

const Log = getTagged('load worker');

const jsArr = ['decoder.worker.js', 'libaudio.js', 'libs.worker.js', 'libffmpeg.js', 'webgl.js', 'webgl.worker.js'];
const wasmArr = ['audio.wasm', 'libaudio.wasm', 'libffmpeg.wasm'];

/**
 * fetch file and save it in the indexDB
 * @param {string} path
 * @param {string} name
 * @param {string} [type='blob']
 * @return {Promise}
 */
function loadFile(path, name, type = 'blob') {
    return fetch(path)
        .then((response) => {
            if (!response['ok']) {
                throw "failed to load wasm binary file at '" + path + "'";
            }
            localForage.setItem(name, response[type]());
        });
}

/**
 * Load all worker resources
 * @export
 * @param {string} path
 * @return {Promise}
 */
export async function loadWorkers(path) {
    let version = localStorage.getItem('__TUMS_PLAYER_VERSION__');
    let oldVersion = await localForage.getItem('__TUMS_PLAYER_VERSION_IDB__');

    if (version === oldVersion && process.env.NODE_ENV !== 'development') return Promise.resolve();

    let loadJsFiles = jsArr.map(name => loadFile(path + '/tums-player/' + name, name));
    let loadWasmFiles = wasmArr.map(name => loadFile(path + '/tums-player/' + name, name, 'arrayBuffer'));
    return Promise.all([...loadJsFiles, ...loadWasmFiles]).then(() => {
        localForage.setItem('__TUMS_PLAYER_VERSION_IDB__', version);
    });
}

export async function getAudioLib() {
    let libsWorker, libaudio, libaudioWasm;

    libsWorker = windowStorage.getItem('libs.worker.js');
    if (libsWorker) {
        libaudio = windowStorage.getItem('libaudio.js');
    } else {
        libsWorker = URL.createObjectURL(await localForage.getItem('libs.worker.js'));
        libaudio = URL.createObjectURL(await localForage.getItem('libaudio.js'));
        windowStorage.setItem('libs.worker.js', libsWorker);
        windowStorage.setItem('libaudio.js', libaudio);
    }
    libaudioWasm = await localForage.getItem('libaudio.wasm');

    return { libsWorker, libaudio, libaudioWasm };
}

export async function getVideoLib(decoderType = 'wasm') {
    let decoderWorker, libffmpeg, libffmpegWasm;

    decoderWorker = windowStorage.getItem('decoder.worker.js');
    if (!decoderWorker) {
        decoderWorker = URL.createObjectURL(await localForage.getItem('decoder.worker.js'));
        windowStorage.setItem('decoder.worker.js', decoderWorker);
    }

    if (decoderType === 'wasm') {
        libffmpeg = windowStorage.getItem('libffmpeg.js');
        if (!libffmpeg) {
            libffmpeg = URL.createObjectURL(await localForage.getItem('libffmpeg.js'));
            windowStorage.setItem('libffmpeg.js', libffmpeg);
        }

        libffmpegWasm = await localForage.getItem('libffmpeg.wasm');
    }

    return { decoderWorker, libffmpeg, libffmpegWasm };
}

export async function getWebglLib() {
    let webglWorker, webgljs;
    webglWorker = windowStorage.getItem('webgl.worker.js');
    if (webglWorker) {
        webgljs = windowStorage.getItem('webgl.js');
    } else {
        webglWorker = URL.createObjectURL(await localForage.getItem('webgl.worker.js'));
        webgljs = URL.createObjectURL(await localForage.getItem('webgl.js'));
        windowStorage.setItem('webgl.worker.js', webglWorker);
        windowStorage.setItem('webgl.js', webgljs);
    }
    return { webglWorker, webgljs };
}