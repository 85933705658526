export default class DevCapability {
    constructor(requestor, options = {
        parentId,
        channel,
        devId
    }) {
        this.requestor = requestor;
        this.parentId = options.parentId || '';
        this.channel = options.channel || 0;
        this.devId = options.devId || '';

        this.init();
    }

    init() {
        this.lensMaskEnable = false; // 镜头遮蔽
        this.flipEnable = true; // 画面镜像
        this.rotateEnable = false; // 画面旋转

        this.moduleSpec = null;
    }

    getModuleSpec() {
        if (this.moduleSpec) {
            return Promise.resolve({
                lensMaskEnable: this.lensMaskEnable,
                flipEnable: this.flipEnable,
                rotateEnable: this.rotateEnable
            });
        }
        const content = {
            method: 'get',
            function: {
                name: 'module_spec'
            }
        };
        let promise = null;
        if (this.channel > 0) {
            promise = this.requestor.nvrChnPassthrough(this.parentId, this.channel, content);
        } else {
            promise = this.requestor.passthrough({ devId: this.devId, param: content });
        }
        return promise.then(data => {
            let functionData = data.function || {};
            let moduleSpec = functionData.module_spec || {};

            this.moduleSpec = moduleSpec;

            this.lensMaskEnable = moduleSpec.lens_mask === '1';
            this.rotateEnable = moduleSpec.corridor_mode === '1';

            return {
                lensMaskEnable: this.lensMaskEnable,
                flipEnable: this.flipEnable,
                rotateEnable: this.rotateEnable
            };
        });
    }
}
