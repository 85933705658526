 <template>
    <tums-dialog
        :title="title"
        width="960px"
        v-model="showAddConfigCruisePath"
        @close="cancel"
    >
        <template slot="footer">
            <tums-button @click="cancel">取消</tums-button>
            <tums-button type="primary" @click="saveData">保存</tums-button>
        </template>
        <tums-form
            class="a-p-b-lg a-d-f a-ai-c a-jc-c"
            :model="currentRouteData"
            ref="currentRouteData"
            :rules="currentRouteDataRules"
        >
            <tums-form-item label="名称" class="a-d-f a-ai-c a-jc-fs" prop="tourName">
                <tums-input class="tums-w-280" maxLength="32" v-model="currentRouteData.tourName"></tums-input>
            </tums-form-item>
        </tums-form>
        <div class="a-border-darker tums-h-440 a-d-f a-jc-fs">
            <div style="width: 303px" class="a-br-darker a-h-full">
                <p class="u-font6-b a-p-t-lg a-p-h-lg a-p-b-md">预置点列表</p>
                <ul class="tums-h-380">
                <tums-scrollbar
                >
                    <li
                        v-for="(item, index) in presetsData"
                        :key="'preset' + index"
                        class="a-d-f a-jc-sb a-ai-c tums-h-48 a-p-v-sm a-p-h-lg tums-w-full"
                    >
                        <p class="a-d-f a-jc-fs a-ai-c tums-w-228">
                            <img :src="item.imgSrc" class="tums-w-56 tums-h-32">
                            <span class="tums-w-minus-64 a-m-l-xs f-ib f-toe">{{item.name}}</span>
                        </p>
                        <tums-button type="graytext" @click="addRoutePreset(item)">
                            <i class="tums-icon-plus"></i>
                        </tums-button>
                    </li>
                </tums-scrollbar>
                </ul>
            </div>
            <div class="a-p-lg" style="width: 607px">
                <p class="a-m-b-lg">
                    <span class="u-font6-b">巡航路径节点</span>
                    <span class="u-font7">(从左边的预置点列表中选择预置点，作为巡航路径节点)</span>
                </p>
                <ul>
                    <li style="background-color: #f6f6f6; padding-top:11px;padding-bottom: 11px" class="tums-h-40">
                        <span class="tums-w-56 f-ib a-p-l-xs">序号</span>
                        <span class="tums-w-280 f-ib a-p-l-xs">预置点</span>
                        <span class="tums-w-120 f-ib a-p-l-xs">停留（秒）</span>
                        <span style="width: 103px" class="f-ib a-p-l-xs">操作</span>
                    </li>
                    <div style="height:284px">
                        <tums-scrollbar>
                            <draggable 
                                v-model="currentRoutePaths"
                                animation="300"
                                chosen-class="a-shadow-base"
                                handle=".mover"
                            >
                                <transition-group>
                                    <li
                                        v-for="(pathItem, pathIndex) in currentRoutePaths"
                                        :key="'path'+ pathIndex"
                                        class="a-d-f a-ai-c a-jc-fs tums-h-48 a-bb-darker"
                                    >
                                        <span  class="tums-w-56 f-ib a-p-l-xs">{{pathIndex + 1}}</span>
                                        <p class="tums-w-280 a-p-l-xs a-d-f a-ai-c a-jc-fs">
                                            <img :src="pathItem.imgSrc" class="tums-w-56 tums-h-32">
                                            <span class="tums-w-minus-64 a-m-l-xs f-ib f-toe">{{pathItem.name}}</span>
                                        </p>
                                        <p class="tums-w-120 a-p-l-xs">
                                            <tums-input 
                                                v-model="pathItem.time"
                                                size="mini"
                                                :placeholder="`${minTime} - ${maxTime}`"
                                            ></tums-input>
                                        </p>
                                        <div style="width: 103px" class="a-p-l-xs a-p-r-sm a-d-f a-ai-c a-jc-sb">
                                            <tums-button type="text" @click="deletePath(pathIndex)">
                                                删除
                                            </tums-button>
                                            <tums-button type="graytext" class="mover">
                                                <i class="tums-icon-menu"></i>
                                            </tums-button>
                                        </div>
                                    </li>
                                </transition-group>
                            </draggable>
                        </tums-scrollbar>
                    </div>
                </ul>
            </div>
        </div>
    </tums-dialog>
</template>

<script>
import draggable from 'vuedraggable';
const {
    Utils
} = window['tums-components'].default;
export default {
    props: {
        showCruiseRoute: Boolean,
        presets: Array,
        cruiseRouteType: String,
        cruiseRouteData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        id: [String, Number],
        maxTourNumber: [String, Number],
        addTourId: [String, Number],
        tourStayTimeRange: Array
    },
    components: {
        draggable
    },
    data() {
        return {
            showAddConfigCruisePath: false,
            presetsData: [],
            currentRouteData: {
                tourName: ''
            },
            currentRoutePaths: [],
            maxTime: '',
            minTime: '',
            currentRouteDataRules: {
                tourName: [
                    {
                        message: '名称不能为空',
                        trigger: ['blur', 'change'],
                        required: true
                    }
                ]
            }
        };
    },
    watch: {
        showAddConfigCruisePath(val) {
            this.$emit('change-show', val);
        },
        showCruiseRoute(val) {
            if (val) {
                this.showAddConfigCruisePath = val;
                this.presetsData = this.presets.map(({ presetId, name, time, imgSrc }) => {
                    return {
                        presetId: presetId,
                        name: name,
                        time: time,
                        imgSrc: imgSrc
                    };
                });
                if (this.cruiseRouteType === 'edit') {
                    // 编辑数据
                    this.currentRoutePaths = [];
                    this.currentRouteData = Object.assign({}, this.cruiseRouteData);
                    this.currentRoutePaths = this.currentRouteData.paths;
                    this.currentRoutePaths.forEach(item => {
                        this.presetsData.forEach(preItem => {
                            if (preItem.presetId === item.presetId) {
                                item.name = preItem.name;
                                item.imgSrc = preItem.imgSrc;
                            }
                        });
                    });
                } else {
                    this.currentRouteData.tourName = `巡航路径${this.addTourId}`;
                    this.currentRoutePaths = [];
                }
            }
        },
        tourStayTimeRange(val) {
            if (val.length) {
                this.maxTime = val[1];
                this.minTime = val[0];
            }
        }
    },
    computed: {
        title() {
            return this.cruiseRouteType === 'edit' ? '编辑巡航路径' : '添加巡航路径';
        }
    },
    methods: {
        addRoutePreset(presetDataItem) {
            if (this.currentRoutePaths && this.currentRoutePaths.length < this.maxTourNumber) {
                this.currentRoutePaths.push({
                    name: presetDataItem.name,
                    presetId: presetDataItem.presetId,
                    time: '',
                    imgSrc: presetDataItem.imgSrc

                });
                this.$forceUpdate();
            } else {
                let infoMessage = `最多可添加${this.maxTourNumber}条路径`;
                TumsMessage.info(infoMessage);
            }
        },
        // 取消
        cancel() {
            this.showAddConfigCruisePath = false;
        },
        // 保存修改
        saveData() {
            this.$refs.currentRouteData.validate(valid=> {
                if (valid) {
                    if (this.cruiseRouteType === 'add') {
                        tumsAxios.post('/tums/ptz/v1/addCruisePath', {
                            id: this.id,
                            tourName: this.currentRouteData.tourName,
                            tourId: this.addTourId,
                            presetIds: this.currentRoutePaths.map(item => item.presetId),
                            times: this.currentRoutePaths.map(item => item.time * 1000)
                        }, () => {
                            this.showAddConfigCruisePath = false;
                            this.$emit('refresh-cruise-path');
                        });
                    } else {
                        tumsAxios.post('/tums/ptz/v1/modifyTour', {
                            id: this.id,
                            tourId: this.currentRouteData.tourId,
                            tourName: this.currentRouteData.tourName,
                            presetIds: this.currentRoutePaths.map(item => item.presetId),
                            times: this.currentRoutePaths.map(item => item.time * 1000)
                        }, () => {
                            this.showAddConfigCruisePath = false;
                            this.$emit('refresh-cruise-path');
                        });
                    }
                }
            });
        },
        // 删除
        deletePath(index) {
            this.currentRoutePaths.splice(index, 1);
            this.$forceUpdate();
        }
    }
};
</script>


