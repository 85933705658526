function getRootRegions(projectId, permissionType = 0, platform) {
    let data = sessionStorage.getItem('regionList') || '{}';
    data = JSON.parse(data);
    if (data && data[projectId]) {
        return Promise.resolve(data[projectId]);
    }
    let api = '/tums/regionDevice/v1/getRootRegionsExcludeEmpty';
    let param = {
        projectId,
        sysType: 0
    };

    if (platform === 'cloud') {
        api = '/tums/regionDevice/v1/getRootRegionsExcludeEmptyWithPermission';
        param.permissionType = permissionType;
    }

    return tumsAxios.chainPostProxy(api, param)
        .then(res => {
            let regionList;
            regionList = res.result || [];
            if (!regionList.length) TumsMessage.text('无区域/分组');
            data[projectId] = regionList;
            sessionStorage.setItem('regionList', JSON.stringify(data));
            return regionList;
        });
}

function getRegionChildren(regionList, regionId, permissionType = 0, platform) {
    let api = '/tums/regionDevice/v1/getRegionChildrenExcludeEmpty';
    let param = {
        regionId
    };

    if (platform === 'cloud') {
        api = '/tums/regionDevice/v1/getRegionChildrenExcludeEmptyWithPermission';
        param.permissionType = permissionType;
    }

    tumsAxios.postProxy(api, param, (res) => {
        let list;
        list = res.result || [];
        if (list.length) {
            regionList.push(...list);
            list.forEach(region => {
                if (region.hasChildren) {
                    setTimeout(() => {
                        getRegionChildren(regionList, region.regionId, permissionType, platform);
                    }, 500);
                }
            });
        }
    });
}

export async function getRegionList(regionList, projectId, permissionType, platform) {
    let result = await getRootRegions(projectId, permissionType, platform);
    if (!result.length) return;
    regionList.push(...result);
    result.forEach(region => {
        if (region.hasChildren) {
            getRegionChildren(regionList, region.regionId, permissionType, platform);
        }
    });
}
