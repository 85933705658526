import { Decoder, tools, Reader } from 'ts-ebml';
import { blobDownload } from '@/utils/download';
import { getSupportMimeTypes } from '@/utils/getSupportMimeTypes';

const DEFAULT_MIME_TYPE = 'video/webm';
const MAX_RECORD_VIDEO_TIME = 1000 * 60 * 10; // 最大录制时间，10min

function readAsArrayBuffer(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = () => {
            reject(reader.error);
        };
    });
}

export class VideoRecorder {
    /**
     * Creates an instance of VideoRecorder.
     * @param {DOMPoint} canvas
     * @param {Object} audio
     * @memberof VideoRecorder
     */
    constructor(videoStream, audioStream) {
        let supportedMimeTypes = getSupportMimeTypes();
        let videoMimeType = supportedMimeTypes[0] || DEFAULT_MIME_TYPE;

        this.recordChunks = new Blob([], { type: videoMimeType });
        this.recordStartTime = 0;
        this.tasks = Promise.resolve();
        this.decoder = new Decoder();
        this.reader = new Reader();

        const combinedStream = new MediaStream([...videoStream.getTracks(), ...audioStream.getTracks()]); // 合并视频轨道和音频轨道

        this.recorder = new MediaRecorder(combinedStream, {
            mimeType: videoMimeType
        });

        this.recorder.ondataavailable = (e) => {
            const chunk = e.data;
            this.recordChunks = new Blob([this.recordChunks, chunk], { type: chunk.type });

            const task = async() => {
                const buf = await readAsArrayBuffer(chunk);
                const elms = this.decoder.decode(buf);
                elms.forEach((elm) => {
                    this.reader.read(elm);
                });
            };
            this.tasks = this.tasks.then(() => task());

            if (Date.now() - this.recordStartTime > MAX_RECORD_VIDEO_TIME) {
                this.stopAndblobDownload();
            }
        };
    }

    startRecord(timeslice = 10) {
        if (this.recorder.state === 'recording') return;
        this.recorder.start(timeslice);
        this.recordStartTime = Date.now();
    }

    async stopRecord() {
        await this.tasks; // wait data processing
        this.recorder.stop();
        this.reader.stop();
    }

    getState() {
        return this.recorder.state;
    }

    getRecordInfo() {
        if (this.recorder && this.recorder !== 'inactive') {
            return {
                size: Math.floor(this.recordChunks.size / 1000),
                time: Date.now() - this.recordStartTime
            };
        }
        return {
            size: 0,
            time: 0
        };
    }

    async stopAndblobDownload(fileName = 'record', download = true) {
        if (!this.recorder || this.recorder.state === 'inactive') return;

        await this.stopRecord();

        const { reader, recordChunks } = this;
        const time = Date.now();
        const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, Date.now() - this.recordStartTime, reader.cues);
        const webMBuf = await readAsArrayBuffer(recordChunks);
        const body = webMBuf.slice(reader.metadataSize);
        const fixedBlob = new Blob([refinedMetadataBuf, body], { type: recordChunks.type });

        download && blobDownload(fixedBlob, `${fileName}_${time}.webm`);
        this.recordChunks = new Blob([]);
        return fixedBlob;
    }
}
