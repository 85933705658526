import { BitArray } from '@/utils/binary';
import { PESAsm } from './pes';
import { NALUAsm } from '@/elementary/NALUAsm';
import { NALUAsmH265 } from '@/elementary/NALUAsmH265';
import { AACFrame } from '@/elementary/AACFrame';
import { ADTS } from './adts';
import PayloadType from '@/utils/payloadType';
import { Array } from 'core-js';
import { getTagged } from '@/utils/logger';

const Log$8 = getTagged('parse ts');
export class PESType {
    static get AAC() {
        return 0x0f;
    } // ISO/IEC 13818-7 ADTS AAC (MPEG-2 lower bit-rate audio) 15
    static get ID3() {
        return 0x15;
    } // Packetized metadata (ID3) 21
    static get H264() {
        return 0x1b;
    } // ITU-T Rec. H.264 and ISO/IEC 14496-10 (lower bit-rate video) 27
    static get H265() {
        return 0x24;
    } // ITU-T Rec. H.265 and ISO/IEC 23008-2 36
    static get PCMA() {
        return 0x90;
    } // G711 PCM ALAW, 8KHz, 16bit 144
    static get PCMU() {
        return 0x91;
    } // 145
    static get PCMA_W() {
        return 0x92;
    } // G711 PCM MULAW, 16KHz, 16bit 146
}

export class TSParser {
    static get PACKET_LENGTH() {
        return 188;
    }

    constructor() {
        this.pmtParsed = false;
        this.pesParserTypes = new Map();
        this.pesParsers = new Map();
        this.pesAsms = {};
        this.ontracks = null;
        this.toSkip = 0;
        this.pesTypes = {};
        this.iframeTime = 0;
        this.startPts = 0;
        this.patInfo = {
            pmtList: []
        };
    }

    addPesParser(pesType, constructor) {
        this.pesParserTypes.set(pesType, constructor);
    }

    parse(packet) {
        let bits = new BitArray(packet);
        if (packet[0] === 0x47) {
            bits.skipBits(9);
            let payStart = bits.readBits(1);
            bits.skipBits(1);
            let pid = bits.readBits(13);
            bits.skipBits(2);
            let adaptFlag = bits.readBits(1);
            let payFlag = bits.readBits(1);
            bits.skipBits(4);
            if (adaptFlag) {
                let adaptSize = bits.readBits(8);
                this.toSkip = bits.skipBits(adaptSize * 8);
                if (bits.finished()) {
                    return;
                }
            }
            if (!payFlag) return;

            let payload = packet.subarray(bits.bytepos); // bitSlice(packet, bits.bitpos+bits.bytepos*8);

            if (this.pmtParsed && this.pesParsers.has(pid)) {
                let pes = this.pesAsms[pid].feed(payload, payStart);
                if (pes) {
                    // let pesType = this.pesTypes[pid];
                    // let clockRate = (pesType === PESType.H264 || pesType === PESType.H265) ? 90000 : pes.samplerate || 8000;
                    if (!this.startPts || pes.pts - this.startPts < 0 || pes.pts - this.startPts > 900000) this.startPts = pes.pts;
                    let timestamp = (this.iframeTime + (pes.pts - this.startPts) / 90000) * 1000;
                    let data = this.pesParsers.get(pid).parse(pes);
                    data.timestamp = parseInt(timestamp);
                    return data;
                }
            } else {
                if (pid === 0) {
                    this.patInfo = this.parsePAT(payload);
                    if (!this._hasLogPat) {
                        Log$8.log('pat info: ', this.patInfo);
                        this._hasLogPat = true;
                    }
                } else if (this.patInfo.pmtList.find(pmt => pmt.pmtId === pid)) {
                    this.parsePMT(payload);
                    this.pmtParsed = true;

                    if (!this._hasLogPmt) {
                        Log$8.log('pmt info: ', this.patInfo.pmtList.find(pmt => pmt.pmtId === pid));
                        this._hasLogPmt = true;
                    }
                } else if (pid === 0x1fff) {
                    this.parseNPT(payload);
                }
            }
        }
        return null;
    }
    // pat第一个包 入口 含有pmt包的pid
    parsePAT(data) {
        let bits = new BitArray(data);
        let packet = {};

        bits.skipBits(8);
        packet.tableId = bits.readBits(8); // 固定为0x00 ，标志是该表是PAT
        packet.sectionSyntax = bits.readBits(1);  // 段语法标志位，固定为1
        packet.zero = bits.readBits(1); // 0
        packet.reserved1 = bits.readBits(2); // 保留位
        packet.sectionLength = bits.readBits(12); // 表示这个字节后面有用的字节数，包括CRC32
        packet.transportStreamId = bits.readBits(16); // 该传输流的ID，区别于一个网络中其它多路复用的流
        packet.reserved2 = bits.readBits(2); // 保留位
        packet.version = bits.readBits(5); // 范围0-31，表示PAT的版本号
        packet.currentNextIndicator = bits.readBits(1); // 发送的PAT是当前有效还是下一个PAT有效
        packet.sectionNumber = bits.readBits(8); // 分段的号码。PAT可能分为多段传输，第一段为00，以后每个分段加1，最多可能有256个分段
        packet.lastSectionNumber = bits.readBits(8); // 最后一个分段的号码
        packet.pmtList = [];

        for (let n = 0; n < packet.sectionLength - 12; n += 4) {
            let programNum = bits.readBits(16);
            packet.reserved3 = bits.readBits(3); // 保留位
            packet.networkPid = 0x00; // 网络信息表（NIT）的PID,节目号为0时对应的PID为network_PID

            if (programNum === 0) {
                packet.networkPid = bits.readBits(13);
            } else {
                let pmt = {};
                pmt.programNum = programNum;
                pmt.pmtId = bits.readBits(13);  // 节目映射表的PID，节目号大于0时对应的PID，每个节目对应一个
                packet.pmtList.push(pmt);
            }
        }

        return packet;
    }

    parsePMT(data) {
        let bits = new BitArray(data);
        let ptr = bits.readBits(8);
        bits.skipBits(8 * ptr + 8);
        bits.skipBits(6);
        // 后面数据的长度
        let secLen = bits.readBits(10);
        bits.skipBits(62);
        let pil = bits.readBits(10);
        bits.skipBits(pil * 8);

        let tracks = new Set();
        let readLen = secLen - 13 - pil;
        while (readLen > 0) {
            let pesType = bits.readBits(8);
            bits.skipBits(3);
            let pid = bits.readBits(13);
            bits.skipBits(6);
            let il = bits.readBits(10);
            bits.skipBits(il * 8);
            if ([PESType.AAC, PESType.PCMA, PESType.H264, PESType.H265, PESType.PCMA_W].includes(pesType)) {
                if (this.pesParserTypes.has(pesType) && (!this.pesParsers.has(pid) || this.pesTypes[pid] !== pesType)) {
                    this.pesParsers.set(pid, new (this.pesParserTypes.get(pesType))(pesType));
                    this.pesAsms[pid] = new PESAsm();
                    this.pesTypes[pid] = pesType;
                    switch (pesType) {
                        case PESType.H264:
                            tracks.add({ type: PayloadType.H264, offset: 0 });
                            break;
                        case PESType.H265:
                            tracks.add({ type: PayloadType.H265, offset: 0 });
                            break;
                        case PESType.AAC:
                            tracks.add({ type: PayloadType.AAC, offset: 0 });
                            break;
                        case PESType.PCMA:
                            tracks.add({ type: PayloadType.G711A, offset: 0 });
                            break;
                        case PESType.PCMA_W:
                            tracks.add({ type: PayloadType.PCMA_W, offset: 0 });
                            break;
                    }
                }
            }
            readLen -= 5 + il;
        }
        // TODO: notify about tracks
        if (this.ontracks) {
            this.ontracks(Array.from(tracks));
        }
    }

    parseNPT(data) {
        let bits = new BitArray(data);

        let packetType = bits.readBits(8);

        switch (packetType) {
            case 0:
                // ANCHOR packet
                break;
            case 1:
                // AUX packet
                bits.skipBits(16);
                this.iframeTime = bits.readBits(64);
                this.startPts = 0;
                break;
            case 2:
                // EVENT packet
                break;
            default:
                break;
        }
    }
}

export class PESParser {
    static get PARSERS() {
        return {
            [PESType.H264]: NALUAsm,
            [PESType.H265]: NALUAsmH265,
        };
    }

    static get PAYLOAD_TYPE_MAP() {
        return {
            [PESType.H264]: PayloadType.H264,
            [PESType.H265]: PayloadType.H265,
        };
    }

    constructor(pesType) {
        this.lastUnit = null;
        this.payloadType = PESParser.PAYLOAD_TYPE_MAP[pesType] || PayloadType.H264;

        let asm = PESParser.PARSERS[pesType];
        this.naluasm = asm ? new asm() : null;
    }

    parse(pes) {
        if (!this.naluasm) return null;

        let array = pes.data;
        let i = 0,
            len = array.byteLength,
            value,
            overflow,
            state = 0;
        let units = [],
            lastUnitStart;
        while (i < len) {
            value = array[i++];
            // finding 3 or 4-byte start codes (00 00 01 OR 00 00 00 01)
            switch (state) {
                case 0:
                    if (value === 0) {
                        state = 1;
                    }
                    break;
                case 1:
                    if (value === 0) {
                        state = 2;
                    } else {
                        state = 0;
                    }
                    break;
                case 2:
                case 3:
                    if (value === 0) {
                        state = 3;
                    } else if (value === 1 && i < len) {
                        if (lastUnitStart) {
                            let nalu = this.naluasm.onNALUFragment(array.subarray(lastUnitStart, i - state - 1), pes.dts);
                            if (nalu) units.push(nalu[0]);
                        } else {
                            // If NAL units are not starting right at the beginning of the PES packet, push preceding data into previous NAL unit.
                            overflow = i - state - 1;
                            if (overflow) {
                                if (this.lastUnit) {
                                    this.lastUnit.data = appendByteArray(this.lastUnit.data.byteLength, array.subarray(0, overflow));
                                }
                            }
                        }
                        lastUnitStart = i;
                        state = 0;
                    } else {
                        state = 0;
                    }
                    break;
                default:
                    break;
            }
        }
        if (lastUnitStart) {
            let nalu = this.naluasm.onNALUFragment(array.subarray(lastUnitStart, len), pes.dts, pes.pts);
            if (nalu) {
                units.push(nalu[0]);
            }
        }
        this.lastUnit = units[units.length - 1];
        return { units: units, type: this.payloadType };
    }
}

export class AACPESParser {
    constructor() {
        this.aacOverFlow = null;
        this.lastAacPTS = null;
        this.track = {};
        this.config = null;
    }

    parse(pes) {
        let data = pes.data;
        let pts = pes.pts;
        let startOffset = 0;
        let aacOverFlow = this.aacOverFlow;
        let lastAacPTS = this.lastAacPTS;
        var config, frameDuration, frameIndex, offset, stamp, len;

        if (aacOverFlow) {
            var tmp = new Uint8Array(aacOverFlow.byteLength + data.byteLength);
            tmp.set(aacOverFlow, 0);
            tmp.set(data, aacOverFlow.byteLength);
            // Log.debug(`AAC: append overflowing ${aacOverFlow.byteLength} bytes to beginning of new PES`);
            data = tmp;
        }

        // look for ADTS header (0xFFFx)
        for (offset = startOffset, len = data.length; offset < len - 1; offset++) {
            if (data[offset] === 0xff && (data[offset + 1] & 0xf0) === 0xf0) {
                break;
            }
        }
        // if ADTS header does not start straight from the beginning of the PES payload, raise an error
        if (offset) {
            var reason, fatal;
            if (offset < len - 1) {
                reason = `AAC PES did not start with ADTS header,offset:${offset}`;
                fatal = false;
            } else {
                reason = 'no ADTS header found in AAC PES';
                fatal = true;
            }
            Log.error(reason);
            if (fatal) {
                return;
            }
        }

        let hdr = null;
        let res = { units: [], type: PayloadType.AAC };
        if (!this.config) {
            hdr = ADTS.parseHeaderConfig(data.subarray(offset));
            this.config = hdr.config;
            res.config = hdr.config;
            hdr.config = null;
            // Log.debug(`parsed codec:${this.config.codec},rate:${this.config.samplerate},nb channel:${this.config.channels}`);
        }
        frameIndex = 0;
        frameDuration = (1024 * 90000) / this.config.samplerate;

        // if last AAC frame is overflowing, we should ensure timestamps are contiguous:
        // first sample PTS should be equal to last sample PTS + frameDuration
        if (aacOverFlow && lastAacPTS) {
            var newPTS = lastAacPTS + frameDuration;
            if (Math.abs(newPTS - pts) > 1) {
                // Log.debug(`AAC: align PTS for overlapping frames by ${Math.round((newPTS - pts) / 90)}`);
                pts = newPTS;
            }
        }

        while (offset + 5 < len) {
            if (!hdr) {
                hdr = ADTS.parseHeader(data.subarray(offset));
            }
            if (hdr.size > 0 && offset + hdr.offset + hdr.size <= len) {
                stamp = pts + frameIndex * frameDuration;
                res.units.push(new AACFrame(data.subarray(offset + hdr.offset, offset + hdr.offset + hdr.size), stamp));
                offset += hdr.offset + hdr.size;
                frameIndex++;
                // look for ADTS header (0xFFFx)
                for (; offset < len - 1; offset++) {
                    if (data[offset] === 0xff && (data[offset + 1] & 0xf0) === 0xf0) {
                        break;
                    }
                }
            } else {
                break;
            }
            hdr = null;
        }
        if (offset < len && data[offset] == 0xff) {
            // TODO: check it
            aacOverFlow = data.subarray(offset, len);
            //logger.log(`AAC: overflow detected:${len-offset}`);
        } else {
            aacOverFlow = null;
        }
        this.aacOverFlow = aacOverFlow;
        this.lastAacPTS = stamp;

        return res;
    }
}

export class PCMAPESParser {
    constructor() {
        this.dts = 0;
        this.pts = 0;
        this.data = null;
    }

    parse(pes) {
        this.dts = pes.dts;
        this.pts = pes.pts;
        this.data = pes.data.subarray(0, pes.data.byteLength - 8);
        return { units: [{
            dts: this.dts,
            pts: this.pts,
            data: this.data
        }], type: PayloadType.G711A };
    }
}

export class PCMA_WPESParser {
    constructor() {
        this.dts = 0;
        this.pts = 0;
        this.data = null;
        this.samplerate = 8000;
        this.wChannels = 0;
    }

    parse(pes) {
        // 146
        this.dts = pes.dts;
        this.pts = pes.pts;

        this.data = pes.data;
        let dataView = new DataView(this.data.buffer);
        // let RIFF = charCodeToStr(this.data.subarray(0, 4)); // RIFF
        // let RIFFLength = this.data.subarray(4,8); // [36, 4, 0 , 0]
        // let judegeCode = charCodeToStr(this.data.subarray(8,12)); // Wave
        // let Fmt = charCodeToStr(this.data.subarray(12,16)); //'FMT'
        // let FMTlength = this.data.subarray(16,20); // [16, 0, 0 , 0]
        let wFormatTag = dataView.getUint16(20, true); // [6, 0]
        this.wChannels =  dataView.getUint16(22, true); // [1, 0]
        this.samplerate = dataView.getUint32(24, true); // [64, 31, 0, 0]
        let type = 5;
        if (wFormatTag === 7) {
            type = 'PCMU';
        } else if(wFormatTag === 6) {
            type = 'G711alaw';
        } else if(wFormatTag === 69) {
            type = 'G726';
        } else if(wFormatTag === 17) {
            type = 'ADPCM';
        }
        let bitCount = dataView.getUint16(34, true);
        return {
            units: [
                {
                    data: this.data.subarray(44, this.data.byteLength - 8),
                    dts: this.dts,
                    pts: this.pts,
                    seq: pes.seq,
                    timestamp: pes.timestamp,
                    samplerate: this.samplerate,
                    type: type,
                    bitCount: bitCount
                },
            ],
            type: PayloadType.PCMA_W,
        };
    }
}
