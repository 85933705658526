const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

export const randomStr = (len) => {
    let ret = '';
    for (let i = 0; i < parseInt(len / 4); i++) {
        ret += S4();
    }
    ret += S4().slice(0, len % 4);
    return ret;
};

const storageKey = '__TUMS_PLAYER_STORAGE__';
export const windowStorage = {
    getItem(key) {
        if (!window[storageKey]) return null;
        return window[storageKey][key];
    },
    setItem(key, val) {
        if (!window[storageKey]) window[storageKey] = {};
        window[storageKey][key] = val;
    }
};

export const isMobile = () => {
    return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
};