// display mode
export const TUMSPLAYER_DISPLAYER_MODE_ORIGIN = 'ORIGIN';
export const TUMSPLAYER_DISPLAYER_MODE_FISHEYE_CYLINDER = 'FISHEYE_CYLINDER';
export const TUMSPLAYER_DISPLAYER_MODE_FISHEYE_360D = 'FISHEYE_360D';
export const TUMSPLAYER_DISPLAYER_MODE_FISHEYE_180D = 'FISHEYE_180D';
export const TUMSPLAYER_DISPLAYER_MODE_FISHEYE_LONGITUDE = 'FISHEYE_LONGITUDE';
export const TUMSPLAYER_DISPLAYER_MODE_FISHEYE_LATITUDE = 'FISHEYE_LATITUDE';
export const TUMSPLAYER_DISPLAYER_MODE_FISHEYE_CALIB = 'FISHEYE_CALIB';
export const TUMSPLAYER_DISPLAYER_MODE_FISHEYE_WIN_PLANE_TOP_QUAD = 'FISHEYE_WIN_PLANE_TOP_QUAD'; // 顶装4分屏
export const TUMSPLAYER_DISPLAYER_MODE_FISHEYE_WIN_PLANE_TOP = 'FISHEYE_WIN_PLANE_TOP'; // 顶装
export const TUMSPLAYER_DISPLAYER_MODE_FISHEYE_WIN_PLANE_WALL = 'FISHEYE_WIN_PLANE_WALL'; // 壁装

/** 图像类型 */
export const TUMSPLAYER_FRAME_TYPE_YUV = 'YUV_FRAME';
export const TUMSPLAYER_FRAME_TYPE_VIDEO = 'VIDEO_FRAME';

/** 顶装原图参数极值 */
export const TUMSPLAYER_GL_WIN_PLANE_TOP_ALPHA_MIN = (-Math.PI / 2 + 0.05);
export const TUMSPLAYER_GL_WIN_PLANE_TOP_ALPHA_MAX = 0.0;
export const TUMSPLAYER_GL_WIN_PLANE_TOP_VIEW_POINTZ_MIN = 0.35;    /* 相较1.0，近似放大4倍 */
export const TUMSPLAYER_GL_WIN_PLANE_TOP_VIEW_POINTZ_MAX = 1.0;    /* default */
/** 顶装四分屏参数极值 */
export const TUMSPLAYER_GL_WIN_PLANE_TOP_QUAD_VIEW_POINTZ_MIN = TUMSPLAYER_GL_WIN_PLANE_TOP_VIEW_POINTZ_MIN;
export const TUMSPLAYER_GL_WIN_PLANE_TOP_QUAD_VIEW_POINTZ_MAX = 0.61;
/** 壁装原图参数极值 */
export const TUMSPLAYER_GL_WIN_PLANE_WALL_PRODUCT_OF_ALPHA_AND_VIEW_POINTZ_MIN = -0.44;        /* alpha和viewPointZ的乘积的最小值 */
export const TUMSPLAYER_GL_WIN_PLANE_WALL_PRODUCT_OF_ALPHA_AND_VIEW_POINTZ_MAX = 0.44;         /* alpha和viewPointZ的乘积的最大值 */
export const TUMSPLAYER_GL_WIN_PLANE_WALL_BETA_MIN = (-Math.PI / 2.0);
export const TUMSPLAYER_GL_WIN_PLANE_WALL_BETA_MAX = (Math.PI / 2.0);
export const TUMSPLAYER_GL_WIN_PLANE_WALL_VIEW_POINTZ_MIN = TUMSPLAYER_GL_WIN_PLANE_TOP_VIEW_POINTZ_MIN;
export const TUMSPLAYER_GL_WIN_PLANE_WALL_VIEW_POINTZ_MAX = TUMSPLAYER_GL_WIN_PLANE_TOP_VIEW_POINTZ_MAX;
/** 圆柱体展开程度极值 */
export const TUMSPLAYER_GL_UNFOLD_PARA_MIN = 0.0;
export const TUMSPLAYER_GL_UNFOLD_PARA_MAX = 1.0;

/** 各模式参数(巡航相关除外)的最终值。当画面处于初始状态时，双击则变为最终值；否则双击变为初始值。 */
export const TUMSPLAYER_GL_360D_HEIGHT_FINAL = 1.0;
export const TUMSPLAYER_GL_WIN_PLANE_TOP_ALPHA_FINAL = (-Math.PI / 4);
export const TUMSPLAYER_GL_WIN_PLANE_TOP_VIEW_POINTZ_FINAL = 0.5;
export const TUMSPLAYER_GL_WIN_PLANE_WALL_ALPHA_FINAL = 0.0;
export const TUMSPLAYER_GL_WIN_PLANE_WALL_VIEW_POINTZ_FINAL = 0.56;


/** 水平分割线的宽度，竖直分隔线的宽度依据屏幕高宽比和水平分隔线的宽度而计算。用于鱼眼四分屏和180D展开视图模式中相邻分屏之间的分割。 */
export const TUMSPLAYER_GL_HORIZONTAL_SPLIT_LINE_WIDTH = 0.002;

// 顶点坐标
export const vertexPositionDefault = [1.0, 1.0, 0.0, -1.0, 1.0, 0.0, 1.0, -1.0, 0.0, -1.0, -1.0, 0.0];
// 纹理坐标
export const textureCoordDefault = [1.0, 0.0, 0.0, 0.0, 1.0, 1.0, 0.0, 1.0];

export const fishEyeAngleDefault = Math.PI / 2;
export const unfoldParaDefault = 1.0;

// 为了使四分屏时，四个分屏尽可能看到全面的画面，又没有黑色区域，所以圆形和非圆形需要采用不同的初始值
export const fWinPlaneTopQuadAlphaDefault4Circle = -0.9;
export const fWinPlaneTopQuadAlphaDefault4CutUpDown = -0.5;
export const fWinPlaneTopQuadBetaDefault = [0.0, Math.PI / 2.0, Math.PI, Math.PI * 3.0 / 2.0];
export const fWinPlaneTopQuadSizeOverPointZDefault = 0.8;
export const fWinPlaneTopQuadViewPointZDefualt = 0.4;

export const fWinPlaneAlphaDefault = 0.0;
export const fWinPlaneBetaDefault = 0.0;
export const fWinPlaneSizeOverPointZDefault = TUMSPLAYER_GL_WIN_PLANE_TOP_VIEW_POINTZ_MAX;
export const fWinPlaneViewPointZDefualt = TUMSPLAYER_GL_WIN_PLANE_TOP_VIEW_POINTZ_MAX;
// 投影模型组合的编号
export const fWinPlaneProjModeDefault = 0;
// 投影模型组合中第一个投影模型的系数
export const fWinPlaneCoefficientDefault = 1;

// 屏幕宽高比，默认1:1
export const heightOverWidthOfScreen = 1;
