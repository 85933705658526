export function getEventCalendar(devId, startDate, endDate) {
    return new Promise((resolve) => {
        tumsAxios
            .chainPost(
                '/tums/index/v1/getEventCalendar',
                {
                    devId,
                    startDate,
                    endDate,
                },
                (_, err) => {
                    TumsMessage.error(err);
                    resolve([]);
                }
            )
            .then(({ result = {} }) => {
                resolve(result.dateList || []);
            });
    });
}

export function getEventListByPage(params) {
    return new Promise((resolve) => {
        tumsAxios
            .chainPost('/tums/index/v1/getEventListByPage', params, (_, err) => {
                TumsMessage.error(err);
                resolve([]);
            })
            .then(({ result = {} }) => {
                resolve(result);
            });
    });
}

export function searchYear(videoDevId, year) {
    return new Promise((resolve) => {
        tumsAxios
            .chainPostProxy(
                '/tums/playback/v1/searchYear',
                {
                    id: [{ videoDevId }],
                    year,
                },
                (_, err) => {
                    TumsMessage.error(err);
                    resolve([]);
                }
            )
            .then(({ result = [] }) => {
                result = result[0] || {};
                let days = (result.dates || []).map((item) => item.slice(0, 4));
                resolve({
                    year,
                    days
                });
            });
    });
}

export function searchVideo(params) {
    return new Promise((resolve) => {
        tumsAxios
            .chainPostProxy('/tums/playback/v2/searchVideo', params, (_, err) => {
                TumsMessage.error(err);
                resolve([]);
            })
            .then(({ result = {} }) => {
                resolve(result);
            });
    });
}
