import { NALU } from './NALU';
import PayloadType from '@/utils/payloadType';

export class NALUAsmH265 {
    constructor() {
        this.fragmented_nalu = null;
    }

    static parseNALHeader(hdr) {
        return {
            nri: hdr & 0x60,
            type: hdr & 0x1f,
        };
    }

    parseSingleNALUPacket(header_type, rawData, dts, pts) {
        return new NALU(header_type, rawData.subarray(0), dts, pts, null, PayloadType.H265);
    }

    parseFragmentationUnit(rawData, dts, pts) {
        let data = new DataView(rawData.buffer, rawData.byteOffset, rawData.byteLength);
        let nal_start_idx = 2; /* pass the HEVC payload header */
        let fu_header = data.getUint8(nal_start_idx);
        let is_start = (fu_header & 0x80) >>> 7;
        let is_end = (fu_header & 0x40) >>> 6;
        let payload_type = fu_header & 0x3f;
        let ret = null;

        nal_start_idx++;

        if (is_start) {
            let naluData = rawData.subarray(nal_start_idx);
            let newNaluHeader = new Uint8Array(2 + naluData.length);
            newNaluHeader[0] = (data.getUint8(0) & 0x81) | (payload_type << 1);
            newNaluHeader[1] = data.getUint8(1);
            newNaluHeader.set(naluData, 2);
            this.fragmented_nalu = new NALU(payload_type, newNaluHeader, dts, pts, null, PayloadType.H265);
        } else {
            if (this.fragmented_nalu && this.fragmented_nalu.ntype === payload_type) {
                this.fragmented_nalu.appendData(rawData.subarray(nal_start_idx));

                if (is_end) {
                    ret = this.fragmented_nalu;
                    this.fragmented_nalu = null;
                    return ret;
                }
            }
        }
        return null;
    }

    onNALUFragment(rawData, dts, pts) {
        let data = new DataView(rawData.buffer, rawData.byteOffset, rawData.byteLength);

        let nal_type = (data.getUint8(0) >> 1) & 0x3f;

        let nal_start_idx = 0;

        let unit = null;
        if (nal_type > 0 && nal_type < 48) {
            unit = this.parseSingleNALUPacket(nal_type, rawData.subarray(nal_start_idx), dts, pts);
        } else if (nal_type == 49) {
            unit = this.parseFragmentationUnit(rawData.subarray(nal_start_idx), dts, pts);
        } else {
            /* 30 - 31 is undefined, ignore those (RFC3984). */
            // Log.log('Undefined NAL unit, type: ' + header.type);
            return null;
        }
        if (unit) {
            return [unit];
        }
        return null;
    }
}