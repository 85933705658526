// 兼容
window.URL = window.URL || window.webkitURL;
navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
let TumsAdudioRecorder = function(stream, config) {
    config = config || {};
    config.sampleBits = config.sampleBits || 16; // 采样数位 8, 16
    config.sampleRate = config.sampleRate || 8000; // 采样率(8000)
    let context = new (window.webkitAudioContext || window.AudioContext)();
    // / 创建⼀个MediaStreamAudioSourceNode接⼝来关联可能来⾃本地计算机麦克风或其他来源的⾳频流MediaStream
    let audioInput = context.createMediaStreamSource(stream);
    // // 创建⼀个ScriptProcessorNode ⽤于通过JavaScript直接处理⾳频.
    let createScript = context.createScriptProcessor || context.createJavaScriptNode;
    let recorder = createScript.apply(context, [4096, 1, 1]);
    let audioData = {
        size: 0, // 录音文件长度
        buffer: [], // 录音缓存
        inputSampleRate: context.sampleRate, // 输入采样率
        inputSampleBits: 16, // 输入采样数位 8, 16
        outputSampleRate: config.sampleRate, // 输出采样率
        oututSampleBits: config.sampleBits, // 输出采样数位 8, 16
        input: function(data) {
            this.buffer.push(new Float32Array(data));
            this.size += data.length;
        },
        clear: function() {
            this.buffer = [];
            this.size = 0;
        },
        compress: function() {
            // 合并压缩
            // 合并
            let data = new Float32Array(this.size);
            let offset = 0;
            for (let i = 0; i < this.buffer.length; i++) {
                data.set(this.buffer[i], offset);
                offset += this.buffer[i].length;
            }
            // 压缩
            let compression = parseInt(this.inputSampleRate / this.outputSampleRate);
            let length = data.length / compression;
            let result = new Float32Array(length);
            let index = 0;
            let j = 0;
            while (index < length) {
                result[index] = data[j];
                j += compression;
                index++;
            }
            return result;
        },
        transToPcmData: function() {
            let sampleRate = Math.min(this.inputSampleRate, this.outputSampleRate);
            let sampleBits = Math.min(this.inputSampleBits, this.oututSampleBits);
            let bytes = this.compress();
            let dataLength = bytes.length * (sampleBits / 8);
            let buffer = new ArrayBuffer(dataLength);
            let data = new DataView(buffer);
            let offset = 0;
            // 写入采样数据
            if (sampleBits === 8) {
                for (let i = 0; i < bytes.length; i++, offset++) {
                    let s = Math.max(-1, Math.min(1, bytes[i]));
                    let val = s < 0 ? s * 0x8000 : s * 0x7fff;
                    val = parseInt(255 / (65535 / (val + 32768)));
                    data.setInt8(offset, val, true);
                }
            } else {
                for (let i = 0; i < bytes.length; i++, offset += 2) {
                    let s = Math.max(-1, Math.min(1, bytes[i]));
                    data.setUint16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
                }
            }
            // pcm数据
            return new Blob([data]);
        },
    };
    // 开始录音
    this.start = function() {
        audioInput.connect(recorder);
        recorder.connect(context.destination);
    };
    // 触发发送数据
    this.audioTatch = () => {};
    let self = this;
    // 停止
    this.stop = function() {
        audioInput.disconnect();
        recorder.disconnect();
        const tracks = stream.getAudioTracks();
        tracks[0].stop();
    };
    this.clear = function() {
        audioData.clear();
    };
    // 获取音频文件
    this.getPcmData = function() {
        return audioData.transToPcmData();
    };
    // 回放
    this.play = function(audio) {
        let downRec = document.getElementById('downloadRec');
        downRec.href = window.URL.createObjectURL(this.getPcmData());
        downRec.download = new Date().toLocaleString() + '.mp3';
        audio.src = window.URL.createObjectURL(this.getPcmData());
    };
    // 音频采集
    recorder.onaudioprocess = function(e) {
        audioData.clear();
        audioData.input(e.inputBuffer.getChannelData(0));
        self.audioTatch();
        // record(e.inputBuffer.getChannelData(0));
    };
};
// 抛出异常
TumsAdudioRecorder.throwError = function(message) {
    alert(message);
    throw new (function() {
        this.toString = function() {
            return message;
        };
    })();
};
// 是否支持录音
TumsAdudioRecorder.canRecording = navigator.getUserMedia != null;
// 获取麦克风
TumsAdudioRecorder.get = function(callback, config) {
    if (callback) {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                let rec = new TumsAdudioRecorder(stream, config);
                callback({ rec });
            }, (error) => {
                callback({ error });
            });
        } else if (navigator.getUserMedia) {
            navigator.getUserMedia(
                { audio: true }, // 只启用音频
                function(stream) {
                    let rec = new TumsAdudioRecorder(stream, config);
                    callback({
                        rec: rec
                    });
                },
                function(error) {
                    callback({
                        error: error
                    });
                }
            );
        } else {
            TumsAdudioRecorder.throwError('当前浏览器不支持录音功能。');
            return;
        }
    }
};
export default TumsAdudioRecorder;
