export function queryUserMenuList(enterpriseId, platform) {
    let data = sessionStorage.getItem('menuList') || '{}';
    data = JSON.parse(data);
    if (data && data[enterpriseId]) {
        return Promise.resolve(data[enterpriseId]);
    }
    let url = platform === 'cloud' ? '/tums/account/v2/queryUserMenuList' : '/tums/account/v1/queryUserMenuList';
    return tumsAxios.chainPostProxy(url, {
        enterpriseId: enterpriseId
    })
        .then(({ result = [] }) => {
            data[enterpriseId] = result;
            sessionStorage.setItem('menuList', JSON.stringify(data));
            return result;
        });
}