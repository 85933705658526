<template>
    <li
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
        @click="selectRoute"
        class="a-d-f a-jc-fs a-ai-fs a-fd-c a-p-h-md a-p-v-sm a-w-full"
    >
        <p class="a-d-f a-jc-sb a-ai-fs a-w-full">
            <span
                :title="tourName"
            >
                {{tourName}}
            </span>
            <span class="f-fcc" v-if="status === 'touring'">执行中</span>
        </p>
        <div class="a-d-f a-jc-sb a-ai-fs a-w-full"
        >
            <span class="a-font7">{{`${paths.length}个预置点`}}</span>
            <div>
                <tums-button
                    size="mini"
                    type="graytext"
                    v-for="type in operation"
                    :key="type.name"
                    :title="buttonTitle(type)"
                    @click.stop="operateRoute(type.name)">
                    <i :class="buttonIcon(type)"></i>
                </tums-button>
            </div>
        </div>
    </li>
</template>

<script>

const OPERATION_TYPE = {
    APPLY: 'apply',
    CONFIGURE: 'configure',
    CLEAR: 'clear'
};
const CRUISING = 'touring';

export default {
    name: 'routeItem',

    data() {
        return {
            // 鼠标在该巡航路径悬浮
            mouseOver: false,
            // 操作按钮
            operation: [{
                name: OPERATION_TYPE.APPLY,
                icon: 'tums-icon-play',
                title: '执行'
            }, {
                name: OPERATION_TYPE.CONFIGURE,
                icon: 'tums-icon-edit-outline',
                title: '编辑'
            }, {
                name: OPERATION_TYPE.CLEAR,
                icon: 'tums-icon-close',
                title: '删除'
            }]
        };
    },

    props: {
        index: {
            type: Number,
            required: true
        },
        tourName: {
            type: String,
            required: true
        },
        configured: {
            type: Boolean
        },
        selected: {
            type: Boolean,
            required: true
        },
        status: {
            type: String,
            required: true
        },
        paths: {
            type: Array
        }
    },
    watch: {
        status(val) {
            console.log('valdfsdfsfds', val);
        }
    },

    computed: {
        liClass() {
            return {
                active: this.selected,
                fade: !this.configured,
                hover: this.mouseOver
            };
        },
        buttonIcon() {
            return type => {
                if (type.name === OPERATION_TYPE.APPLY) {
                    return this.status === CRUISING ? 'tums-icon-stop' : 'tums-icon-play';
                } else {
                    return type.icon;
                }
            };
        },
        buttonTitle() {
            return type => {
                if (type.name === OPERATION_TYPE.APPLY) {
                    return this.status === CRUISING
                        ? '停止'
                        : '开始';
                } else {
                    return type.title;
                }
            };
        }
    },

    methods: {
        onMouseEnter() {
            this.mouseOver = true;
        },
        onMouseLeave() {
            this.mouseOver = false;
        },
        selectRoute() {
            this.$emit('select');
        },
        operateRoute(type) {
            switch (type) {
                case OPERATION_TYPE.APPLY:
                    this.$emit('apply');
                    break;
                case OPERATION_TYPE.CONFIGURE:
                    this.$emit('configure');
                    break;
                case OPERATION_TYPE.CLEAR:
                    this.$emit('clear');
                    break;
            }
        }
    }
};
</script>
