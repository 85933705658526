const URI_PREFIX = '/tums';
const VERSION = 'v1';
const MODELNAME = 'playback';

const makeUrl = (method, model = MODELNAME, v = VERSION) => {
    return `${URI_PREFIX}/${model}/${v}/${method}`;
};

const METHOD = {
    suspendPlayback: 'suspendPlayback',
    setPlaybackCfg: 'setPlaybackCfg',
    deletePlaybackChn: 'deletePlaybackChn',
    getCurrentTime: 'getCurrentTime'
};

export const PLAYBACK = {
    url: {
        suspendPlayback: makeUrl(METHOD.suspendPlayback),
        setPlaybackCfg: makeUrl(METHOD.setPlaybackCfg),
        deletePlaybackChn: makeUrl(METHOD.deletePlaybackChn),
        getCurrentTime: makeUrl(METHOD.getCurrentTime, 'systemConfig')
    },
    value: {
        suspendFlag: {
            play: 0,
            pause: 1
        }
    }
};
