export default function() {
    let ua = navigator.userAgent;
    let pf = navigator.platform;
    let env = {
        type: '',
        version: '',
        bit: '',
        os: '',
        osVersion: '0'
    };

    const BROWSER = {
        IE: 'ie',
        EDGE: 'edge',
        FIREFOX: 'firefox',
        CHROME: 'chrome',
        OPERA: 'opera',
        SAFARI: 'safari'
    };

    const OS = {
        WINDOWS: 'windows',
        WIN_PHONE: 'windowsPhone',
        LINUX: 'linux',
        IOS: 'iOS',
        ANDROID: 'android'
    };

    let rIE = /msie ((\d+\.)+\d+)/i;
    let rIE11 = /Trident\/[\d.]+[\w\W]*rv:11\.[\d.]+/i;
    let rEdge = /(edge)\/(\d+\.\d+)/i;
    let rChrome = /(chrome)\/(\d+\.\d+)/i;
    let rFirefox = /(firefox)\/(\d+\.\d+)/i;
    let rOpera = /(opera).(\d+\.\d+)/i;
    let rSarari = /version\/(\d+\.\d+).*(safari)/i;
    let isIE = rIE.test(ua) ? (document.mode || RegExp['$1']) : false;

    if (isIE !== false) {
        if (isIE <= 6) {
            env.version = '6.0';
        } else if (isIE === 7) {
            env.version = '7.0';
        } else if (isIE === 8) {
            env.version = '8.0';
        } else if (isIE === 9) {
            env.version = '9.0';
        } else if (isIE === 10) {
            env.version = '10.0';
        }

        env.type = BROWSER.IE;
    } else if (rIE11.test(ua)) {
        [env.type, env.version] = [BROWSER.IE, '11.0'];
    } else {
        [rEdge, rChrome, rFirefox, rOpera, rSarari].some(r => {
            let matches = ua.match(r);
            if (matches !== null) {
                [env.type, env.version] = [matches[1].toLowerCase(), matches[2]];
                if (/\d/.test(env.type)) {
                    [env.type, env.version] = [env.version, env.type];
                }
                return true;
            }
        });
    }

    if (pf.indexOf('64') >= 0) {
        env.bit = '64';
    } else {
        env.bit = '32';
    }

    if (typeof pf !== 'undefined') {
        if (pf.indexOf('Win') >= 0) {
            if (ua.indexOf('Windows Phone') >= 0) {
                env.os = OS.WIN_PHONE;
            } else {
                env.os = OS.WINDOWS;
                if (/Windows NT ([\d.]+)/i.test(ua)) {
                    env.osVersion = RegExp['$1'];
                }
            }
        } else if (ua.indexOf('Android') >= 0) {
            env.os = OS.ANDROID;
        } else if (pf.indexOf('iPad') >= 0 ||
            pf.indexOf('iPhone') >= 0 ||
            pf.indexOf('iPod') >= 0 ||
            pf.indexOf('Mac') >= 0) {
            env.os = OS.IOS;
        } else if (pf.indexOf('X11') >= 0 ||
            pf.indexOf('Linux') >= 0 ||
            pf.indexOf('arm') < 0) {
            env.os = OS.LINUX;
        }
    } else if (ua.indexOf('Android') >= 0) {
        env.os = OS.ANDROID;
    }

    return env;
};
