export const uuid = () => {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4());
};

export async function login(config) {
    let {
        username, password, platform, accountType
    } = config;

    let loginApi = '/tums/account/v2/app/login';
    let params = {
        username,
        password
    };

    if (platform === 'cloud') {
        params.terminalUUID = uuid();
        params.appType = 'VideoManagementSystem_SDKClient';
        if (accountType === 'sub') {
            let names = username.split('@');
            params.username = names[0];
            params.enterpriseCode = names[1];
        }
        window.bizToken = '';
    }

    return tumsAxios.chainPost(loginApi, params);
}
