<template>
    <tums-scrollbar ref="scrollbar" class="tume-player-time__panel">
        <div
            class="tume-player-time__wrap"
            :style="{ width: width + '%'}"
            @mouseenter="inPanel = true"
            @mouseleave="inPanel = false"
        >
            <div class="tume-player-time__tab tums-h-40 a-d-f a-ai-c a-pos-r">
                <div
                    v-for="item in timeTabs"
                    class="a-pos-a"
                    :key="item.label"
                    :style="{
                        left: `calc(${item.offset}% - 29px)`
                    }"
                    style="width: 58px; text-align:center"
                >
                    <div class="a-font4">{{ item.label }}</div>
                    <div class="divide-line"></div>
                </div>
            </div>
            <div
                ref="timeLine"
                class="tume-player-time__line tums-h-32 a-pos-r"
                @mouseover="showHoverTime = true"
                @mouseout="showHoverTime = false"
                @mousemove="handleHoverTimeline"
                @click="seek"
            >
                <div
                    v-for="(item, i) in timePeriods"
                    :key="i"
                    class="a-pos-a"
                    :class="item.videoType"
                    :style="{
                        width: item.width + '%',
                        height: '8px',
                        top: '8px',
                        left: item.left + '%'
                    }"
                ></div>
                <div v-show="videoList.length" class="tume-player-time__current a-pos-a" :style="{ left: currentTimeInfo.left + '%'}">
                    <div class="tume-player-time__label">{{currentTimeInfo.label}}</div>
                    <div class="tume-player-time__pointer"></div>
                </div>
                <div v-show="showHoverTime" class="tume-player-time__hover a-pos-a" :style="{ left: hoverTimeInfo.left + '%'}">
                    <div class="tume-player-time__label">{{hoverTimeInfo.label}}</div>
                    <div class="tume-player-time__pointer"></div>
                </div>
            </div>
        </div>
    </tums-scrollbar>
</template>

<script>
// 本组件时间单位统一使用秒
const totalTime = 24 * 60 * 60;
const scaleList = [7200, 3600, 1800, 600, 300];

export default {
    name: 'TimePanel',
    props: {
        player: Object,
        /**
         * videoList: [{
         *     startTime,
         *     endTime,
         *     videoType
         * }]
         */
        videoList: {
            type: Array,
            default() {
                return [];
            },
        },
        currentDate: Date,
        currentTime: {
            type: Number,
            default: new Date(new Date().toLocaleDateString()).getTime() / 1000
        }
    },
    data() {
        return {
            hoverTime: 0,
            showHoverTime: false,
            scale: scaleList[0],
            width: 100,
            inPanel: false,
        };
    },
    computed: {
        currentDateTime() {
            return new Date(this.currentDate.toLocaleDateString()).getTime() / 1000;
        },

        timeTabs() {
            let { scale } = this;
            let result = [];

            for (let i = 0; i <= totalTime; i++) {
                if (i === totalTime) {
                    result.push({
                        label: '24:00',
                        offset: 100
                    });
                    continue;
                }
                if (i % scale === 0) {
                    result.push({
                        label: new Date(i * 1000 - 28800000).format('hh:mm'),
                        offset: i / totalTime * 100,
                    });
                }
            }

            return result;
        },

        timePeriods() {
            let { currentDateTime } = this;
            let result = [];

            this.videoList.forEach(item => {
                let startTime = item.startTime - currentDateTime;
                let endTime = item.endTime - currentDateTime;

                if (startTime > totalTime || endTime < 0) return;

                startTime = Math.max(startTime, 0);
                endTime = Math.min(endTime, totalTime);

                result.push({
                    videoType: (item.videoType[0] === 1 || item.videoType === 1) ? 'time-record' : 'motion-detect',
                    width: (endTime - startTime) / totalTime * 100,
                    left: startTime / totalTime * 100
                });
            });
            return result;
        },

        currentTimeInfo() {
            let time = this.currentTime - this.currentDateTime;
            return this.getTimeInfo(time);
        },

        hoverTimeInfo() {
            let time = this.hoverTime - this.currentDateTime;
            return this.getTimeInfo(time);
        }
    },
    mounted() {
        document.onmousewheel = this.handleMouseScroll;
    },
    beforeDestroy() {
        document.onmousewheel = null;
    },
    methods: {
        getTimeInfo(time) {
            return {
                label: time === totalTime ? '24:00:00' : new Date(time * 1000 - 28800000).format('hh:mm:ss'),
                left: time / totalTime * 100
            };
        },

        handleHoverTimeline(e) {
            let { timeLine } = this.$refs;
            let offsetX = e.clientX - timeLine.getBoundingClientRect().left;
            offsetX = offsetX < 0 ? 0 : offsetX;

            let second = Math.floor(offsetX / timeLine.offsetWidth * totalTime);
            second = Math.min(second, totalTime);

            this.hoverTime = second + this.currentDateTime;
        },

        seek() {
            this.$emit('seek', this.hoverTime);
        },

        handleMouseScroll(e) {
            if (!this.inPanel) return;

            let wheelDelta = e.wheelDelta || e.detail; // > 0 向上滚动，< 0 向下滚动

            if (wheelDelta > 0) {
                this.width += 30;
            } else {
                this.width -= 30;
            }

            this.width = Math.max(this.width, 100);
            this.width = Math.min(this.width, 1000);
            // TODO: 更新scale和定位当前时间
            this.scale = scaleList[Math.floor((this.width - 100) / 200)];
            this.$nextTick(() => {
                this.$refs.scrollbar.update();
            });
        },
    }
};
</script>

<style>
.tume-player-time__panel {
    height: 80px;
}

.tume-player-time__tab {
    margin: 0 52px;
}

.tume-player-time__tab .divide-line {
    height: 8px;
    width: 1px;
    background: #ccc;
    margin: 6px 0 0 29px;
}

.tume-player-time__line {
    margin: 0 52px;
}

.tume-player-time__line .time-record {
    background: #1785e6;
    z-index: 1;
}

.tume-player-time__line .motion-detect {
    background: #24b354;
    z-index: 2;
}

.tume-player-time__line .tume-player-time__label {
    position: absolute;
    top: -20px;
    left: 0;
    padding: 0 8px;
    transform: translateX(-50%);
    border-radius: 9px;
    background: #333;
    opacity: 0.5;
    line-height: 18px;
    color: #fff;
    cursor: default;
}

.tume-player-time__line .tume-player-time__pointer {
    position: relative;
    z-index: 100;
    height: 32px;
    width: 1px;
    background: #333;
}
</style>
