<template>
    <tums-scrollbar v-show="show">
        <ul>
            <template v-if="presets.length">
                <preset-item
                    v-for="(preset, index) in presets"
                    :key="index"
                    :index="index"
                    :name="preset.alias"
                    :selected="preset.selected"
                    :status="preset.status"
                    :imgSrc="preset.imgSrc"
                    @select="selectPreset(index)"
                    @change="changeName($event, index)"
                    @apply="playPreset(index)"
                    @clear="deletePreset(index)">
                </preset-item>
            </template>
            <div v-else class="u-content-center">
                <p class="a-font7">暂未添加预置点</p>
            </div>
        </ul>
    </tums-scrollbar>
</template>

<script>
import PresetItem from './presetItem';
const {
    Utils
} = window['tums-components'].default;
const { decode } = Utils.util;
const OPERATEPRESET = {
    play: 0,
    delete: 1,
    add: 2,
    modify: 3
};
export default {
    name: 'presetPoint',
    components: {
        PresetItem
    },

    data() {
        return {
            presets: [],
            defaultPresets: [],
            defaultCount: 10,
            selectedIndex: -1
        };
    },

    props: {
        id: {
            type: String
        },
        show: {
            type: Boolean
        },
        config: {
            type: Object,
            default() {
                return {
                    platform: '',
                };
            },
        },
        currentPlayer: Object,
        authType: String
    },

    watch: {
        selectedIndex(newIndex, oldIndex) {
            let prev = this.presets[oldIndex];
            let next = this.presets[newIndex];
            prev && (prev.selected = false);
            next && (next.selected = true);
        },
        show(val) {
            if (val) {
                this.getAllPresets();
            }
        }

    },

    methods: {
        async getAllPresets() {
            if (this.authType === 'aksk') {
                let data = await this.currentPlayer.getAllPresets(this.id);
                if (data.error_code === 0) {
                    this.setAllPresetData(data);
                }
                return;
            }
            tumsAxios.post('/tums/ptz/v2/getAllPresets', {
                id: this.id
            }, res => {
                this.setAllPresetData(res);
            });
        },
        setAllPresetData(res) {
            let data = res.result;
            let presetIds = data.ids || [];
            let presetNames = data.presetNames;
            let imgSrcs = data.imgSrcs;
            let { proxyServer } = this.config;
            let domain = proxyServer.substring(0, proxyServer.length-1);
            this.presets = [];
            presetIds.forEach((presetId, i) => {
                this.presets.push({
                    presetId: presetId,
                    name: decode(presetNames[i]),
                    //  预置点实际显示名称，可修改
                    alias: decode(presetNames[i]),
                    //  预置点选中状态
                    selected: false,
                    status: false,
                    imgSrc: domain + imgSrcs[i]
                });
            });

            // 通知父组件，并更新到巡航扫描页面
            this.$emit('change', this.presets);
        },
        async operatePreset({
            presetId,
            operation,
            name
        }, callback) {
            let data = {
                id: this.id,
                presetId: presetId,
                operation: operation
            };
            if (this.authType === 'aksk') {
                if (name !== undefined) {
                    data.name = name;
                }
                let operateData = await this.currentPlayer.operatePreset(data);
                if (operateData.data.error_code === 0) {
                    callback();
                }
                return;
            }
            if (name !== undefined) {
                data.presetName = name;
            }
            tumsAxios.post(
                '/tums/ptz/v1/operatePreset',
                data,
                callback);
        },
        selectPreset(index) {
            this.selectedIndex = index;
        },
        changeName(data, index) {
            let presetId = this.presets[index].presetId;
            let operation = OPERATEPRESET.modify;

            this.operatePreset({
                presetId,
                operation,
                name: encodeURIComponent(data)
            }, () => {
                this.presets[index].alias = data;
                this.getAllPresets();
            });
        },
        // 运动到该预置点
        playPreset(index) {
            let presetId = this.presets[index].presetId;
            this.presets[index].status = !this.presets[index].status;
            if (this.presets[index].status) {
                let operation = OPERATEPRESET.play;
                this.operatePreset({
                    presetId,
                    operation
                }, () => {
                    this.presets[index].status = !this.presets[index].status;
                });
            }
        },
        // 删除
        deletePreset(index) {
            let presetId = this.presets[index].presetId;
            let operation = OPERATEPRESET.delete;

            this.operatePreset({
                presetId,
                operation
            }, () => {
                this.presets[index].alias = this.presets[index].name;
                this.getAllPresets();
            });
        }
    }
};
</script>
