<template>
    <div class="tums-player-playback tums-w-full">
        <div class="tums-player-content a-bs-b a-bb-base a-pos-r">
            <div class="tums-player a-h-full a-w-full" ref="tumsPlayer">
                <div v-if="loading" class="tums-icon-loading"></div>
            </div>
            <tums-button type="graytext" class="tums-player-close tums-icon-close" v-show="player" @click="destroy"></tums-button>
        </div>
        <div class="a-d-r a-p-h-lg a-p-v-sm a-bb-base">
            <tums-button v-if="isPlaying" type="graytext" icon="tums-icon-pause" @click="suspend(0)"></tums-button>
            <tums-button v-if="!isPlaying" type="graytext" icon="tums-icon-play" @click="suspend(1)"></tums-button>
            <tums-select size="mini" class="tums-w-84 a-m-l-lg" v-model="scale" :disabled="!isPlaying" @change="handleChangeScale">
                <tums-option v-for="item in scaleList" :key="item.value" :value="item.value" :label="item.label"></tums-option>
            </tums-select>
            <tums-button class="a-m-l-lg" size="mini" @click="handleSeek(currentTime + 30)">前进30s</tums-button>
            <tums-button class="a-m-l-lg" size="mini" @click="fullscreen">全屏</tums-button>
            <tums-button class="a-m-l-lg" size="mini" @click="screenshot">截图</tums-button>
            <tums-button
                class="a-m-l-lg"
                :type="isZoom ? 'text' : 'graytext'"
                icon="tums-icon-zoom-in"
                @click="zoom"
            ></tums-button>
            <tums-popover popper-class="u-operation-content-popper" width="260">
                <tums-button
                    :disabled="!player"
                    class="a-m-l-lg"
                    icon="tums-icon-volume"
                    type="graytext"
                    slot="reference"
                    @click="getVolume"
                ></tums-button>
                <div>
                    <tums-progress
                        style="width: 200px;display:inline-block;"
                        :percent="volume"
                        show-thunk
                        is-drag
                        clickable
                        hide-info
                        @on-percent-change="setVolume"
                    ></tums-progress>
                    <span class="a-m-l-sm a-font4">{{volume}}</span>
                </div>
            </tums-popover>
            <div class="a-f-r">
                <tums-checkbox-group v-model="eventList" @change="handleChangeEventType">
                    <tums-checkbox :label="1">定时</tums-checkbox>
                    <tums-checkbox :label="2">移动侦测</tums-checkbox>
                </tums-checkbox-group>
            </div>
        </div>
        <time-panel
            :video-list="videoListFiltered"
            :player="player"
            :current-date="currentDate"
            :current-time="currentTime"
            @seek="handleSeek"
        ></time-panel>
    </div>
</template>

<script>
import TumsPlayer from '@/index';
import { getPreviewUrl } from '../api/getPreviewUrl';
import { searchVideo, getEventCalendar, getEventListByPage } from '../api/playback';
import { passthrough } from '../api/device';
import TimePanel from './TimePanel';

const bitmap = (val = 0) => {
    let res = 1;
    while (val >= 2) {
        res++;
        val /= 2;
    }
    return res;
};

export default {
    name: 'Playback',
    components: {
        TimePanel,
    },
    props: {
        config: {
            type: Object,
            default() {
                return {
                    platform: '',
                };
            },
        },
        currentDevice: Object,
        autoplay: {
            type: Boolean,
            default: true,
        },
        storageType: String,
        decoderType: String
    },
    data() {
        let scaleList = [];
        let scale = 0.0625;
        while (scale < 32) {
            scaleList.push({
                label: scale < 1 ? `1/${1 / scale}` : scale,
                value: scale,
            });
            scale *= 2;
        }
        return {
            player: null,
            loading: false,
            isPlaying: false,
            resolution: 1,
            scale: 1,
            scaleList,
            videoList: [],
            startTime: 0,
            currentDate: new Date(),
            currentTime: new Date(new Date().toLocaleDateString()).getTime() / 1000,
            tickTimer: 0,
            isZoom: false,
            eventList: [1, 2],
            userId: 0,
            searchTimer: 0,
            volume: 0,
        };
    },
    computed: {
        isCloud() {
            return this.config.platform === 'cloud';
        },
        videoListFiltered() {
            return this.videoList.filter(item => {
                return this.eventList.find(event => {
                    if (this.currentDevice.channel) {
                        return item.videoType.some(val => val === event);
                    } else {
                        if (event === 2) {
                            return item.videoType.some(val => val >= event);
                        } else {
                            return item.videoType.some(val => val === event);
                        }
                    }
                });
            });
        },
        eventTypes() {
            if (this.currentDevice.channel) {
                return [this.eventList.reduce((pre, cur) => pre + cur, 0), 0];
            } else {
                let eventTypes = this.videoList.map(item => {
                    return bitmap(item.videoType[0]);
                });
                eventTypes = Array.from(new Set(eventTypes));
                eventTypes = eventTypes.sort();
                if (!this.eventList.includes(1)) {
                    eventTypes = eventTypes.filter(item => item !== 1);
                }
                if (!this.eventList.includes(2)) {
                    eventTypes = eventTypes.filter(item => item < 2);
                }
                return eventTypes;
            }
        }
    },
    beforeDestroy() {
        this.destroy();
    },
    methods: {
        suspend(status) {
            let { player } = this;
            if (!player) return;

            if (status) {
                if (player.isInit) {
                    this.loading = true;
                    player.start();
                } else {
                    player.play();
                }
            } else {
                player.pause();
            }
            this.isPlaying = !!status;
        },

        async destroy() {
            let { player } = this;
            if (!player) return;

            await player.destroy();
            this.player = null;
            this.loading = false;
            this.isPlaying = false;
            this.videoList = [];
            this.isZoom = false;
            this.scale = 1;
            clearInterval(this.tickTimer);
            clearTimeout(this.searchTimer);
        },

        getResolution() {
            let { devId, parentId, channel } = this.currentDevice;
            let param = {
                method: 'get',
                video: {
                    name: ['main', 'minor']
                }
            };
            if (channel) {
                const chName = 'chn' + channel + '_';
                param = {
                    method: 'get',
                    video: {
                        name: [chName + 'main', chName + 'minor'],
                        table: ['main_res', 'minor_res']
                    }
                };
            }

            passthrough({
                devId: parentId || devId,
                param
            }).then(res => {
                console.log(res.video);
            });
        },

        async start() {
            if (this.player) return;

            this.getResolution();

            if (this.autoplay) {
                this.loading = true;
            }

            // requestRelayAccess(this.config.proxyServer, 'ASWMLB=xxx', '/sd/');
            let { proxyServer, cloudDomain } = this.config;
            let domain = cloudDomain || proxyServer.split('/')[2];
            let res;

            if (this.isCloud && this.storageType === 'relay') {
                let storageLocation = this.storageType === 'cloudStorage' ? 1 : 0;
                res = await getPreviewUrl(this.currentDevice.devId, this.config.platform, this.resolution, domain, 'sdvod', storageLocation);
            }

            if (res === '网络异常' || (res.error_code && (res.error_code !== 0))) {
                this.loading = false;
                if (res === '网络异常') {
                    TumsMessage.error(res);
                }
                return;
            }

            let playerOptions = {
                autoplay: this.autoplay,
                resolution: this.resolution === 1 ? 'HD' : 'VGA',
                cover: false,
                streamType: 'sdvod',
                decoderType: this.decoderType
            };

            if (this.isCloud) {
                playerOptions = Object.assign(playerOptions, {
                    url: res.url || res.sdkStreamUrl,
                    type: this.storageType,
                    scale: this.scale,
                    startTime: this.videoList[0].startTime * 1000,
                    userId: this.userId,
                    eventType: this.eventTypes,
                });
                // document.cookie = `${res.cookie};path=/;domain=.tp-link.com.cn`;
            } else {
                playerOptions = Object.assign(playerOptions, {
                    url: res.url,
                    socket: res.wssUrl,
                    type: 'rtsp',
                });
            }

            this.player = new TumsPlayer(this.$refs.tumsPlayer, playerOptions);
            this.addEventHandler();
            this.tick();
        },

        manualPlay(playerOption) {
            if (!playerOption.url) return;

            if (playerOption.autoplay) {
                this.loading = true;
            }

            if (!this.player) {
                this.player = new TumsPlayer(this.$refs.tumsPlayer, playerOption);
                this.addEventHandler(1);
            } else {
                this.player.init(playerOption);
            }
        },

        addEventHandler() {
            let ref = 'tumsPlayer';
            this.player.on('play', () => {
                this.$emit('event-log', `${ref} trigger event: play`);
                this.isPlaying = true;
            });
            this.player.on('error', ({ detail = {} }) => {
                // if (this.loading) {
                //     this.destroy();
                // }
                this.loading = false;
                this.$emit('event-log', `${ref} trigger event: error\ncode: ${detail.code}: ${detail.message}`);
            });
            this.player.on('playing', () => {
                this.loading = false;
                this.$emit('event-log', `${ref} trigger event: playing`);
            });
            this.player.on('zoom', ({ detail = 1 }) => {
                this.$emit('event-log', `${ref} trigger event: zoom, level = ${detail.scale}`);
            });

            ['ready', 'pause', 'stream', 'disconnected', 'ended'].forEach((event) => {
                this.player.on(event, () => {
                    this.$emit('event-log', `${ref} trigger event: ${event}`);
                });
            });
        },

        fullscreen() {
            if (!this.player) return;
            this.player.fullscreen();
        },

        screenshot() {
            if (!this.player) return;
            this.player.screenshot();
        },

        searchVideo(date) {
            this.destroy();
            this.startTime = date.getTime();
            this.currentDate = new Date(date.toLocaleDateString());
            let isInit = true;
            clearTimeout(this.searchTimer);

            if (this.storageType === 'relay') {
                let searchDay = date.format('yyyyMMdd');
                let startIdx = 0;

                const fetch = () => {
                    searchVideo({
                        videoDevId: this.currentDevice.devId,
                        searchDay,
                        startIdx,
                        endIdx: startIdx + 99,
                    }).then((res) => {
                        let { videos = [], userId = 0 } = res;
                        this.videoList.push(...videos);
                        if (videos.length === 100) {
                            startIdx += 100;
                            this.searchTimer = setTimeout(fetch, 50);
                        } else {
                            TumsMessage.destroy();
                            if (this.videoList.length) {
                                this.userId = userId;
                                this.start();
                            } else {
                                TumsMessage.info('无录像');
                            }
                        }
                    });
                };

                TumsMessage.loading({
                    duration: 0,
                    content: '正在查询...',
                });
                fetch();
            } else if (this.storageType === 'cloudStorage') {
                let startTimestamp = this.startTime;
                let endTimestmp = startTimestamp + 24 * 60 * 60 * 1000;
                const fetch = () => {
                    getEventListByPage({
                        devId: this.currentDevice.devId,
                        startTimestamp,
                        endTimestmp,
                        limit: 100
                    }).then(res => {
                        let { nextTimestamp, eventList = [] } = res;
                        if (eventList.length === 100) {
                            startTimestamp = nextTimestamp;
                            fetch();
                        }

                        if (!isInit) return;
                        isInit = false;
                        TumsMessage.destroy();
                        if (this.videoList.length) {
                            this.start();
                        } else {
                            TumsMessage.info('无录像');
                        }
                    });
                };

                fetch();
            }
        },

        handleSeek(time) {
            let { player } = this;
            if (!player || player.isError) return;

            player.setPlaybackConfig({
                startTime: time * 1000,
            });
        },

        handleChangeScale(scale) {
            let { player } = this;
            if (!player || player.isError) return;

            player.setPlaybackConfig({
                scale,
            });
        },

        handleChangeEventType() {
            let { player } = this;
            if (!player || player.isError) return;

            player.setPlaybackConfig({
                eventType: this.eventTypes,
            });
        },

        tick() {
            let { player } = this;
            if (!player) return;

            clearInterval(this.tickTimer);
            this.tickTimer = setInterval(() => {
                this.currentTime = player.getPlaybackTime() / 1000;
            }, 500);
        },

        zoom() {
            let { player } = this;
            if (!player) return;

            this.isZoom = !this.isZoom;
            this.player.toggleZoomState(+this.isZoom);
        },

        setVolume(val) {
            let { player } = this;
            if (!player) return;

            this.volume = val;
            player.setVolume(val);
        },

        getVolume() {
            let { player } = this;
            if (!player) return;

            this.volume = player.getVolume();
        },
    },
};
</script>

<style>
.tums-player-playback .tums-player-content {
    height: calc(100% - 130px);
}
</style>
