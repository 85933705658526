import { uuid } from './login';

function addPreviewChn(devId, resolution = 0) {
    let api = '/tums/preview/v1/addPreviewChn';
    let param = {
        id: devId,
        streamType: resolution
    };

    return new Promise((resolve, reject) => {
        tumsAxios.post(api, param, ({ result = {} }) => {
            resolve(result.sessionId);
        }, (errData, errMsg) => {
            TumsMessage.error(errMsg);
            reject(errData);
        });
    });
}

function getBizToken() {
    return new Promise((resolve)=> {
        if (window.bizToken) {
            resolve(window.bizToken);
        } else {
            return tumsAxios.chainPostProxy(
                '/tums/account/v1/getBizToken',
                {
                    serviceId: 'tums.relays'
                },
                (data, err) => {
                    if(err) {
                        resolve(err);
                    }
                }
            ).then((data) => {
                window.bizToken = data;
                resolve(data);
            });
        }
    });
}

export async function getPreviewUrl(devId, platform, resolution, cloudDomain, streamType, storageLocation) {
    let sessionId, bizToken, param;
    let api = '/tums/preview/v1/getPreviewUrl';

    if (platform === 'local') {
        resolution = resolution > 0 ? 0 : 1;
        sessionId = await addPreviewChn(devId, resolution).catch(errData => {
            throw new Error(errData);
        });
        param = {
            id: devId,
            sessionId
        };
    } else if (platform === 'cloud') {
        // api = '/tums/relayPreview/v1/getPreviewUrl';
        // 请求商云预览回放URL
        api = '/tums/relay/v1/sdk/requestStreamUrl';
        // bizToken = await getBizToken().then(data=> {
        //     if (data === '网络异常') {
        //         return '网络异常';
        //     } else {
        //         return data.result;
        //     }
        // });
        // if (bizToken === '网络异常') {
        //     return '网络异常';
        // }

        // param = {
        //     clientType: 'firefox 94.0',
        //     clientUUID: uuid(),
        //     devInfoId: devId,
        //     resolution: resolution + 1,
        //     isSdkClient: true,
        //     token: bizToken
        // };
        param = {
            clientType: 'browser',
            clientUUID: uuid(),
            devInfoId: devId,
            resolution: resolution + 1,
            streamType: streamType,
            cloudDomain,
            windowUUID: uuid(),
            storageLocation
        };
    } else {
        return Promise.reject();
    }

    return new Promise(resolve => {
        const fetch = (api, param) => {
            tumsAxios.postProxy(api, param, (res) => {
                let result = res.result || {};
                result.token = bizToken;
                resolve(result);
            }, (res, error) => {
                if (res.error_code === -80703) {
                    setTimeout(() => {
                        if (platform === 'cloud' && !param.sessionId) {
                            let { result = {} } = res;
                            let { sessionId = '' } = result;
                            api = '/tums/relay/v1/getUrlAgain';
                            param = {
                                sessionId
                            };
                        }
                        fetch(api, param);
                    }, 1000);
                } else {
                    TumsMessage.error(error);
                    resolve(res);
                }
            });
        };

        fetch(api, param);
    });
}
