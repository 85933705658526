function SMediaError(data) {
    if(data instanceof SMediaError) {
        return data;
    }

    if (typeof data === 'number') {
        this.code = data;
    }  else if (typeof data === 'string') {
        this.code = SMediaError.errorTypes.COMMON_ERR_CUSTOM;
        this.message = data;
    }

    if (!this.message) {
        this.message = SMediaError.defaultMessages[this.code] || '';
    }
}

SMediaError.prototype.code = 0;
SMediaError.prototype.message = '';

SMediaError.errorTypes = {
    // media error
    // error code
    'MEDIA_ERR_CUSTOM': 1000,
    'MEDIA_ERR_ABORTED': 1001,
    'MEDIA_ERR_NETWORK': 1002,
    'MEDIA_ERR_DECODE': 1003,
    'MEDIA_ERR_SRC_NOT_SUPPORTED': 1004,
    'MEDIA_ERR_ENCRYPTED': 1005,
    'MEDIA_ERR_TRANSPORT': 1006,
    'MEDIA_ERR_CONNECT': 1007,
    'MEDIA_ERR_DECODER': 1008,

    // cloud error
    'CLOUD_ERR_SERVER': 1101,
    'CLOUD_ERR_TOKEN': 1102,
    'CLOUD_ERR_DEVID': 1104,
    'CLOUD_ERR_BANDWIDTH': 1105,
    'CLOUD_ERR_PARAM': 1106,
    'CLOUD_ERR_DEV_RELAY': 1107,
    'CLOUD_ERR_STREAM': 1108,
    'CLOUD_ERR_VERSION': 1109,
    'CLOUD_ERR_STREAM_LIMIT': 1110,
    'CLOUD_ERR_FREE': 1111,
    'CLOUD_ERR_WINDOW_LIMIT': 1112,
    'CLOUD_ERR_CLIENT_LIMIT': 1113,
    'CLOUD_ERR_SHARE_FINISH': 1114,
    'CLOUD_ERR_PERMISSION': 1115,
    'CLOUD_ERR_ENCRYPT_CHANGED': 1120,
    'CLOUD_ERR_TALK_BUSY': 1121,
    'CLOUD_ERR_UNBIND': 1122,

    // audio error
    'AUDIO_ERR_AUDIO_ENABLE': 1201,
    'AUDIO_ERR_PERMISSION_DENIED': 1202,
    'AUDIO_ERR_NOT_SUPPORTED_ERROR': 1203,
    'AUDIO_ERR_MANDATORY_UNSATISFIED_ERROR': 1204,
    'AUDIO_ERR_NOT_FOUND_ERR': 1205,
    'AUDIO_ERR_AUDIO_NOSUPPORTED': 1206,
    'AUDIO_ERR_AUDIO_BUSY': 1207,
    'AUDIO_ERR_NOT_ALLOW' : 1208,

    // device error
    'DEVICE_ERR_CHN_OFFLINE': 1301,
    'DEVICE_ERR_CHN_INVALID': 1302,
    'DEVICE_ERR_PWD_CHANGED': 1303,

    // common error
    'COMMON_ERR_CUSTOM': 1401,
    'COMMON_ERR_TIME_OUT': 1402,
    'COMMON_ERR_NETWORK': 1403,
    'COMMON_ERR_INTERNAL': 1404,
    'COMMON_ERR_SESSION': 1405,

    // local error
    'LOCAL_ERR_CONFIG_PLAYBACK': 1501,

    // warning code
    'VIDEO_RES_WARNING': 2001,
    'NETWORK_UNSTABLE_WARNING': 2002,
    'DECODE_WARNING': 2003
};

SMediaError.defaultMessages = {
    // media error
    1001: 'The fetching of the associated resource was aborted by the user\'s request.',
    1002: 'Some kind of network error occurred which prevented the media from being successfully fetched, despite having previously been available.',
    1003: 'Despite having previously been determined to be usable, an error occurred while trying to decode the media resource, resulting in an error.',
    1004: 'The associated resource or media provider object (such as a MediaStream) has been found to be unsuitable.',
    1005: 'The media is encrypted and we do not have the keys to decrypt it.',
    1006: 'Transport error',
    1007: 'Websocket connection to server failed',
    1008: 'Unsupported decoder type',

    // cloud error
    1101: 'Some error occurred in cloud server',
    1102: 'Token overdued or non-existent',
    1104: 'Invalid device id',
    1105: 'Bandwidth limitted',
    1106: 'The parameter of request has error',
    1107: 'Device cannot connect to relay server',
    1108: 'Fail to get stream',
    1109: 'Unsupported relay protocol version',
    1110: 'The request of stream has limitted',
    1111: 'Had watch for a long time',
    1112: 'Too many vip windows',
    1113: 'Too many vip clients',
    1114: 'Share time has finished',
    1115: 'Permission deny',
    1120: 'The status of media encrypt has been changed',
    1121: 'Device is on calling',
    1123: 'Device has been unbind',

    // audio error
    1201: 'Device does not support voice intercom',
    1202: 'Permission denied error',
    1203: 'Not supported error',
    1204: 'Mandatory unsatisfied error',
    1205: 'Requested device not found',
    1206: 'Call mode not supported',
    1207: 'The audio device is busy and cannot initiate a call',
    1208: 'Permission denied by system',

    // device error
    1301: 'Device offline',
    1302: 'Device invalid',
    1303: 'The password of the device has been changed',

    // common error
    1401: 'Unknown error',
    1402: 'Time out',
    1403: 'Network error',
    1404: 'Server internal error',
    1405: 'Session overdued or non-existent',

    // local error
    1501: 'Config error',

    // warning code
    2001: 'Resolution too high',
    2002: 'Network unstable',
    2003: 'Decode performance insufficient'
};

Object.keys(SMediaError.errorTypes).forEach(type => {
    SMediaError[type] = SMediaError.errorTypes[type];
    SMediaError.prototype[type] = SMediaError.errorTypes[type];
});

export default SMediaError;
