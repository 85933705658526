export function getEnterpriseList() {
    let data = sessionStorage.getItem('enterpriseList') || '';
    if (data) {
        return Promise.resolve(JSON.parse(data));
    }
    return tumsAxios.chainPostProxy('/tums/resource/v2/getEnterpriseList')
        .then(({ result = [] }) => {
            if (!result.length) TumsMessage.text('无企业');
            sessionStorage.setItem('enterpriseList', JSON.stringify(result));
            return result;
        });
}