// 镜像配置
export const flipTypes = [
    {
        value: 'off',
        label: '关闭'
    },
    {
        value: 'left_and_right',
        label: '左右'
    },
    {
        value: 'up_and_down',
        label: '上下'
    },
    {
        value: 'center',
        label: '中心'
    }
];

// 旋转配置(只有走廊模式的设备支持)
export const rotateOptions = [
    {
        value: 'off',
        label: '关闭'
    },
    {
        value: 'anticlockwise_180',
        label: '逆时针旋转180°'
    },
    {
        value: 'clockwise_90',
        label: '顺时针旋转90°'
    },
    {
        value: 'anticlockwise_90',
        label: '逆时针旋转90°'
    }
];
