import Crypto from 'crypto-js';
import { randomStr } from './util';

export function getAuthorization({
    appKey,
    appSecret,
    terminalId,
    method = 'POST',
    uri,
    payload = 'UNSIGNED-PAYLOAD' // payload为空时赋值为'UNSIGNED-PAYLOAD'
} = {}) {
    let timestamp = parseInt(Date.now() / 1000); // 时间戳，单位s
    let credentialScope = `${method} ${uri} tp-link_request`; // 拼接请求
    let hashedCanonicalRequest = Crypto.SHA256(payload).toString(); // 使用SHA256计算payload签名
    let stringToSign = `HmacSHA256\n${timestamp}\n${credentialScope}\n${hashedCanonicalRequest}`; // 构造待签名字符串
    let secretDate = Crypto.HmacSHA256(timestamp + '', appSecret); // 计算时间戳签名
    let secretService = Crypto.HmacSHA256(uri, secretDate); // path签名串
    let secretSigning = Crypto.HmacSHA256('tp-link', secretService); // tp-link签名
    let finalSign = Crypto.HmacSHA256(stringToSign, secretSigning).toString(); // 最终签名
    let nonce = randomStr(32); // 32位随机字符
    let fignalSignString = `Timestamp=${timestamp},Nonce=${nonce},AccessKey=${appKey},Signature=${finalSign},TerminalId=${terminalId}`;
    return fignalSignString;
}