<template>
    <aside class="a-bg-base a-h-full tums-w-240 a-border-darker" v-show="showPanel">
        <div class="tums-h-40 a-bb-base a-d-f a-jc-sb a-ai-c a-p-h-md a-m-b-lg">
            <span>云台</span>
            <tums-button-group>
                <!-- <tums-button type="text">
                    <i class="tums-icon-refresh"></i>
                </tums-button> -->
                <tums-button type="text" @click="closePtzPanel">
                    <i class="tums-icon-close"></i>
                </tums-button>
            </tums-button-group>
        </div>
        <!-- 调焦 转动 -->
        <div class="a-p-h-xxl a-d-f a-jc-sb a-ai-c">
            <div class="a-d-f a-fd-r a-fw-w a-jc-sa a-ac-sa tums-h-108 tums-w-108">
                <tums-button
                    class="a-d-f a-ai-c a-jc-c"
                    plain
                    width="32"
                    v-for="direction in directions"
                    :key="direction.name"
                    :disabled="direction.show"
                    @mousedown.native="onMouseDown(direction.name)"
                    @mouseup.native="onMouseUp(direction.name)"
                    @click="onMouseClick(direction.name)">
                    <i :class="direction.icon"></i>
                </tums-button>
            </div>
            <div class="a-d-f a-ai-c a-jc-sa a-fd-c a-border-darker tums-w-32 a-bg-base a-p-v-xs">
                <tums-button
                    type="graytext"
                    icon="tums-icon-plus"
                    title="调焦+"
                    size="mini"
                    :disabled="zoomCapabilityDisabled"
                    @mousedown.native="onMouseDown(DIRECTIONS.ZOOM_IN)"
                    @mouseup.native="onMouseUp(DIRECTIONS.ZOOM_IN)">
                </tums-button>
                <span class="u-font7 a-p-v-sm">调焦</span>
                <tums-button
                    type="graytext"
                    icon="tums-icon-minus"
                    title="调焦-"
                    size="mini"
                    :disabled="zoomCapabilityDisabled"
                    @mousedown.native="onMouseDown(DIRECTIONS.ZOOM_OUT)"
                    @mouseup.native="onMouseUp(DIRECTIONS.ZOOM_OUT)">
                </tums-button>
            </div>
        </div>
        <!-- 操作 -->
        <div class="tums-p-h-16 a-m-t-lg a-m-b-lg">
            <tums-button width="208" class="a-m-b-sm" icon="tums-icon-plus"  :disabled="presetUnSupported" @click="showAddPreset">预置点</tums-button>
            <!-- <tums-button-group>
                <tums-button
                    width="175"
                    @click="startParkConfig"
                    :disabled="parkSupported"
                >{{`${isWatching? '结束守望': '开启守望'}`}}
                </tums-button>
                <tums-button width="32" @click="startShowWatchConfig" :disabled="parkSupported">
                    <i class="tums-icon-ptz-setup"></i>
                </tums-button>
            </tums-button-group> -->
        </div>
        <div>
            <tums-tabs v-model="tab.name" class="tums-tabs--card">
                <p class="u-font7 a-p-h-md a-p-b-md" v-show="tab.name === tab.TABS.PRESET">使用上方云台工具调整监控方位，点击“+预置点”即可设为预置点</p>
                <tums-tab-pane
                        label="预置点"
                        :disabled="presetUnSupported"
                        :name='tab.TABS.PRESET'
                >
                </tums-tab-pane>
                <!-- <tums-tab-pane
                        label="巡航扫描"
                        :disabled="tourSupportedDisabled"
                        :name='tab.TABS.CRUISE'
                >
                </tums-tab-pane> -->
            </tums-tabs>
        </div>
        <!-- 预置点 -->
        <preset-point
            ref="presetPoint"
            :style="{height: 'calc(100% - 403px)'}"
            :id="id"
            :show="showPanel && tab.name === tab.TABS.PRESET"
            :config="config"
            @change="updatePreset"
            :currentPlayer="currentPlayer"
            :authType="authType"
        >
        </preset-point>
        <cruise-scan
            :style="{height: 'calc(100% - 352px)'}"
            :id="id"
            :show="showPanel && tab.name === tab.TABS.CRUISE"
            :presets="presets"
            :tourStayTimeRange="tourStayTimeRange"
        >
        </cruise-scan>
        <!-- 添加预置点 -->
        <tums-dialog
            title="添加预置点"
            v-model="showAddPresetDig"
        >
            <tums-form :model="addPresetData" :rules="addRules" ref="addPresetData">
                <tums-form-item label="预置点名称" label-width="84px" prop="name">
                    <tums-input v-model="addPresetData.name" maxLength="32"></tums-input>
                </tums-form-item>
            </tums-form>
            <template slot="footer">
                <tums-button @click="addPresetName" type="primary" :loading="addPresetLoading">保存</tums-button>
                <tums-button @click="cancelAddPreset">取消</tums-button>
            </template>
        </tums-dialog>
        <!-- 开启守望 -->
        <tums-dialog
            title="守望设置"
            width="480"
            v-model="showWatch"
        >
            <template slot="footer">
                <tums-button @click="cancelWatch">取消</tums-button>
                <tums-button @click="saveWatch" type="primary">保存</tums-button>
            </template>
            <tums-form
                :model="watchData"
                ref="watchData"
                label-width="84px"
                class="watch-set"
                :rules="watchDataRules"
            >
                <div class="a-bg-light-blue2 a-border-primary watch-set-top-info a-m-b-sm a-c-primary">
                    <p class="a-d-f a-jc-fs a-ai-c a-p-l-sm">
                        <i class="tums-icon-info"></i>
                        <span class="a-p-l-xs">
                            守望：云台在无人操作时，默认执行某一动作。
                        </span>
                    </p>
                    <p class="a-p-l-xl a-p-r-sm">
                        比如守望模式是预置点，云台可以被操作者临时调整到别的监控位置，在操作 者停止操作后，经过“守望等待时间”，云台会自动回归到原先设定的预置点。
                    </p>
                </div>
                <tums-form-item label="守望模式">
                    <tums-select v-model="watchData.mode" class="tums-w-280" @change="changeMode">
                        <tums-option
                            v-for="modeItem in actionModeStatus"
                            :key="modeItem.value"
                            :value="modeItem.value"
                            :label="modeItem.label"
                        ></tums-option>
                    </tums-select>
                </tums-form-item>
                <tums-form-item label="守望预置点">
                    <tums-select
                        v-model="watchData.actionId"
                        class="tums-w-280"
                        prop="actionId"
                        :disabled="watchData.actionId === -1"
                    >
                        <tums-option
                            v-for="presetName in presetNameStatus"
                            :key="presetName.value"
                            :value="presetName.value"
                            :label="presetName.label"
                        ></tums-option>
                    </tums-select>
                </tums-form-item>
                <tums-form-item label="守望时间" prop="parkTime">
                    <tums-input v-model="watchData.parkTime" suffix-icon="秒" class="tums-w-280"></tums-input>
                    <span class="u-font7 a-p-l-sm">(5~72)</span>
                </tums-form-item>

            </tums-form>
        </tums-dialog>
    </aside>
</template>

<script>
import PresetPoint from './preset';
import CruiseScan from './cruise';
import  PTZ_CONFIG from './ptz.js';
const {
    Utils
} = window['tums-components'].default;
const { decode } = Utils.util;
const direct = PTZ_CONFIG.value.directions;
const DIRECTIONS = {
    TOP: direct.top,
    LEFT: direct.left,
    RIGHT: direct.right,
    BOTTOM: direct.bottom,
    TOP_LEFT: direct.topLeft,
    TOP_RIGHT: direct.topRight,
    HORIZONTAL: direct.horizontal,
    BOTTOM_LEFT: direct.bottomLeft,
    BOTTOM_RIGHT: direct.bottomRight,
    ZOOM_IN: direct.zoomIn,
    ZOOM_OUT: direct.zoomOut
};
const MOTION = {
    start: 1,
    stop: 0
};

export default {
    name: 'ptzPanel',
    components: {
        PresetPoint,
        CruiseScan
    },
    props: {
        // 设备id
        id: {
            type: String
        },
        showPanel: {
            type: Boolean
        },
        config: {
            type: Object,
            default() {
                return {
                    platform: '',
                };
            },
        },
        currentPlayer: Object,
        authType: String
    },

    data() {
        const actionModeStatus = [
            {
                value: 'preset',
                label: 'preset'
            },
            {
                value: 'tour',
                label: 'tour'
            }
        ];
        const directions = [{
            name: DIRECTIONS.TOP_LEFT,
            icon: 'tums-icon-caret-lt',
            show: true
        }, {
            name: DIRECTIONS.TOP,
            icon: 'tums-icon-caret-top',
            show: true
        }, {
            name: DIRECTIONS.TOP_RIGHT,
            icon: 'tums-icon-caret-rt',
            show: true
        }, {
            name: DIRECTIONS.LEFT,
            icon: 'tums-icon-caret-left',
            show: true
        }, {
            name: DIRECTIONS.HORIZONTAL,
            icon: 'tums-icon-ptz-horirotation',
            show: true
        }, {
            name: DIRECTIONS.RIGHT,
            icon: 'tums-icon-caret-right',
            show: true
        }, {
            name: DIRECTIONS.BOTTOM_LEFT,
            icon: 'tums-icon-caret-lb',
            show: true
        }, {
            name: DIRECTIONS.BOTTOM,
            icon: 'tums-icon-caret-bottom',
            show: true
        }, {
            name: DIRECTIONS.BOTTOM_RIGHT,
            icon: 'tums-icon-caret-rb',
            show: true
        }];
        return {
            DIRECTIONS,
            PTZ_CONFIG: Object.freeze(PTZ_CONFIG),
            actionModeStatus,
            presetNameStatus: [],
            directions,
            speed: {
                gearLevel: 50,
                gearCount: 6,
                gearWidth: 6,
                gearDescribe: Array.apply(null, {
                    length: 7
                }).map((_, i) => i + 1),
                gearStyle: {
                    fontSize: '12px',
                    letterSpacing: '25px',
                    overflow: 'hidden'
                }
            },
            tab: {
                TABS: {
                    PRESET: 'preset',
                    CRUISE: 'cruise'
                },
                name: ''
            },
            isHorizontalRotating: false,
            // 预置点信息，由预置点页面进行更新
            presets: [],
            addPresetData: {
                name: ''
            },
            addPresetLoading: false,
            showAddPresetDig: false,
            addRules: {
                name: [{
                    message: '请输入预置点名称',
                    trigger: 'blur',
                    required: true
                }]
            },
            // 控制云台
            zoomCapabilityDisabled: true,
            tourSupportedDisabled: true,
            panRangeDisabled: true,
            tiltRangeDisabled: true,
            presetUnSupported: true,
            autoScan: true,
            panTiltRotate: true,
            // 是否支持守望
            parkSupported: true,
            // 开启守望
            showWatch: false,
            watchData: {
                mode: 'preset',
                parkTime: '',
                enabled: 0,
                actionId: ''
            },
            watchDataRules: {
                parkTime: [
                    {
                        validator: (rule, value, cb) => {
                            if (Number(value) >= 5 && Number(value) <= 72) {
                                cb();
                            } else {
                                let errMsg ="请输入正确的守望时间";
                                cb(errMsg);
                            }
                        }
                    }
                ],
                actionId: [{
                    message: '请选择守望预置点',
                    trigger: ['blur', 'change'],
                    required: true
                }]
            },
            routes: [],
            // 是否开始守望
            isWatching: false,
            // 添加/编辑巡航扫描
            showCruiseRoute: false,
            cruiseRouteType: '',
            tourStayTimeRange: []
        };
    },

    watch: {
        isHorizontalRotating(rotating) {
            let motion = MOTION;
            let state = rotating ? motion.start : motion.stop;
            if (this.authType === 'aksk') {
                this.resetPtzDevicePosition();
                return;
            }
            this._setMotionParams(DIRECTIONS.HORIZONTAL, state);
        },
        showPanel(val) {
            if (!val) {
                // 重置数据
                this.tab.name = '';
                this.isHorizontalRotating = false;
                this.speed.gearLevel = 50;
                this.zoomCapabilityDisabled = true;
                this.tourSupportedDisabled = true;
                this.presetUnSupported = true;
                this.panRangeDisabled = true;
                this.tiltRangeDisabled = true;
                this.parkSupported = true;
                this.autoScan = true;
                this.panTiltRotate = true;
                this.ptzDeviceResetSupport = true;
                this.tourStayTimeRange = [];
            } else {
                this.getDeviceType();
            }
        }

    },

    methods: {
        resetPtzDevicePosition() {
            if (this.authType === 'aksk') {
                this.currentPlayer.resetPtzDevicePosition(this.id);
            }
        },
        // 获取类型
        async getDeviceType() {
            if (this.authType === 'aksk') {
                let data = await this.currentPlayer.getDeviceType(this.id);
                this.setPtzData(data);
                return;
            }
            let deviceData = (await this.getEquipmentInfoByDevId(this.id)).result || {};
            if (deviceData.channel > 0) {
                let param = {
                    devId: deviceData.parentId,
                    param:
                    {
                        method: 'do',
                        ptz: {
                            get_ptz_capability: { chn_id: deviceData.channel }
                        }
                    }
                };
                this.passthough('nvr', param, deviceData.channel);
            } else {
                let param = {
                    devId: deviceData.devId,
                    param:
                    {
                        method: 'get',
                        ptz: {
                            name: 'capability'
                        }
                    }
                };
                this.passthough('ipc', param);
            }
        },
        getEquipmentInfoByDevId(devId) {
            return new Promise(resolve => {
                tumsAxios.post(
                    '/tums/deviceManager/v1/getDeviceDetails', { devId: devId },
                    (response) => {
                        resolve(response);
                    }
                );
            });
        },
        passthough(type, param, nvrChannel) {
            tumsAxios.post('/tums/devConfig/v1/passthrough', param, (data) => {
                if (type === 'ipc') {
                    let capability = data.ptz.capability;
                    // 变焦 zoom capability.position_zoom_range true为支持
                    this.zoomCapabilityDisabled = this.isDisabled(capability, 'position_zoom_range');
                    // position3D_unsupported  该字段存在且取值为1：不支持/ptz/position3D服务和镜头居中服务功能；
                    this.position3DUnsupported = capability.position3D_unsupported === '1';
                    // 花样扫描 pattern_supported 缺少该字段或为0：不支持 1：支持
                    this.patternSupported = !(capability.pattern_supported === '1');
                    // tour_supported 是否支持巡航扫描 缺少该字段或为0：不支持 1：支持
                    this.tourSupportedDisabled = !(capability.tour_supported === '1');
                    // preset_supported 预置点缺少该字段或为0：不支持 1：支持
                    this.presetUnSupported = !(capability.preset_supported === '1');
                    if (capability.preset_supported === '1') {
                        this.tab.name = 'preset';
                    } else {
                        this.tab.name = '';
                    }
                    // pan_tilt_rotate_sync_unsupported 水平移动 设置为["0.000000", "0.000000"]时，表示不支持水平方向移动。 支持同时转动的云台才支持斜向运动。
                    this.panRangeDisabled = this.isDisabled(capability, 'position_pan_range');
                    // position_tilt_range都设置为["0.000000", "0.000000"]时，表示不支持云台功能
                    this.parkSupported = !(capability.park_supported === '1');
                    this.tiltRangeDisabled = this.isDisabled(capability, 'position_tilt_range');
                    this.panTiltRotate = capability.pan_tilt_rotate_sync_unsupported === '1';
                    // this.autoScan = !(capability.scan_supported === '1' && capability.scan_support_type_list.has('auto_scan'));
                    this.tourStayTimeRange = capability.tour_stay_time_range.map(item => item / 1000);
                } else {
                    let chnPtz = `chn${nvrChannel}_ptz_capability`;
                    let capability = data[chnPtz].ptz_capability;
                    this.zoomCapabilityDisabled = capability.z_max === '0.000000' && capability.z_min === '0.000000';
                    this.patternSupported = !(capability.pattern_supported === '1');
                    this.tourSupportedDisabled = !(capability.tour_supported === '1');
                    this.presetUnSupported = !(capability.preset_supported === '1');
                    if (capability.preset_supported === '1') {
                        this.tab.name = 'preset';
                    } else {
                        this.tab.name = '';
                    }
                    this.panRangeDisabled = capability.x_max === '0.000000' && capability.x_min === '0.000000';
                    this.tiltRangeDisabled = capability.y_max === '0.000000' && capability.y_min === '0.000000';
                    this.position3DUnsupported = this.zoomCapabilityDisabled && this.panRangeDisabled && this.tiltRangeDisabled;
                }
                this.directions.forEach(item => {
                    if (item.name === DIRECTIONS.LEFT ||
                            item.name === DIRECTIONS.RIGHT
                    ) {
                        this.$set(item, 'show', this.panRangeDisabled);
                    }
                    if (item.name === DIRECTIONS.TOP ||
                            item.name === DIRECTIONS.BOTTOM
                    ) {
                        this.$set(item, 'show', this.tiltRangeDisabled);
                    }
                    if (item.name === DIRECTIONS.TOP_RIGHT ||
                            item.name === DIRECTIONS.TOP_LEFT ||
                            item.name === DIRECTIONS.BOTTOM_LEFT ||
                            item.name === DIRECTIONS.BOTTOM_RIGHT
                    ) {
                        this.$set(item, 'show', this.panTiltRotate);
                    }
                });
            });
        },
        async setPtzData(data) {
            if(Object.hasOwnProperty.call(data, 'error_code')) {
                if(data.error_code < 0) {
                    return;
                }
            }
            if (data.type === 'ipc') {
                this.zoomCapabilityDisabled = data.zoomCapabilityDisabled;
                this.position3DUnsupported = data.position3DUnsupported;
                this.patternSupported = data.patternSupported;
                this.tourSupportedDisabled = data.tourSupportedDisabled;
                this.presetUnSupported = data.presetUnSupported;
                if (!data.presetUnSupported) {
                    this.tab.name = 'preset';
                } else {
                    this.tab.name = '';
                }
                this.panRangeDisabled = data.panRangeDisabled;
                this.parkSupported = data.parkSupported;
                this.tiltRangeDisabled = data.tiltRangeDisabled;
                this.panTiltRotate = data.panTiltRotate;
                this.tourStayTimeRange = data.tourStayTimeRange;
                let resetPtzdata = await this.currentPlayer.getPtzDeviceResetSupport(this.id);
                this.ptzDeviceResetSupport = !(resetPtzdata.result.positionResetSupport);
            } else {
                this.zoomCapabilityDisabled = data.zoomCapabilityDisabled;
                this.patternSupported = data.patternSupported;
                this.tourSupportedDisabled = data.tourSupportedDisabled;
                this.presetUnSupported = data.presetUnSupported;
                if (!data.presetUnSupported) {
                    this.tab.name = 'preset';
                } else {
                    this.tab.name = '';
                }
                this.panRangeDisabled = data.panRangeDisabled;
                this.tiltRangeDisabled = data.tiltRangeDisabled;
                this.position3DUnsupported = data.position3DUnsupported;
                this.ptzDeviceResetSupport = true;
            }
            this.directions.forEach(item => {
                if (item.name === DIRECTIONS.LEFT ||
                            item.name === DIRECTIONS.RIGHT
                ) {
                    this.$set(item, 'show', this.panRangeDisabled);
                }
                if (item.name === DIRECTIONS.TOP ||
                            item.name === DIRECTIONS.BOTTOM
                ) {
                    this.$set(item, 'show', this.tiltRangeDisabled);
                }
                if (item.name === DIRECTIONS.TOP_RIGHT ||
                            item.name === DIRECTIONS.TOP_LEFT ||
                            item.name === DIRECTIONS.BOTTOM_LEFT ||
                            item.name === DIRECTIONS.BOTTOM_RIGHT
                ) {
                    this.$set(item, 'show', this.panTiltRotate);
                }
                if (item.name === DIRECTIONS.HORIZONTAL
                ) {
                    this.$set(item, 'show', this.ptzDeviceResetSupport);
                }
            });

        },
        hasProtoType(object, item) {
            return Object.prototype.hasOwnProperty.call(object, item);
        },
        isDisabled(objectName, fieldName) {
            return !((Object.prototype.hasOwnProperty.call(objectName, fieldName) && objectName[fieldName].toString() !== '0.000000,0.000000'));
        },
        startShowWatchConfig() {
            this.showWatch = true;
            this.watchData.mode = 'preset';
            this.presetNameStatus = [];
            this.$refs.watchData.clearValidate();
            this.presetNameStatus = this.presets.map(({ presetId, name }) => {
                return {
                    value: presetId,
                    label: name
                };
            });
            if (!this.presetNameStatus.length) {
                this.presetNameStatus.push({
                    value: -1,
                    label: '未设置预置点，请先设置预置点'
                });
                this.watchData.actionId = this.presetNameStatus[0].value;
            } else {
                this.watchData.actionId = this.presetNameStatus[0].value;
            }
        },
        // 开启守望
        startParkConfig() {
            this.watchData.enabled = this.isWatching ? 0 : 1;
            tumsAxios.post('/tums/ptz/v1/setParkConfig', {
                id: this.id,
                enabled: this.watchData.enabled,
                parkTime: this.watchData.parkTime,
                actionMode: this.watchData.mode,
                actionId: this.watchData.actionId
            }, () => {
                this.isWatching = !this.isWatching;
            });
        },
        // 修改模式
        changeMode(mode) {
            this.$refs.watchData.clearValidate();
            this.watchData.parkTime = '';
            if (mode === 'preset') {
                this.presetNameStatus = [];
                this.presetNameStatus = this.presets.map(({ presetId, name }) => {
                    return {
                        value: presetId,
                        label: name
                    };
                });
            } else {
                if (this.tourSupportedDisabled) {
                    return [];
                }
                this.updateCruise('changemode');
            }
        },
        cancelWatch() {
            this.showWatch = false;
        },
        saveWatch() {
            this.$refs.watchData.validate((valid) => {
                if (valid && this.actionId !== -1) {
                    this.startParkConfig();
                    this.showWatch = false;
                }
            });
        },
        // 添加
        async  addPresetName() {
            let canvas = document.querySelector('canvas');
            let shotImg = canvas.toDataURL();
            let filename = new Date().getTime();
            let files = await this.transformToFiles(shotImg, filename);
            let param = new FormData();
            param.append('imgSrc', files, filename);
            param.append('presetName', this.addPresetData.name);
            param.append('id', this.id);
            this.$refs.addPresetData.validate((valid) => {
                if (valid) {
                    this.addPresetLoading = true;
                    if (this.authType === 'aksk') {
                        this.getAddPresetPoint({
                            filename: filename,
                            presetName: this.addPresetData.name,
                            id: this.id,
                            files: files
                        });
                        return;
                    }
                    tumsAxios.post('/tums/ptz/v1/addPresetPoint', param, () => {
                        this.$refs.presetPoint.getAllPresets();
                        this.addPresetLoading = false;
                        this.showAddPresetDig = false;
                    }, () => {
                        this.addPresetLoading = false;
                    }, {
                        headers: {
                            'Content-Type': 'multipart/form-data;'
                        }
                    });
                }
            });
        },

        async getAddPresetPoint(options) {
            let addData = await this.currentPlayer.addPresetPoint(options);
            if (addData.error_code === 0) {
                this.$refs.presetPoint.getAllPresets();
                this.addPresetLoading = false;
                this.showAddPresetDig = false;
            } else {
                this.addPresetLoading = false;
            }

        },

        transformToFiles(dataurl, filename) {
            var arr = dataurl.split(',');
            var mime = arr[0].match(/:(.*?);/)[1];
            var bstr = window.atob(arr[1]);
            var n = bstr.length;
            var u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            console.log('mimeee', mime);
            return new File([u8arr], filename, { type: mime });
        },
        cancelAddPreset() {
            this.showAddPresetDig = false;
        },
        showAddPreset() {
            this.showAddPresetDig = true;
            this.addPresetData.name = '';
            this.$refs.addPresetData.clearValidate();
        },
        async _setMotionParams(direction, move) {
            let speed = (1 + 6 * this.speed.gearLevel / 100) / 7;
            if (this.authType === 'aksk') {
                let data = await this.currentPlayer.setPtzParams({
                // 设备索引
                    id: this.id,
                    direction: direction,
                    startOrNot: move,
                    speed: String(speed.toFixed(6))
                });
                // if (data.data.error_code === 0 && move === MOTION.start) {
                //     // this.$refs.cruise.stopCruise();
                //     this.$refs.locus.stopLocus();
                // }
                return;
            }
            tumsAxios.post('/tums/ptz/v1/motionCtrl', {
                // 设备索引
                id: this.id,
                direction: direction,
                startOrNot: move,
                speed: String(speed.toFixed(6))
            }, () => {
                // 停止正在进行的扫描
                // if (move === MOTION.start) {
                //     // this.$refs.cruise.stopCruise();
                //     this.$refs.locus.stopLocus();
                // }
            });
        },
        onMouseDown(direction) {
            if (direction === DIRECTIONS.HORIZONTAL) {
                return;
            }

            this.isHorizontalRotating = false;
            this.$nextTick(() => {
                this._setMotionParams(direction, MOTION.start);
            });
        },
        onMouseUp(direction) {
            if (direction === DIRECTIONS.HORIZONTAL) {
                return;
            }

            this._setMotionParams(direction, MOTION.stop);
        },
        onMouseClick(direction) {
            switch (direction) {
                case DIRECTIONS.HORIZONTAL:
                    this.isHorizontalRotating = !this.isHorizontalRotating;
                    break;
            }
        },
        updatePreset(presets) {
            this.presets = presets;
            console.log('presets', this.presets);
        },
        // 获取巡航路径数据
        updateCruise(type) {
            let tourIds;
            let tourStates;
            let tourNames;
            tumsAxios.chainPost('/tums/ptz/v1/getAllTours', {
                id: this.id
            }).then(res => {
                let data = res.result;
                tourIds = data.tourId || [];
                tourStates = data.tourStates || [];
                tourNames = data.tourNames || [];

                // 未设置任何预置点
                if (tourIds.length === 0) {
                    return Promise.resolve({
                        result: [],
                        error_code: 0
                    });
                }

                return tumsAxios.chainPost('/tums/ptz/v1/getToursInfo', {
                    id: this.id,
                    tourId: tourIds
                });
            }).then(res => {
                let routePaths = res.result.map(route => {
                    let presetIds = route.presetIds || [];
                    let times = route.times || [];
                    let speedPans = route.speedPans || [];

                    return presetIds.map((presetId, index) => {
                        return {
                            presetId: presetId,
                            speed: parseInt(speedPans[index] * 40, 10),
                            time: parseInt(times[index] / 1000, 10)
                        };
                    });
                });
                this.routes = [];
                tourIds.forEach((tourId, i) => {
                    this.routes.push({
                        paths: routePaths[i],
                        status: tourStates[i],
                        tourId: tourId,
                        id: this.id,
                        tourName: decode(tourNames[i]) || `巡航路径${i}`,
                        // 巡航路径选中状态
                        selected: false
                    });
                });
                // 设置守望数据
                if (type === 'changemode') {
                    this.watchData.mode = 'cruise';
                    this.presetNameStatus = [];
                    this.presetNameStatus = this.routes.map(({ tourId, tourName }) => {
                        return {
                            value: tourId,
                            label: tourName
                        };
                    });
                    if (!this.presetNameStatus.length) {
                        this.presetNameStatus.push({
                            value: -1,
                            label: '未设置巡航路径，请先设置'
                        });
                        this.watchData.actionId = -1;
                    } else {
                        this.watchData.actionId = this.presetNameStatus[0].value;
                    }
                }
            });
        },
        closePtzPanel() {
            this.$emit('close-ptz-panel');
        }
    }
};
</script>
<style lang="scss" scoped>
.tums-button +.tums-button {
     margin-left: 0;
}
.tums-form-item__content {
    display: flex;
}
.watch-set {
    .watch-set-top-info {
        border: 1px solid rgba(23,133,230,0.10);
        padding-top: 7px;
        padding-bottom: 7px;
        border-radius: 4px;
    }
}
</style>
