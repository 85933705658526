/**
 * 将Array、TypedArray数据下载为文件
 * @param {Blob} blob
 * @param {String} fileName
 */
export function blobDownload(blob, fileName) {
    const link = document.createElement('a');
    link.style.display = 'none';
    const fullBlob = blob;
    const downloadUrl = window.URL.createObjectURL(fullBlob);
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(downloadUrl);
}