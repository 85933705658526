// Attention Please!
// 每次更新code，请同时更新ErrorCode.zhCN与ErrorCode.codeMap，是否需要新增mediaError类型，视具体情况而定
import SMediaError from './mediaError';
let ErrorCode = {
    ENAME: 'error_code',
    ENONE: 0,

    // 枚举值，供外部进行引用
    COMMON: {
        REQUEST_TIMEOUT: -82432,
        NETWORK_ERROR: -82428,
        UNKNOWN_CODE: -82415
    },

    // 枚举值，供外部进行引用
    VIDEO: {
        VIDEO_SET_PLAYBACK_FAIL: -80706
    },

    zhCN: {
        // common code
        '-82432': '操作超时',
        '-82428': '网络异常',
        '-82429':  '服务器忙',
        '-80125': '该账号已在其他终端登录，请重新登录',
        '-82402': '用户重新登录',
        '-82415': '未知错误',

        // playback code
        '-80706': '设置回放失败'
    },

    codeMap: {
        // common code
        '-82432': SMediaError.errorTypes.COMMON_ERR_TIME_OUT,
        '-82428': SMediaError.errorTypes.COMMON_ERR_NETWORK,
        '-82429': SMediaError.errorTypes.COMMON_ERR_INTERNAL,
        '-80125': SMediaError.errorTypes.COMMON_ERR_SESSION,
        '-82402': SMediaError.errorTypes.COMMON_ERR_SESSION,
        '-82415': SMediaError.errorTypes.COMMON_ERR_CUSTOM,

        // playback code
        '-80706': SMediaError.errorTypes.LOCAL_ERR_CONFIG_PLAYBACK
    }
};

// 获取errorCode对应错误提示
ErrorCode.translate = code => {
    if (Object.prototype.hasOwnProperty.call(ErrorCode.zhCN, `${code}`)) {
        return ErrorCode.zhCN[code];
    } else {
        return `${code} is not a valid string`;
    }
};

// 获取errorCode对应mediaCode
ErrorCode.tranToMediaErrorType = code => {
    if (ErrorCode.codeMap[code]) {
        return ErrorCode.codeMap[code];
    } else {
        return SMediaError.errorTypes.COMMON_ERR_CUSTOM; // 未知错误
    }
};

export default ErrorCode;
