import Url from '@/utils/url';

export class RTSPError extends Error {
    constructor(data) {
        super(data.msg);
        this.data = data;
    }
}

export class RTSPStream {
    static get STATE_INITIAL() {
        return 1 << 0;
    }
    static get STATE_OPTIONS() {
        return 1 << 1;
    }
    static get STATE_DESCRIBE() {
        return 1 << 2;
    }
    static get STATE_SETUP() {
        return 1 << 3;
    }
    static get STATE_STREAMS() {
        return 1 << 4;
    }
    static get STATE_TEARDOWN() {
        return 1 << 5;
    }
    static get STATE_MULTITRANS() {
        return 1 << 6;
    }
    static get STATE_PAUSED() {
        return 1 << 7;
    }

    constructor(client, track, audioTrack) {
        this.state = null;
        this.client = client;
        this.track = track;
        this.audioTrack = audioTrack;
        this.rtpChannel = 1;
        this.audiortpChannel = 1;

        this.stopKeepAlive();
        this.keepaliveInterval = null;
    }

    reset() {
        this.stopKeepAlive();
        this.client.forgetRTPChannel(this.rtpChannel);
        this.client = null;
        this.track = null;
    }

    start() {
        return this.sendSetup().then(this.sendPlay.bind(this));
    }

    stop() {
        return this.sendTeardown();
    }

    getSetupURL(track) {
        let sessionBlock = this.client.sdp.getSessionBlock();
        // try {
        if (Url.isAbsolute(track.control)) {
            return track.control;
        } else if (Url.isAbsolute(`${sessionBlock.control}${track.control}`)) {
            return `${sessionBlock.control}${track.control}`;
        } else if (Url.isAbsolute(`${this.client.contentBase}${track.control}`)) {
            /* Should probably check session level control before this */
            return `${this.client.contentBase}${track.control}`;
        } else {
            //need return default
            return track.control;
        }
        // } catch (e) {
        //     return;
        // }
    }

    getControlURL() {
        let ctrl = this.client.sdp.getSessionBlock().control;
        if (Url.isAbsolute(ctrl)) {
            return ctrl;
        } else if (!ctrl || '*' === ctrl) {
            return this.client.contentBase || this.client.url;
        } else {
            return `${this.client.contentBase}${ctrl}`;
        }
    }

    sendKeepalive() {
        if (this.client.methods.includes('GET_PARAMETER')) {
            return this.client.sendRequest('GET_PARAMETER', this.getSetupURL(this.track), {
                Session: this.session,
            });
        } else {
            return this.client.sendRequest('OPTIONS', '*');
        }
    }

    stopKeepAlive() {
        clearInterval(this.keepaliveInterval);
    }

    startKeepAlive() {
        this.keepaliveInterval = setInterval(() => {
            this.sendKeepalive().catch((e) => {
                if (e instanceof RTSPError) {
                    if (Number(e.data.parsed.code) == 501) {
                        return;
                    }
                }
                this.client.reconnect();
            });
        }, 30000);
    }

    sendRequest(_cmd, _params = {}) {
        let params = {};
        if (this.session) {
            params['Session'] = this.session;
        }
        Object.assign(params, _params);
        return this.client.sendRequest(_cmd, this.getControlURL(), params);
    }

    async sendAudioSetup(_data) {
        this.session = _data.headers['session'];

        let audioInterleavedChannels = this.client.interleaveChannelIndex++ + '-' + this.client.interleaveChannelIndex++;
        await this.client.sendRequest('SETUP', this.getSetupURL(this.audioTrack), {
            Session: this.session,
            Transport: `RTP/AVP/TCP;unicast;interleaved=${audioInterleavedChannels}`,
            Date: new Date().toUTCString(),
        });
    }

    async sendSetup() {
        this.state = RTSPStream.STATE_SETUP;
        this.rtpChannel = this.client.interleaveChannelIndex;
        this.audiortpChannel = this.client.interleaveChannelIndex + 2;
        let interleavedChannels = this.client.interleaveChannelIndex++ + '-' + this.client.interleaveChannelIndex++;
        let data = await this.client.sendRequest('SETUP', this.getSetupURL(this.track), {
            Transport: `RTP/AVP/TCP;unicast;interleaved=${interleavedChannels}`,
            Date: new Date().toUTCString(),
        });

        this.sendAudioSetup(data);
    }

    async sendPlay(/* pos = 0 */) {
        let promise = new Promise((resolve) => {
            setTimeout(() => resolve('done'), 500);
        });
        await promise;
        this.state = RTSPStream.STATE_PLAY;
        let params = {};
        let range = this.client.sdp.sessionBlock.range;
        if (range) {
            // TODO: seekable
            if (range[0] == -1) {
                range[0] = 0; // Do not handle now at the moment
            }
            // params['Range'] = `${range[2]}=${range[0]}-`;
        }
        this.client.useRTPChannel(this.rtpChannel);
        this.client.useRTPChannel(this.audiortpChannel);
        let data = await this.sendRequest('PLAY', params);
        this.state = RTSPStream.STATE_STREAMS;
        return { track: this.track, data: data };
    }

    async sendPause() {
        if (!this.client.supports('PAUSE')) {
            return;
        }
        // this.state = RTSPStream.STATE_PAUSED;
        await this.sendRequest('PAUSE');
        this.state = RTSPStream.STATE_PAUSED;
    }

    async sendTeardown() {
        if (this.state != RTSPStream.STATE_TEARDOWN) {
            this.client.forgetRTPChannel(this.rtpChannel);
            this.state = RTSPStream.STATE_TEARDOWN;
            this.stopKeepAlive();
            await this.sendRequest('TEARDOWN');
            ///this.client.connection.disconnect();
            // TODO: Notify client
        }
    }
}
