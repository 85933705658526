import ErrorCode from './errorCode';
import { getAuthorization } from '@/utils/auth';
import { randomStr } from '@/utils/util';
import StreamType from '@/utils/streamType';
import axios from 'axios';
// to-do 适配原有data.js
const axiosConfig = { timeout: 60 * 1000 };
const passthroughUri = '/tums/devConfig/v1/passthrough';

export default class Request {
    /**
     * Request构造函数
     * @param {Object} options
     */
    constructor(options = {
        steamType: '',
        authSessionId: '',
        appKey: '',
        appSecret: '',
        urlPrefix: ''
    }) {
        this.steamType = options.steamType || '';
        this.authSessionId = options.authSessionId || '';
        this.appKey = options.appKey || '';
        this.appSecret = options.appSecret || '';

        this.setUrlPrefix(options.urlPrefix);
    }

    setUrlPrefix(urlPrefix = '') {
        this.urlPrefix = urlPrefix;

        // 去除末尾'/'，便于拼接完整url
        let strLength = urlPrefix.length || 0;
        if (strLength && urlPrefix[strLength - 1] === '/') {
            this.urlPrefix = this.urlPrefix.substring(0, strLength - 1);
        }
    }

    getAxiosConfig(options = {
        uri,
        param,
        method: 'POST',
        initConfig: {}
    }) {
        let config = Object.assign({}, axiosConfig, options.initConfig);
        // 商云鉴权
        if (this.steamType === StreamType.RELAY) {
            let headers = null;
            if (!config.headers) config.headers = {};
            headers = config.headers;

            config.headers['X-Authorization'] = getAuthorization({
                appKey: this.appKey,
                appSecret: this.appSecret,
                terminalId: randomStr(16),
                method: options.method,
                uri: options.uri,
                payload: JSON.stringify(options.param || {})
            });
        }
        return config;
    }

    getFullUrl(uri) {
        let url = this.urlPrefix + uri;
        // 本地管理平台鉴权
        if (this.steamType === StreamType.RTSP) {
            if (url.indexOf('?') > -1) {
                url = `${url}&SESSIONID=${this.authSessionId}`;
            } else {
                url = `${url}?SESSIONID=${this.authSessionId}`;
            }
        }
        return url;
    }

    post(uri, data, config) {
        const url = this.getFullUrl(uri);
        return axios.post(
            url,
            data || {},
            this.getAxiosConfig({ uri, param: data, method: 'POST', initConfig: config || {} }),
        )
            // 优先捕捉axios内部错误
            .catch(error => {
                if (error.message.indexOf('timeout') !== -1) {
                    return Promise.reject({ error_code: ErrorCode.COMMON.REQUEST_TIMEOUT, msg: '请求超时' });
                } else if (
                    error.message.indexOf('Network Error') !== -1 ||
                    error.message.indexOf('Request failed') !== -1
                ) {
                    // to-do 目前协议平台未有对应错误码，暂时不予传递
                    return Promise.reject({ error_code: undefined, msg: '网络异常' });
                } else {
                    return Promise.reject({ error_code: ErrorCode.COMMON.UNKNOWN_CODE, msg: '未知错误' });
                }
            })
            .then(response => {
                let data = response.data || {};
                if (data.error_code === 0) {
                    return Promise.resolve(response.data);
                } else {
                    return Promise.reject(response.data);
                }
            });
    }

    // 设备透传请求
    passthrough(params) {
        return this.post(passthroughUri, params).then(data => {
            if (data.error_code === 0 && data.result.responseData.error_code === 0) {
                return Promise.resolve(data.result.responseData);
            } else {
                return Promise.reject(data.error_code ? data : data.result.responseData);
            }
        });
    }

    // 通过nvr透传ipc的请求
    nvrChnPassthrough(parentId, channel, content) {
        let params = {
            devId: parentId,
            param: {
                method: 'forward',
                destination: 'ipc',
                channel: channel,
                content
            }
        };
        return this.passthrough(params).then(data => {
            if (data.error_code !== 0) return Promise.reject(data);
            return data.content;
        });
    }
}
