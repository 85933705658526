const supportedTypes = [];
// 计算当前浏览器支持的mimeType类型
export function getSupportMimeTypes() {
    if (supportedTypes.length) return supportedTypes;
    const VIDEO_TYPES = [
        'webm'
        // 'ogg',
        // 'mp4',
        // 'x-matroska'
    ];
    const VIDEO_CODECS = [
        'vp8',
        'vp8.0',
        'vp9',
        'vp9.0'
        // 'avc1',
        // 'av1',
        // 'h265',
        // 'h.265',
        // 'h264',
        // 'h.264',
        // 'opus'
    ];

    VIDEO_TYPES.forEach((videoType) => {
        const type = `video/${videoType}`;
        VIDEO_CODECS.forEach((codec) => {
            const variations = [
                `${type};codecs=${codec}`,
                `${type};codecs:${codec}`,
                `${type}`
            ];
            variations.forEach(variation => {
                if(MediaRecorder.isTypeSupported(variation)) {
                    supportedTypes.push(variation);
                }
            });
        });
    });

    return supportedTypes;
}
